.recentSearch_container{
  width: 1191px;
  max-width: 1191px;
  margin: 0 auto;
}
.recentSearch {
    border-radius: 0;
    display: flex;
    max-width: 280px;
    flex-direction: column;
    font-family: var(--font_family);
  }
  .recentSearch_wrap{
    display: flex;
    gap: 12px 19px;

  }
  .recentSearch_space{
    margin-top: 45px;
  }

  .recentSearch_title{
    color: var(--secondary_color);
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
    text-transform: capitalize;
    margin-bottom: 25px;
  }
  
  .recentSearchCard {
    align-items: start;
    border-radius: 10px 0 10px 10px;
    border: 1px solid  #e5e7eb;
    background:  #fff;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 19px 16px;
    cursor: pointer;
  }
  .recentSearchCard:hover{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .21);
    -webkit-transition: box-shadow .5sease-in;
  }
  
  .recentSearchCardContent {
    display: flex;
    width: 100%;
    max-width: 244px;
    flex-direction: column;
    padding: 2px 0;
  }
  
  .recentSearchCardHotel {
    display: flex;
    min-height: 20px;
    align-items: center;
    gap: 7px;
    font-size: 18px;
    color: var(--secondary_color);
    font-weight: 700;
    line-height: 1.1;
  }
  
  .recentSearchCardHotelIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 13px;
    align-self: stretch;
    margin: auto 0;
  }
  
  .recentSearchCardHotelName {
    align-self: stretch;
    width: 228px;
    margin: auto 0;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .recentSearchCardBooking {
    align-self: center;
    display: flex;
    margin-top: 4px;
    width: 100%;
    max-width: 202px;
    flex-direction: column;
    font-size: 12px;
    color: var(--font_color);
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 1;
  }
  .recentSearchCardBookingDates{}
  .recentSearchCardBookingGuests {
    margin-top: 7px;
  }


  @media screen and (min-width: 320px) and (max-width: 767px) {
    .recentSearch_container {
      width: 100%;
      max-width: 100%;
      margin: 0;
  }
  
  .recentSearch_space {
      margin-top: 40px;
      padding: 0 15px;
      margin-bottom: 20px;
  }
  
  .recentSearch_wrap {
      overflow: scroll;
      padding-bottom: 10px;

  }
  .recentSearch_title{
    font-size: 18px;
    margin-bottom: 20px;
  }

  
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .recentSearch_space{
      width: 97%;
    }

  }