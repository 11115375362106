.mob_flight_search_wid_container{
    width: 100%;
    /* min-height: 100vh; */
    background: #e2e2e2;
    padding-bottom: 10px;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main{
    padding: 0;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible{
    display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header{
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
    padding: 0px 15px;
    /* margin-top: 25px; */
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image svg{
    width: 25px;
    cursor: pointer;
    height: 25px;
    margin-right: 25px;
    color: var(--primary_color);
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image{
    display: inline-block;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .search_filter_header{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: var(--primary_color);
}

.mob_flight_way_tab{
    width: -webkit-fill-available;
    text-align: center;
    background: #fff;
    margin: 15px 10px;
    display: flex;
    height: 40px;
}

.mob_flight_way_tab .mob_flight_way_btngrp{
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    width: 100%;
}

.mob_flight_way_btngrp .active_way{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    border-bottom: 3px solid var(--secondary_color);
    border-radius: 3px;
    padding: 5px 10px;
    transition: 0.3s;
    width: 50%;
}
.mob_flight_way_btngrp .button_style{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--font_color);
    width: 50%;
}

.mob_flight_way_btngrp .button_style:focus{
    color: var(--primary_color);
}
.mob_flight_way_btngrp .button_style:hover{
    color: var(--primary_color);
}

.mob_flight_search_details_container{
    width: 100%;
    margin-top: 20px;
    max-height: 68vh;
    overflow-y: auto;
}

.mob_flight_search_details_container .mob_flight_search_details_main .mob_flight_search_details_row{
    display: block;
    padding: 0 10px;
}
.mob_flight_search_details_container .mob_flight_search_details_main .mob_flight_search_details_row .mob_flight_search_column{
    margin-bottom: 9px;
    position: relative;
    background: #ffff;
    border-radius: 3px;
    height: 65px;

}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message{
    position: relative;
    top: -8px;
    float: left;
    width: 104%;
}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.mob_flight_search_details_container .mob_flight_search_details_main .mob_flight_search_details_row .mob_flight_swap_main{
    position: relative;
}
.mob_flight_search_details_container .mob_flight_search_details_main .mob_flight_search_details_row .mob_flight_swap_main .mob_flight_swap{
    position: absolute;
    left: 45%;
    z-index: 1;
    top: -15px;
    width: 34px;
    transform: rotate(270deg);
}

.mob_flight_search_details_row .mob_flight_search_depart{
    width: 100%;
}

.mob_flight_search_details_row .mob_flight_search_depart_date{
    width: 100%;
}
.mob_flight_search_details_row .mob_flight_search_depart_going_date{
    width: 100%;
    cursor: pointer;
}

.mob_flight_search_details_row .mob_flight_search_pax{
    width: 100%;
    font-family: var(--font_family);
    padding-left: 50px;
    padding-top: 3px;
    padding-right: 15px;
}
.mob_flight_search_details_row .mob_flight_search_class{
    width: 100%;
}

.mob_flight_search_details_row .mob_flight_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 100%;
    height: 50px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
    margin: 20px 0;
}

.mob_flight_search_details_row .mob_flight_search_widget_btn:hover, .mob_flight_search_details_row .mob_flight_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}

.mob_flight_search_wid_inputfield{
    width: 100%;
    /* height: 50px !important; */
    /* margin-bottom: 10px; */
    margin-left: 0px !important;
}

.mob_flight_search_wid_inputfield .mob_flight_search_flex_adjust{
    display: flex;
}
.flight_search_wid_inputfield .disable_pax_btn{
    opacity: 0.5;
}

.mob_flight_search_wid_inputfield .disable_pax_btn{
    opacity: 0.5;
}

.mob_flight_search_wid_inputfield .add_pax{
    width: 20px !important ;
    height: 20px !important;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    background-color: transparent;
    border: 0;
    object-fit: contain;
}

.mob_flight_search_wid_inputfield .minus_pax{
    width: 20px !important;
    height: 20px !important;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    background-color: transparent;
    border: 0;
    object-fit: contain;
}

.mob_flight_search_wid_inputfield .disbale_traveller{
    opacity: 0.5;
}
   
    
.mob_flight_search_details_main .mob_flight_search_details_row:first-child label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 5px;
}

.mob_flight_search_details_main .mob_flight_search_details_row:first-child>div{
    padding-left: 50px;
    padding-top: 3px;
    position: relative;
}
.mob_flight_search_details_main .mob_flight_search_details_row:first-child>div img{
    position: absolute;
    left: 7px;
    /* top: 25px; */
    width: 32px;
    height: auto;
}
.mob_flight_search_details_main .mob_flight_search_details_row:first-child>div input
{
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    border: none;
    padding: 0 10px 0 0;
    height: 30px;
    box-sizing: border-box;
    width: 100%;
    box-shadow: none;
    cursor: pointer;
}

.mob_flight_search_details_main .mob_flight_search_details_row:first-child>div input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

.mob_flight_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 245px;
    z-index: 3;
    top: 53px;
    background-color: #fff;
    box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
}

.mob_flight_autocomplete_element{
    width: 100%;
    display: flex;
    padding: 5px 17px;  
    cursor: pointer;      
    align-items: center;
    min-height: 70px;
}

.mob_flight_autocomplete_element:hover,
.mob_flight_autocomplete_element:focus{
    background: #ccc;
}

.mob_flight_autocomplete_tag{
    padding: 1px 5px;
    background-color: #48a842;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    vertical-align: top;
    display: inline-block;
    border-radius: 7px;
}

.mob_flight_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.mob_flight_autocomplete_name span{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}


.mob_flight_search_details_row ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .mob_flight_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .mob_flight_search_details_row ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
  .mob_flight_search_pax span{
    margin-bottom: 10px;
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    display: inline-block;
    color: var(--font_color);
  }

  .mob_flight_search_pax label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    display: inline-block;
    color: var(--font_color) !important;
    margin-right: 3px;
  }

  .mob_flight_search_pax .mob_flight_search_wid_inputfield{
      justify-content: space-between;
      display: flex;
      margin-left: -20px !important;
      color: var(--primary_color);
          margin-top: -5px;
  }
  .mob_flight_search_wid_inputfield .pax_block{
display: flex;
justify-content: space-between;
width: 100%;
margin-left: -15px;
  }
  .mob_flight_search_pax .mob_flight_search_wid_inputfield span{
      margin-bottom: 0;
      font-family: var(--font_family);
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--font_color);
      /* padding: 4px 4px; */
      position: relative;
      /* height: 30px; */
      text-align: center;
      border: none;
      margin-top: -2px;
  }

  .mob_flight_cabin_class_cont{
      display: flex;
      align-items: center;
      font-family: var(--font_family);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--font_color);
      padding: 0 0 0px 0;
      cursor: pointer;
      height: 60px;
    justify-content: space-between;
  }
  .mob_flight_cabin_class_cont img{
max-width: 25px;
margin: 0 17px 0 8px;
height: auto;
object-fit: contain;
  }
  .mob_flight_cabin_class_list {
    position: absolute;
    width: 100%;
    border: 1px #e2e0e0 solid;
    top: -153px;
    left: 0px;
    border-top: none;
    background: #ffffff;
    z-index: 1;
}

.mob_flight_cabin_class_ele {
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px 10px;
    margin: 0px 0px;
}

.mob_flight_cabin_class_ele:hover {
    background-color: var(--primary_color);
    color: #ffffff;
}

.mob_flight_cabin_class_name{
    width: 170px;
}

.mob_flight_cabin_class_arrow_img{
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 20px !important;
}

.mob_flight_search_error_message{
    color: #e8664e;
    font-size: 11px;
    position: relative;
    top: -15px;
    margin-top: 10px;
}

.btn_search{
    padding-left: 0 !important;
}

/************ Calendar Module ************/

.calendar_wid_container{
    width: 100%;
}

.calendar_wid_container_popup{
    z-index: 100;
    position: fixed;
    min-width: 333px;
    height: 100%;
    background: white;
    overflow: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.calendar_wid_container_popup > img{
    height: 20px !important;
    width: 20px !important;
    z-index: 99;
    top: 10px !important;
    position: fixed !important;
}
.calendar_wid_container_popup .calendar_head_container{
    width: 100%;
    top: 0;
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: rgb(255, 255, 255);
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main{
    width: 100%;
    display: flex;
color: var(--font_color);
    padding: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_left{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_right{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main h5 {
    border-bottom: 2px dashed #ccc;
    display: inline-block;
    font-weight: bold;
}
.calendar_head_main span{
    display: block;
}
.calendar_wid_container .calendar_main_body_popup{
    overflow: auto;
    /* height: 75vh; */
    padding:  20px 30px 70px;
    width: 100%;
    display: block;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    text-transform: uppercase;
    color: var(--font_color);
    width: 100%;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 25px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.calendar_body .calendar_day_list  .day_cell_center_highlight>span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 80%;
    background-color: #cbe3f5;
    transition: color 0.3s, border 0.3s;
}
.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
color: var(--font_color);
font-size: 14px;
width: 100%;
}
.calendar_body{
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px dashed #ccc;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date
/* ,.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span */
{
    background: url(/images/selected_to.svg)no-repeat;
    background: var(--secondary_color);
    height: 30px;
    width: calc(100% / 7);
    background-size: contain;
    background-position: 55%;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date span{
    font-weight: bold;
    color: #fff;
}

.calendar_footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
}

.calendar_footer button{
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: var(--buttons_color);
    border-radius: 3px;
    border: none;
    font-size: 14px;
    padding: 10px 50px;
}
.calendar_modal_close{
    height: 20px;
    position: relative;
}
.calendar_modal_close svg{
    height: 25px;
    width: 25px;
    background-color: var(--primary_color);
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    z-index: 3;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    position: fixed;
    right: 0;
}


.returndisble{
    color: rgb(129, 116, 116)  !important;
}