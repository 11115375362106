.activity_search_wid_container{
    width: 100%;
    padding: 20px 25px 40px 30px;
    padding-top: 50px;
    display: table;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 8px;  
}

.activity_way_title{
    width: 100%;
    text-align: left;
}

.activity_way_title h2{
    font-family: var(--font_family);
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    text-align: left;
    color: var(--primary_color);
}
.activity_search_details_container{
    width: 100%;
    margin-top: 20px;
    border: solid 1px #d4d4d4;
    padding: 20px 20px;
    border-radius: 30px;
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row{
     display: flex;
}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column{
    float: left;
    margin-right: 3px;
    margin-bottom: 0px;
    position: relative;
    background: #ffff;
    padding-left: 0;
    border-right: 1px solid #dedddd;
    border-radius: 0;
    /* height: 90px; */
    /* cursor: pointer; */

}
.activity_search_details_row .activity_search_column.activity_search_date{
    width: 28.1% !important;
}
.activity_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;   
}
.activity_search_wid_inputfield .activity_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}
.activity_search_details_main .activity_search_details_row .activity_search_name{
    width: 100%;
    border-radius: 10px;
}
.activity_search_details_main .activity_search_details_row .activity_search_date{
    width: 42.1%;
}
.activity_search_details_main .activity_search_details_row .activity_search_accomodation{
    width: 22%;
}
.activity_search_widget_btn:hover, .activity_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}
.activity_search_details_row .activity_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 10px;
    width: 140px;
    padding: 18px 15px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
    text-transform: capitalize;
}
.activity_nw .activity_search_widget_btn{
    margin-top: 0;
    width: 200px;
    /* height: 3.3rem; */
    /* height: 4rem; */
}
.activity_search_details_row .activity_search_class{
    margin-left: auto;
    order: 2;
    margin-right: 3px;
    padding-left: 0;
    /* width: 17%; */
    padding-top: 0px !important;
}

.activity_search_accomodation .activity_cabin_class_cont{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.activity_search_column .activity_room_list{
    position: absolute;
    min-width: 265px;
    width: 100%;
    top: 60px;
    background-color: #ffffff;
    left: 0px;
    z-index: 99;
    box-sizing: border-box;
    max-height: 400px;
    box-shadow: 0px 0px 3px 0px #aaa;
    overflow: auto;
}

.activity_search_column .activity_room_list .activity_room_list_main{
    width: 360px;
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    border-top: none;
    box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
}

.activity_search_column .activity_room_list .activity_room_list_main .activity_room_detail_ele{
    text-align: left;
    padding: 10px 10px;
    width: 100%;
}

.activity_room_detail_ele .activity_room_title{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
    text-transform: uppercase;
}
.activity_room_head{    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.activity_room_remove{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: var(--font_color);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}
.activity_search_details_container .activity_search_details_row .activity_search_column .activity_search_wid_inputfield .activity_search_wid_inputfield_main .activity_search_error_message{
    position: relative;
    top: -10px;
    float: left;
    width: 104%;
}
.activity_search_details_container .activity_search_details_row .activity_search_column .activity_search_wid_inputfield .activity_search_wid_inputfield_main .activity_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.activity_accomodation_main >div{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.activity_accomodation_main >div img{
    position: relative;
    top: unset;
    left: unset;
}

.activity_accomodation_conf{
    display: flex;
    align-items: center;
}

.activity_accomodation_conf div{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    width: 50px;
    text-align: center;
}
.activity_accomodation_main span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
}

.activity_accomodation_main p{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    margin: 0px 0px;
    display: block;
}
.activity_room_detail_ele .activity_room_title span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
}

.activity_room_list_main .activity_room_detail_ele .activity_accomodation_main{
    display: block;
    margin-top: 0px;
    position: relative;
    width: 100%;
}

.activity_accomodation_conf .activity_accomodation_icon svg{
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: snow;
    border: 0;
    position: relative;
    top: unset;
    left: unset;
}

.age_list_ele{
    cursor: pointer;
    width: 100%;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    padding: 0 15px;
}

.disbale_traveller{
    pointer-events: none;
    cursor: default;
}

.disbale_traveller img{
    opacity: 0.5;
}

.done_button_main{
    width: 100%;
    text-align: left;
    padding-top: 25px;
    background: #fff;
}

.done_button_main .done_button{
    background-color: var(--buttons_color);
    border: none;
    color: #ffffff;
    padding: 11px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
}

.activity_search_details_row label{
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 0px;
    padding-left: 0px;
    /* text-transform: uppercase; */
}

.activity_search_details_row >div{
    padding-left: 15px;
    padding-top: 6px;
}
.activity_nw{
    padding-left: 10px !important;
    padding-top: 0 !important;
}

.activity_search_details_row svg{
    position: absolute;
    left: 13px;
    top: 16px;
    width: 20px;
    height: 22px;
    color: var(--button_color);
}
.activity_search_class button{
    position: relative;
}
.activity_search_class button svg{
    position: absolute;
    left: 45px;
    top: 29px;
    width: 18px;
    height: 22px;
    color: var(--button_color);
}
.activity_search_details_row input,.activity_cabin_class_name
{
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    border: 0 !important;
    padding: 0px 10px 0 0px;
    outline: none !important;
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 98%;
    cursor: pointer;
    color: var(--secondary_color)!important;
    text-shadow: 0 0 0 var(--font_color);
}
.activity_search_details_row input::placeholder,
.activity_cabin_class_name::placeholder{
    font-weight: bold !important;
}

.activity_search_details_row input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

.activity_autocomplete_value{
    position: absolute;
    width: 100%;
    z-index: 9;
    top: 25px;
    color: var(--font_color);
    font-size: 12px;
}
.activity_recentsearch{
    font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  text-align: left;
  color: #606060;
  margin-left: 20px;
}
    .activity_autocomplete_element{
            width: 100%;
            display: flex;
            padding: 5px 17px;  
            cursor: pointer;      
            align-items: center;
            min-height: 40px;
            position: relative;
    }

    .activity_autocomplete_element:hover,
    .activity_autocomplete_element:focus{
        background: #ccc;
    }


.activity_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--secondary_color);
}

.activity_autocomplete_tag{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}
.activity_search_wid_inputfield_main input:focus{
    outline: 0!important;
    box-shadow: none!important;
}

.activity_search_details_row ::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  .activity_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .activity_search_details_row ::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 10px;
  }


  .activity_autocomplete_element img{
      position: relative;
      left: unset;
      top: unset;
      height: 23px;
      width: 23px;
      object-fit: scale-down;
      margin-right: 15px;
  }


.activity_add_rooms {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    display: flex;
    padding: 15px 10px;
    cursor: pointer;
}

.activity_add_rooms span{

    width: 23px;
    height: 23px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
    margin: -3px 10px 0 0;
    font-weight: normal;
}

.child_row_main label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 15px 0px 10px 0px;
    width: 100%;
    display: block;
}

.child_row_main input{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    width: 100%;
    height: 35px;
    padding-bottom: 5px;
    border-bottom: 1px#dcd8d8 solid !important;
    /* background: url(../image/down_arrow.jpg) center right no-repeat; */
    background-position: 97% 10px;
    cursor: pointer;
}

.child_row_main input::placeholder{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

/* ==================== Calendar ==================== */

.calendar_wid_container{
    width: 100%;
}
.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    margin-top: -3px;
}
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 612px;
    margin-top: 0px;
    top: 60px;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--font_color);
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
    border: solid 1px #5d5b62;
    background-color: #ffffff;
    padding: 10px 8px;
}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    /* text-transform: uppercase; */
    color: var(--font_color);
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    /* text-transform: uppercase; */
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cbe3f5;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    border-radius: 5px;
    color: #fff;
    background-color: var(--secondary_color);
}

.calendar_head_icon svg{
    position: relative !important;
    top: unset !important;
    right: unset !important;
}
.child_row_main{
    position: relative;
}
.child_age_select_arrow svg{
height: 15px;
width: 15px;
position: relative;
left: unset;
top: unset;
}

.child_row_main .child_age_select_arrow{
    position: absolute;
    right: 0;
    top: 43px;

}
.activity_search_wid_inputfield span img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
}
.activity_search_wid_inputfield span small {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--primary_color); 
    mask: url('/images/location-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/location-01.svg') no-repeat center / contain;

}
.activity_search_details_container .activity_search_details_main .activity_search_details_row .activity_search_column.activity_search_date span small{
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--primary_color); 
    mask: url('/images/calendar-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/calendar-01.svg') no-repeat center / contain;
}
.activity_autocomplete_element >span{
    width: 19px;
    height: 19px;
    background-color: #000000; 
    mask: url('/images/location-01.svg') no-repeat center / contain;
    -webkit-mask: url('/images/location-01.svg') no-repeat center / contain;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 5px 0px 0px;
}
.calendar_wid_container img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
    /* color: var(--primary_color); */
}
.error{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
   /* margin-top: 15px; */
   margin-top:2px;
}
.errordate{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
     /* margin-top: 10px; */
     margin-top: -4px;
}
.activity_search_new{
    margin: 10px 17px;
    border: 1px solid #b4b4c8;
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
}
.activity_citysearch{
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  text-align: left;
  color: #606060;
  padding-left: 8px;
}
.activity_iconsearch{
    margin-top: 2px;
}
.activity_iconsearch svg{
    position: unset;
}
.activity_autocomplete_newname{
    font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #959595;
}
.activity_search_details_row .input_search_destination svg {
    position: absolute;
    left: 6px;
    top: 12px;
    width: 20px;
    height: 19px;
    color: var(--button_color);
}
.input_search_destination input {
    padding: 20px 40px 20px 30px;
    /* margin: 10px auto; */
    border: 1px solid rgba(0,0,0,.16)!important;
    border-radius: 5px;
    width: 90%;
    display: block;
    height: 25px;
    color: var(--font_color) !important;
    font-size: 15px;
    font-weight: normal;
}
.input_search_destination input:focus{
    box-shadow: none;
    border: none;
}
.input_search_destination input,
.input_search_destination input::placeholder{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: #979797 !important;
}
.holiday_Dropcont{
    margin-right: 3px;
    margin-bottom: 0px;
    position: relative;
    background: #ffff;
    border-radius: 15px;
    padding-left: 15px !important;
    width: 100%;
    border-right: 1px solid #dedddd;
    border-radius: 0;
    cursor: pointer;
}
.activity_search_details_row .holiday_Dropcont_last{
    border-right: 0;
}
.catg_lblCont{
    display: flex;
    gap: 20px;
}
.catg_lblCont span svg{
    position: static;
    width: 22px;
    height: 22px;
}
.catg_lblInput,
.activity_search_wid_inputfield .activity_search_wid_inputfield_main{
    padding-top: 4px;
}
/* .activity_search_wid_inputfield_main input:focus,
.holiday_Dropcont .catg_lblInput input:focus{
    padding: 4px 10px;
    border-radius: 8px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.3);
} */
.holiday_Dropcont .catg_lblInput input:focus{
    box-shadow: none;
    border: none;
}
.activity_search_wid_inputfield_main input::placeholder,
.holiday_Dropcont .catg_lblInput input::placeholder{
    color: var(--secondary_color);
}


.pointer {
    width: 20px !important;
    height: 20px;
}
.dropDownlistmain {
    background-color: #fff;
}
.dropDownlistmain {
    position: absolute;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
  /* border: solid 1px #d4d4d4; */
  background-color: #fff;
    margin-top: 4px;
    z-index: 9;
    width: 95%;
}
.dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 2px;
    border-radius: 4px;
    background-color: #fff;
    margin-right: 10px;
}
.dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 19px 0 10px 19px;
    cursor: pointer;
}
.dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100% !important;
    padding: 4px 15px !important;
    border: 1px solid #d6d6d6 !important;
    border-radius: 50px !important;
    background: #fff !important;
}
.dropDownlistmain ul li input[type="text"]::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--font_color);
    padding-left: 3px;
}
.dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
}
.dropDownlistmain ul li span {
    margin-right: 4px;
}
.dropdown_value_container {
    max-height: 280px;
    overflow: auto;
}
.checkbox_label {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    align-items: center;
}
.checkbox_label .bagCheckbox {
    position: relative;
    margin-left: 0 !important;
}
.checkbox_label .bagCheckbox input {
    cursor: pointer;
    height: 20px;
    opacity: 0;
    position: absolute;
    width: 20px;
    z-index: 2;
}
.checkbox_label .bagBox {
    align-items: center;
    background: #fff;
    border: 1px solid var(--primary_color);
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    justify-content: center;
    position: relative;
    width: 18px;
    height: 18px;
    margin-left: 0 !important;
}
.checkbox_label .bagCheck {
    border-color: #fff;
    -o-border-image: none;
    border-image: none;
    border-style: none solid solid none;
    border-width: medium 2px 2px medium;
    box-sizing: border-box;
    height: 11px;
    opacity: 0;
    position: relative;
    top: -2px;
    left: 2px;
    transform: scale(1) rotate(45deg);
    transition-delay: 0.1s;
    width: 5px;
    z-index: 1;
    margin-left: 0;
}
.checkbox_label input[type="checkbox"]:checked ~ .bagBox {
    background: var(--primary_color);
    border: 0;
}
.checkbox_label .bagCheckbox input[type="checkbox"]:checked ~ .bagBox .bagCheck {
    opacity: 1;
    transform: scale(1) rotate(45deg);
}
.advTitle {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
    margin-left: 2px;
}
.dropdown_value_container::-webkit-scrollbar {
    width: 6px;
    height: 10px;
}
.dropdown_value_container::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
    border-radius: 10px;
}
.dropdown_value_container::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 10px;
}
.dropdown_value_container::-webkit-scrollbar-thumb:hover {
    /* background: #555; */
}

.option_month{
    padding: 0 10px;
    width: 70%;
    color: var(--secondary_color);
    height: 31px;
    border-radius: 15px;
}
.option_month:hover{
    background: var(--primary_color);
    color: #fff;
    font-weight: 700;
}
.advTitleDate{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.38px;
    text-align: left;
    margin-left: 2px;
    padding: 0 10px;
}
.dropDownlistmainMonth {
    position: absolute;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d4d4d4;
    background-color: #fff;
    margin-top: 4px;
    z-index: 9;
    width: 95%;
}
.dropDownlistmainMonth ul{
    padding: 8px;
    margin: 2px 0px;
    margin-right: 15px;
}
.dropDownlistmainMonth ul li{
    padding: 5px 0 10px 0;
}
.mainactivitydrop{
    background: #ffffff;
    border-radius: 5px;
}
.holidayMain_srp{
    /* background: linear-gradient(180deg, rgba(38,106,172, 1) 10%, rgba(255,255,255,1) 100%); */
    position: relative;
    z-index: 99;
    background: linear-gradient(180deg,#578dbf,#e3ecf4);
    padding: 30px 0 1px;
}
.activity_search_wid_container_srp{
    width: 1270px !important;
    margin: 0 auto;
    /* padding: 20px 25px 30px 30px;
    padding-top: 50px;
    display: table;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff; */
    margin-bottom: 30px;
    border-radius: 8px;  
    background: #fff;
    border-radius: 30px;
}
.activity_search_wid_container_srp .activity_search_wid_main{
    background-color: #fff;
}

.dropdown_main {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    /* border: solid 1px #d4d4d4; */
    width: 90%;
    overflow: auto;
    max-height: 245px;
}
.suggestions {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: var(--font_color);
    padding: 5px 17px;
    padding-top: 10px !important;
}

.dropdownicon {
    transform: rotate(180deg);
}
.dropdownPos{
    position: absolute;
    top: -5px;
    right: 105px;
}
.dropdownPosCat{
    position: absolute;
    top: -5px;
    right: 136px;
}
.no_res{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 17px;
  }
  
  .no_res svg{
    width: 20px !important;
    height: 20px !important;
    position: inherit !important;
  }
  
  .no_res p{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
  }
  .no_res svg {
    margin-top: 3px;
  }
  .hotel_autocomplete_element {
    width: 100%;
    display: flex;
    padding: 5px 17px;
    cursor: pointer;
    align-items: flex-start;
}
.hotel_autocomplete_element:focus,
.hotel_autocomplete_element:hover {
    background: #ccc;
}
.hotel_autocomplete_name {
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}
.hotel_autocomplete_tag {
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #979797;
    text-align: left;
    display: block;
}
.hotel_autocomplete_element svg {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 25px;
    height: 25px;
    margin-right: 5px;
}
.hotelnw_city {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--secondary_color);
    flex: 1;
    text-transform: capitalize;
}
.recent_label {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .33px;
    text-align: left;
    color: var(--font_color);
    padding: 5px 17px;
    padding-top: 10px!important;
    /* text-transform: uppercase; */
}
.hotel_autocomplete_city {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    font-family: var(--font_family);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--secondary_color);
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
    .hotel_autocomplete_element {
        flex-direction: row-reverse;
        padding: 10px 20px;
        margin-bottom: 0;
    }
    .hotel_autocomplete_element svg {
        width: auto;
        margin-right: 0;
    }
    .hotel_autocomplete_name {
        display: grid;
        align-items: center;
    }
    .search_container {
        position: relative;
        padding: 12px 20px;
        background-color: #1b5d9d;
        height: 65px;
    }
    .recent_label {
        border-top: 1px solid #c1c1c1;
        padding-top: 19px;
        padding-left: 18px;
    }
    .hotelnw_city {
        display: none;
    }
    .hotel_autocomplete_city {
        font-family: var(--font_family);
        font-size: 15px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        text-align: left;
        color: var(--secondary_color);
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 230px;
    }
    .hotel_autocomplete_tag {
        width: 40%;
        display: block;
    }
}


  @media only screen and (min-width: 1024px) and (max-width: 1240px) {
    .activity_search_details_row svg{
        position: absolute;
        left: 22px;
    }
    .activity_search_details_row svg{
        left: 60px;
    }
    .activity_search_wid_container_srp{
        width: 992px !important;
    }
    .holidaycontainer{
        width: 992px !important;
    }
}

.arrow_rotate {
    top: 3px !important;
    transform: rotate(180deg);
    transition: all 0.2s linear;
  }