.modal_container{
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal_background{
    position: fixed;
    background: rgb(0, 0, 0, .8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }   
  .modal_close1{}  
  .modal_close2{}
  .modal_overflow{
    /* overflow: auto;
    height: 50vh; */
    display: flex;
    align-items: center;
    z-index: 1;
    width: 50%;
    padding:  40px 0;
  }
      
  .modal_content{
    position: relative;
    width: 95%;
    box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 400px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 25px 35px;
    height: max-content;
    border-radius: 15px;
  }
  .modal_content.mobile_modal{
    padding: 20px 15px;
    border-radius: 10px;
  }
  .modal_close svg{
    height: 27px;
    width: 27px;
    /* background-color: var(--primary_color); */
    color: #000;
    border-radius: 50px;
    padding: 4px;
    position: absolute;
    z-index: 3;
    top: 7px;
    right: 10px;
    /* border: 0.2px solid var(--primary_color); */
  }
      
  .modal_close{
    float: right;
    cursor: pointer;
    /* position: relative;
      top: -50px;
      right: -15px; */
  }
  
  .green_popup  .modal_content.mobile_modal{
    background-color: #fff;
  }
  
  .green_popup .modal_content{
    background-color: #fff;
  }
  .green_popup .modal_content .modal_close svg{
    color: var(--primary_color);
  }
  @media screen and (max-width: 767px) and (min-width: 320px){

    .green_popup  .modal_content{
      background-color: #ffffff !important;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      margin: auto;
      z-index: 2;
      max-width: 515px;
      color: var(--primary_color);
      font-family: var(--font_family);
      font-size: 12px;
      padding: 20px 15px;
      height: max-content;
      border-radius: 10px;
      display: block;
    }  
    .modal_close svg{
      border: 0.2px solid var(--primary_color) !important;
    }
      .green_popup  .modal_content{
      padding: 20px 15px;
      border-radius: 10px;
    }
    .success_email{
      padding: 20px 15px !important;
      border-radius: 10px;
    }
    .success_email .modal_close svg{
      top: 16px !important;
    }
    .modal_overflow{
      padding: 0;
      width: 90%;
    }
    .modal_content{
      padding: 30px 15px;
      /* padding-top: 45px; */
      height: 50%;
      width: 100%;
      border-radius: 20px !important;
    }
    .modal_content p{
      color: var(--font_color);
    }
    .modal_close svg{
      top: 12px;
    }
    .success_email> div{
      text-align: center;
      margin-right: 20px;
    }
  
  }
  
  .success_email{
    position: relative;
    width: 95%;
    box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 400px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 15px;
    padding: 40px 100px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    border-radius: 10px;
  }
  
  .modal_close svg{
    /* position: absolute;
    width: 22px;
    height: 22px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    border: 1px solid #ccc; */
  }
  