.hotel_search_wid_container{
    width: 100%;
    padding: 20px 25px 20px 31px;
    display: table;
    /* background: rgb(255,255,255,0.3); */
    background-color: #ffffffad;
    margin-bottom: 30px;
    border-radius: 0 0 20px 20px;
}
.hotel_way_title{
    width: 100%;
    text-align: left;
}

.hotel_way_title h2{
    font-family: var(--font_family);
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    text-align: left;
    color: var(--primary_color);
}
.hotel_search_details_container{
    width: 100%;
    margin-top: 20px;
}
.hotel_search_details_container .hotel_search_details_main .hotel_search_details_row{
    display: flex;
}
.hotel_search_details_container .hotel_search_details_main .hotel_search_details_row .hotel_search_column{
    float: left;
    margin-right: 3px;
    margin-bottom: 3px;
    position: relative;
    background: #ffff;
    border-radius: 15px;
    height: 80px;
    cursor: default;
    padding: 15px;
    border: 1px solid var(--buttons_color);
}
.hotel_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
    width: 95%;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_name{
    width: 40%;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_date{
    width: 30%;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_accomodation{
    width: 15%;
}
.hotel_search_widget_btn:hover, .hotel_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}
.hotel_search_details_row .hotel_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 15px;
    width: 100%;
    height: 80px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
    position: relative;
    padding-left: 15px;
}
.hotel_search_details_row .hotel_search_widget_btn svg{
    position: absolute;
    left: 20px;
    top: 28px;
    width:  25px;
    height: 25px;
    color: var(--primary_color);
    cursor: pointer
}
.hotel_search_details_row .hotel_search_class{
    margin-left: auto;
    margin-left: 5px;
    order: 2;
    margin-right: 3px;
    padding-left: 0;
    width: 22%;
}
.hotel_search_btn_new{
    width: 15%;
    padding-left: 0px !important;
    padding-top: 0px !important;
}
.hotel_search_btn_new ::before{
    content: url(/images/location-01.svg) no-repeat center/contain;
    background-image: url(/images/location-01.svg) no-repeat center/contain;
}
.flight_search_class_new{
    padding-left: 0px;
    padding-top: 0px;
    width: 10%;
}
/* .hotel_search_details_row .hotel_search_class_btn{
    margin-left: auto;
    order: 2;
    margin-right: 3px;
    padding-left: 0;
    width: 22%;
} */
.hotel_search_accomodation .hotel_cabin_class_cont{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.hotel_search_column .hotel_room_list{
    position: absolute;
    min-width: 265px;
    border-radius: 15px;
    width: 100%;
    top: 60px;
    background-color: #ffffff;
    left: 0px;
    z-index: 9;
    box-sizing: border-box;
    max-height: 400px;
    box-shadow: 0px 0px 3px 0px #aaa;
    overflow: auto;
}

.hotel_search_column .hotel_room_list .hotel_room_list_main{
    width: 360px;
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    border-top: none;
    box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
}

.hotel_search_column .hotel_room_list .hotel_room_list_main .hotel_room_detail_ele{
    text-align: left;
    padding: 10px 10px;
    width: 100%;
}

.hotel_room_detail_ele .hotel_room_title{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
    text-transform: uppercase;
}
.hotel_room_head{    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.hotel_room_remove{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: var(--font_color);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}
.hotel_search_details_container .hotel_search_details_row .hotel_search_column .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .hotel_search_error_message{
    position: relative;
    top: -10px;
    float: left;
    width: 104%;
}
.hotel_search_details_container .hotel_search_details_row .hotel_search_column .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main .hotel_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.hotel_accomodation_main >div{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.hotel_accomodation_main >div img{
    position: relative;
    top: unset;
    left: unset;
}

.hotel_accomodation_conf{
    display: flex;
    align-items: center;
}

.hotel_accomodation_conf div{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    width: 50px;
    text-align: center;
}
.hotel_accomodation_main span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
}

.hotel_accomodation_main p{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    margin: 0px 0px;
    display: block;
}
.hotel_room_detail_ele .hotel_room_title span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
}

.hotel_room_list_main .hotel_room_detail_ele .hotel_accomodation_main{
    display: block;
    margin-top: 0px;
    position: relative;
    width: 100%;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.hotel_accomodation_conf .hotel_accomodation_icon svg{
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: snow;
    border: 0;
    position: relative;
    top: unset;
    left: unset;
}

.age_list_ele{
    cursor: pointer;
    width: 100%;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    /* margin: 0px 0px 10px 0px; */
    padding: 5px 15px;
}
.age_list_ele:hover{
    background-color: var(--primary_color);
    color: #fff;
}
.disbale_traveller{
    pointer-events: none !important;
    opacity: 0.5 !important;
    background: #3c3c3c1f !important;
}

.disbale_traveller img{
    opacity: 0.5;
}

.done_button_main{
    width: 100%;
    text-align: left;
    padding-top: 25px;
    background: #fff;
}

.done_button_main .done_button{
    background-color: var(--buttons_color);
    border: none;
    color: #ffffff;
    padding: 22px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
}

.hotel_search_details_row label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 20px;
    /* padding-bottom: 0px; */
}

.hotel_search_details_row >div{
    padding-left: 15px;
    /* padding-top: 10px; */
    margin-left: auto;
}

.hotel_search_details_row svg{
    position: absolute;
    left: 13px;
    top: 8px;
    width: 18px;
    height: 18px;
    color: var(--primary_color);
    cursor: pointer;
}
.hotel_search_wid_inputfield_main svg{
    top: 14px;
}
.hotel_search_details_row input,.hotel_cabin_class_name
{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    border: 0 !important;
    padding: 0 0px 0 4px;
    /* height: 30px; */
    box-sizing: border-box;
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    /* margin-top: 6px; */
    cursor: pointer;
    /* caret-color: transparent; */
}
.hotel_cabin_class_name span{
    font-size: 16px;
    font-family: var(--font_family);
    color: var(--font_color);
}
.hotel_search_wid_inputfield_main input{
    font-size: 22px;
    font-weight: 500; 
} 
.hotel_search_details_row input{
    color: var(--primary_color) !important;
}

.hotel_search_details_row input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

.hotel_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 245px;
    z-index: 9;
    top: 60px;
    background-color: #fff;
    box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid transparent;
    border-radius: 15px;
}

    .hotel_autocomplete_element{
            width: 100%;
            display: flex;
            padding: 5px 17px;  
            cursor: pointer;      
            align-items: flex-start;
            min-height: 70px;
    }

    .hotel_autocomplete_element:hover,
    .hotel_autocomplete_element:focus{
        background: #ccc;
    }


.hotel_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.hotel_autocomplete_tag{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}


.hotel_search_details_row ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .hotel_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .hotel_search_details_row ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }


  .hotel_autocomplete_element img{
      position: relative;
      left: unset;
      top: unset;
      height: 23px;
      width: 23px;
      object-fit: scale-down;
      margin-right: 15px;
  }


.hotel_add_rooms {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    display: flex;
    padding: 0px 10px;
    cursor: pointer;
    width: 60%;
}

.hotel_add_rooms span{

    width: 23px;
    height: 23px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
    margin: -3px 10px 0 0;
    font-weight: normal;
}

.child_row_main label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 15px 0px 10px 0px;
    width: 100%;
    display: block;
}

.child_row_main input{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    width: 100%;
    height: 35px;
    padding-bottom: 5px;
    border-bottom: 1px #dcd8d8 solid !important;
    /* background: url(../image/down_arrow.jpg) center right no-repeat; */
    background-position: 97% 10px;
    cursor: pointer;
}

.child_row_main input::placeholder{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

/* ==================== Calendar ==================== */

.calendar_wid_container{
    width: 100%;
    display: flex;
}
.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    margin-top: -3px;
    width: 100%;
}
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 612px;
    margin-top: 0px;
    top: 60px;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--font_color);
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
    border: solid 1px #5d5b62;
    background-color: #ffffff;
    padding: 10px 8px;
}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    text-transform: uppercase;
    color: var(--font_color);
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 10px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #cbe3f5; */
    background-color:#d6e4f4;
    opacity: 0.5;
    color: #fff;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    border-radius: 50px;
    color: #fff;
    background-color: var(--primary_color);
}
.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date>span{
    color: #ffffff;
    font-family: var(--font_family);
}
.calendar_head_icon svg{
    position: relative !important;
    top: unset !important;
    right: unset !important;
    color: var(--secondary_color) !important;
}
.child_row_main{
    position: relative;
}
.child_age_select_arrow svg{
height: 15px;
width: 15px;
position: relative;
left: unset;
top: unset;
}

.child_row_main .child_age_select_arrow{
    position: absolute;
    right: 0;
    top: 43px;

}
.container_tooltip{
    position: absolute;
    margin-top: -60px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    white-space: nowrap;
    font-size: 11px;
    pointer-events: none;
    z-index: 99;
}
.container_tooltip:before {
	position: absolute;
	bottom: -5px;
	left: calc(50% - 5px);
	border-top: 5px solid rgba(0, 0, 0, 0.12);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: ""
}
.container_tooltip:after {
	position: absolute;
	bottom: -4px;
	left: calc(50% - 4px);
	border-top: 4px solid #fff;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: ""
}

.child_row_container .child_row_main .err_message_text{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.hotel_search_left_icon {
    cursor: pointer;
}
.flight_search_wid_inputfield_img{
    cursor: pointer;
}

.hotel_cabin_class_name input{
    background-color: #ffffff;
}

.adv_search_wrap{
    text-align: end;
    display: flex;
    margin-left: auto;
    width: 50%;
}
.collapsible_title{
    width: 50%;
}
.advmodal_dropcontent{
    position: absolute;
    right: 0;
    background: #fff;
    z-index: 1;
    border-radius: 5px;
    padding: 15px 35px;
    margin-top: 10px;
    box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2);
    width: 80%;
    padding-bottom: 25px;
}
.filter_container{
    display: flex;
    gap: 10px 40px;
    flex-wrap: wrap;
}
.adv_text {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: var(--font_color);
    margin: 15px 0;
}
.check_advance_labels{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: var(--font_color);
}

.nationality_wrap{
    position: relative;
    display: inline;
}
.hotel_search_details_row .input_nationality_wd {
    width: 250px !important; 
    height: 40px !important;
    padding: 12px 20px 12px 12px !important;
    border-radius: 3px;
    border: solid 1px var(--primary_color) !important;
    background-color: #e2e0e0;
    position: relative;
    margin-top: 3px;
}
.pg_down_arrow i{
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}
.hotel_search_details_row .inputsearch_result{
    width: 250px;
    height: 383px;
    padding: 16px 22px 20px 20px;
    margin-top: 0px;
    border: solid 1px #ccc !important;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    z-index: 1;
}
.hotel_search_details_row .inputsearch_result input{
    width: 100%;
    height: 35px;
    padding: 10px;
    border-radius: 3px;
    border-radius: 3px;
    border: solid 1px #ccc !important;
    background-color: #fff;
    cursor: auto;
    font-size: 14px;
    font-weight: 500;
    caret-color: auto;
}

.hotel_search_details_row_mg {
    /* margin-left: auto;
    padding-left: 0; */
    width: 22%;
}
.hotel_search_details_row_mg .hotel_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 15px;
    width: 100%;
    height: 40px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 3px;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}
.hotel_search_widget_btn svg{
    position: absolute;
    left: 37px;
    top: 20px;
    width: 18px;
    height: 18px;
    color: var(--primary_color);
    cursor: pointer;
}
.hotel_search_btnClass{
    /* margin-left: 5px !important; */
    margin-left: auto;
    order: 2;
    margin-right: 3px;
    padding-left: 0 !important;
    width: 22%;
}
.nationality_cnt_wd{
    margin-left: 44.50% !important;
}
.hotel_search_left_icon span img{
    position: absolute;
    top: 15px;
    left: 7px;
    width: 30px;
    height: 30px;
}
.hotel_search_details_main{
    /* display: flex; */
}
.newhoteldate{
    width: 30%;
    background: #ffffff;
    margin-right: 3px;
    border-radius: 15px;
    /* padding: 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    border: 1px solid var(--buttons_color);
}
.newlefthoteldate{
    display: flex;
    width: 100%;
    position: relative;
    /* border-right: 1px solid #707070; */
}
.newspanhoteldate{
    width: 40%;
    display: grid;
    height: fit-content;
}
.newrighthoteldate{
    display: flex;
    width: 50%;
}
.newlefthoteldate .calendar_wid_container label{
    padding-left: 0px;
}
.newrighthoteldate input{
    width: 40%;
    padding-left: 10px;
    font-size: 24px;
}
.newrighthoteldate input::placeholder{
    font-family: var(--font_family);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    margin-right: 5px;
    color: #3479c5;
}
.newlefthoteldate input{
    font-size: 16px;
    width: 30%;
    margin-left: 17px;
    /* padding-top: 15px; */
}
.newlefthoteldate input::placeholder{
    font-family: var(--font_family);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    margin-right: 5px;
    color: #3479c5;
}
.newlefthoteldate span{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
}
.newspanhoteldate span{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
}
.hotel_adult_accomodation_tag{
    display: flex;
}
.hotel_adult_accomodation_tag span{
    padding-left: 10px;
}
.hotel_class_arrow{
    position: relative;
}
.hotel_class_arrow span{
    position: absolute;
    left: 14px;
    top: 11px;
    width: 20px;
    height: 20px;
    object-fit: scale-down;
    color: var(--primary_color);
}

.new_cal_main{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-evenly;
    cursor: pointer;
}


.new_cal_main:nth-child(1){
    border-right: 2px solid #e6e6e6;
    padding-right: 5%;
}
.flex_cont_cal:first-child {
    padding-right: 20px;
    border-right: 2px solid #ebebec;
}
.flex_cont_cal{
    display: flex;
    gap: 10px;
    align-items: center;
    /* border-right: 2px solid #e6e6e6;
    padding-right: 5%; */
}

.new_cal_digit_text{
    font-size: 35px;
    color: var(--primary_color);
    font-weight: 600;
}

.flight_search_wid_inputfield_img svg{
    width: 30px;
    height: 30px;
}

.vr_divider{
    width: 3px;
    height: 12px;
    background: #5d5b62;
}

.adult_room_flex {
    display: grid;
    padding-top: 0px;
    position: relative;
}
.hotel_search_details_row .adult_room_flex  svg{
position: absolute;
    right: 31px;
    top: 20px;
    width: 24px;
    height: 24px;
    color: var(--primary_color);
    cursor: pointer;
    left: unset;
}

.input_search_destination input {
    padding: 20px 40px;
    margin: 10px auto;
    border: 1px solid rgba(0, 0, 0, 0.16) !important;
    border-radius: 50px;
    width: 90%;
    display: block;
    height: 25px;
    box-sizing: border-box;
}
.hotel_autocomplete_value .input_search_destination svg{
    position: absolute;
    left: 31px;
    top: 22px;
    width: 20px;
    height: 19px;
    color: var(--button_color); 
}