.flight_search_wid_container{
    width: 100%;
    padding: 20px 25px 20px 31px;
    display: table;
    /* background: rgb(255,255,255,0.3); */
    background-color: #ffffffad;
    margin-bottom: 30px;
    position: relative;
    border-radius: 0 0 20px 20px;
    padding: 10px 20px;
}
.flight_way_tab{
    width: 100%;
    text-align: left;
    height: 40px;
}
.flight_way_btngrp{
    display: flex;
    flex-wrap: wrap;
    color: var(--primary_color);
}
.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message{
    position: relative;
    top: -11px;
    float: left;
    width: 104%;
}

.flight_search_details_main .flight_search_details_row .flight_search_column .flight_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.flight_way_btngrp .active_way{
    border: 2px solid;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--primary_color);
    border-radius: 15px;
    padding: 3px 15px;
    transition: 0.3s;
}

.flight_way_btngrp .button_style{
    border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--font_color);
}

.flight_way_btngrp .button_style:focus{
    color: var(--primary_color);
}
.flight_way_btngrp .button_style:hover{
    color: var(--primary_color);
}

.flight_search_details_container{
    width: 100%;
    margin-top: 0px;
}

.flight_search_details_container .flight_search_details_main {
    position: relative;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row{
    display: flex;
}
.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_search_column{
    float: left;
    margin-right: 3px;
    margin-bottom: 3px;
    position: relative;
    background: #ffff;
    border-radius: 15px;
    /* height: 105px; */
    padding-bottom: 5px;
    border: 1px solid var(--buttons_color);
}

.flight_search_details_main .flight_search_details_row .flight_search_column.flight_search_class{
    padding-right: 20px !important;
    padding-bottom: 10px;
}

.flight_search_details_container .flight_search_details_main .flight_search_details_row .flight_swap{
    position: absolute;
    left: 21%;
    /* z-index: 99; */
    z-index: 1;
    top: 19px;
    width: 30px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    border-radius: 50%;
}
.flight_search_details_row .flight_search_depart{
    width: 25%;
    min-width: 255px;
}
.flgt_auto_icon img{
    width: 18px;
    margin-right: 15px;
  }
  .flgt_auto_icon svg{
    position: inherit !important;
    margin-right: 10px;
    width: 20px !important;
    height: 20px !important;
  }
.flight_search_details_row .flight_search_depart_date{
    width: 15%;
}
.flight_search_details_row .flight_search_depart_going_date{
    width: 30%;
    cursor: pointer;
}
.flight_search_pax{
    height: 35px !important;
    display: flex !important;
}
/* .flight_search_pax .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;   
} */
.flight_search_details_row .flight_search_pax{
    width: 18%;
    min-width: 250px;
    font-family: var(--font_family);
    padding-left: 30px;
    padding-top: 3px;
    padding-right: 15px;
}
.flight_search_details_row .flight_search_class{
    width: 15%;
    display: flex;
}

.flight_search_details_row .flight_search_widget_btn{
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 100%;
    height: 60px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flight_search_widget_btn svg{
    width: 25px !important;
    height: 25px !important;
    margin-right: 6px;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
}

.flight_search_details_row .flight_search_widget_btn:hover, .flight_search_details_row .flight_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}

.flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;   
    padding-left: 10px;
    /* margin-top: 15px; */
}
.flight_search_wid_inputfield > input::placeholder{
    color: var(--primary_color);  
}
.flight_search_divpax{
    display: flex;
    width: 35%;
}
.flight_search_divpax small{
    padding: 0 5px;
}
.flight_search_divpax small svg{
    position: unset !important;
}
.flight_search_wid_inputfield .disable_pax_btn{
    /* opacity: 0.5; */
}
.flight_search_wid_inputfield > div svg{
    position: inherit !important;
}
.disable_pax_name svg{
    position: inherit !important;
}
/* .flight_search_wid_inputfield .add_pax{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 0;
}

.flight_search_wid_inputfield .minus_pax{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 0;
} */

.flight_search_wid_inputfield .disbale_traveller{
    opacity: 0.5;
}
   
.flight_search_wid_inputfield .flight_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
}    
    
.flight_search_details_main .flight_search_details_row:first-child label{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    /* padding-left: 35px; */
    padding-top: 6px;
}
.flight_search_depart .flight_search_wid_inputfield:first-child label{
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 600;
    color: var(--font_color);
    /* padding-left: 22px; */
    padding-top: 0;
}

.flight_search_paxcount{
    display: flex;
    float: left;
    margin-right: 3px;
    position: relative;
    padding: 10px 10px 10px 0;
    width: 100%;
}
.flight_search_paxcount label{
    padding-left: 15px !important;
}
.flight_search_paxcount span{
    font-family: var(--font_family);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 5px; 
}

.flight_search_details_main .flight_search_details_row:first-child>div{
    padding-left: 15px;
    padding-top: 10px;
    position: relative;
}
.flight_search_details_main .flight_search_details_row:first-child>div svg{
    /* position: absolute; */
    left: 14px;
    top: 11px;
    width: 30px;
    height: 30px;
    object-fit: scale-down;
    color: var(--primary_color);
    margin-right: 5px;
}
.flight_search_details_main .flight_search_details_row:first-child>div input
{
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    border: none;
    padding: 0 10px 0 0;
    height: 30px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    cursor: pointer;
}

.flight_search_details_main .flight_search_details_row:first-child>div input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

/* .flight_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 50px;
    z-index: -2;
    top: 60px;
    background-color: rgb(175, 78, 78);
    box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
} */

.flight_autocomplete_element{
    width: 100%;
    display: flex;
    padding: 5px 17px;  
    cursor: pointer;      
    align-items: center;
    min-height: 70px;
}

.flight_autocomplete_element:hover,
.flight_autocomplete_element:focus{
    background: #ccc;
}

.flight_autocomplete_tag{
    padding: 1px 5px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    vertical-align: top;
    display: inline-block;
    border-radius: 7px;
    margin-left: 10px;
}

.flight_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.flight_autocomplete_name span{
    width: 100%;
    margin-top: 0px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}

.search_widget_input_new{
    cursor: pointer;
}
.flight_search_left_icon{
    cursor: pointer;
    position: absolute;
    left: 5px;
    top: 21px;
}

.flight_search_details_row ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .flight_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .flight_search_details_row ::-webkit-scrollbar-thumb {
    /* background: black; */
    background: var(--primary_color);
    border-radius: 10px;
  }
  .flight_search_pax span{
    margin-bottom: 10px;
    font-family: var(--font_family);
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    display: inline-block;
    color: var(--font_color);
  }

  
  .flight_search_pax label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    display: inline-block;
    color: var(--font_color);
    margin-right: 3px;
  }

  .flight_search_pax .flight_search_wid_inputfield{
      display: flex;
      justify-content: space-between;
      margin-left: -15px;
      margin-top: -4px;
      width: 50%;
      user-select: none;
  }

  .flight_search_pax .flight_search_wid_inputfield span{
      margin-bottom: 0;
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--font_color);
      padding: 4px 4px;
      position: relative;
      height: 30px;
      text-align: center;
      border: none;
      margin-top: -2px;
  }

  .flight_cabin_class_cont{
      display: flex;
      align-items: center;
      font-family: var(--font_family);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--font_color);
     /* padding: 0 10px; */
      cursor: pointer;
      width: 50%;
      float: left;
      padding: 5px 0;
      /* padding: 5px 10px; */
  }
  .flight_cabin_class_cont img{
max-width: 25px;
margin: 0 17px 0 8px;
height: auto;
object-fit: contain;
  }
  .flight_cabin_class_list {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    border: 1px solid #e2e0e0;
    /* border-top: none; */
    border-radius: 20px;
    background: #fff;
    z-index: 1;
}
.flight_cabin_class_ele:first-child {
    border-radius: 20px 20px 0 0;
}
.flight_cabin_class_ele:last-child {
    border-radius: 0 0 20px 20px;
}
.flight_cabin_class_ele {
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px 10px;
    margin: 0px 0px;
}

.flight_cabin_class_ele:hover {
    background-color: var(--primary_color);
    color: #ffffff;
}

.flight_cabin_class_name{
    /* flex: 1 1; */
    /* float: left; */
    /* margin-top: 5px; */
    margin-left: 10px;
    width: 100%;
}
.flight_cabin_class_icon_img{
    height: 28px;
    width: 28px;
    color: var(--primary_color);
margin: 0 12px 0 8px;
}

.minus_pax, .add_pax{
    height: 30px;
    width: 30px !important;
    background-color: var(--primary_color);
    color: #fff !important;
    border-radius: 20px;
    cursor: pointer;
}

.flight_cabin_class_arrow svg{
    height: 15px;
    width: 15px;
    margin: 0 10px;
    color: var(--primary_color);
}

/* ====================== Desktop Style ====================== */

.calendar_wid_container{
    width: 100%;
    display: flex;
    align-items: center;
    /* border-bottom: 4px solid #e9e9eb; */
    height: 100%;
}
.calendar_wid_container h1{
    /* font-size: 16px;
    margin-top: -4px; */
    cursor: pointer;
}
.calendar_wid_container .calendar_wid_main{
    align-items: center;
    line-height: normal;    
    letter-spacing: normal;
    display: flex;
    /* gap: 25px; */
    /* margin-top: -4px; */
    /* margin-left: 30px; */
}
.calendar_wid_container .calendar_container{
    position: absolute;
    width: 650px;
    margin-top: 0;
    top: 25px;
    left: 0;
    transform: translate(-50%,0);
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--font_color);
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
    border: solid 1px #5d5b62;
    background-color: #ffffff;
    padding: 10px 8px;
}

.calendar_wid_container .calendar_container.left{
    left: 0 !important;
}

.calendar_wid_container .calendar_container.calendar_container_single{
    position: absolute;
    width: 320px;
    top: 65px;
    left: 50%;
    z-index: 10 !important;
    background-color: #fff;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    text-transform: uppercase;
    color: var(--font_color);
    padding-bottom: 15px;
    width: 100%;
    /* border-bottom: 1px solid #ddd; */
}
.calendar_head .calendar_head_center_side h3{
    border: 1px solid #b4b4c8;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 30px;
}
.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 10px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
}
.calendar_body{
    padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span{
    border-radius: 50px;
    color: #fff;
    background-color: var(--primary_color);
}
.calendar_head_icon svg{
    position: relative !important;
    top: unset !important;
    right: unset !important;
    /* color: var(--secondary_color) !important; */
    color: var(--primary_color) !important;
}

.flightautocomplete_main .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    padding: 0px 0px 0px 1px;
    width: 100%;
    /* height: 30px; */
    background: none;
}

.flight_autocomplete{
    width: 15%;
}

.flightsearchpage_widget{
    padding: 10px;
    margin: 0px 0px 10px 0px;    
    background: #e9e9eb;
    border-radius: 15px;
    width: 100%;
}
.flightsearchpage_widget_flex{
    display: flex;
    margin-top: 20px;
}
.flightsearchpage_widget_flex label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
}

.flightautocomplete{

}
.flight_swap{
    position: absolute;
    right: -17px;
    z-index: 1;
    top: 28px;
    width: 30px;
    cursor: pointer;
}
.flightautocomplete_main{
width: 22%;
margin-right: 5px;
background: #ffffff;
border-radius: 15px;
position: relative;
padding: 10px 10px 0 15px;
    border-radius: 15px;
}
.flightautocomplete_main:nth-child(1){
    border-radius: 10px 0px 0px 10px;
}
.flightautocomplete_main:nth-child(2){
    border-radius: 0px 10px 10px 0px;
}

.flightautocompletecalender{
    width: 17%;
    margin-right: 5px;
    display: block;
    background: #ffffff;
    border-radius: 15px;
    /* padding: 0px 10px; */
    position: relative;
}
.flightautocompletecalender:nth-child(3){
    border-radius: 10px;
    width:34%;
    display: flex;
    align-items: center;
}
.flightautocompletecalender:nth-child(4){
    border-radius: 0px 10px 10px 0px;
}
.flightautocompletetraveller{
    width: 100%;
    background: #ffffff;
    padding: 10px;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    align-items: center;
    position: relative;
}
.flightautocompletetraveller label{
    margin: 0px;
}

.flightautocompletetraveller svg {
    cursor: pointer;
    display: inline;
    /* margin: 0 6px; */
}

.flightautocomplemodify .flightautocompletetraveller input{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding: 10px 5px;
    width: 20%;
    height: 30px;
    text-align: right;
    background: none; 
}
.flightautocomplemodify .flightautocompletetraveller svg{
    width: 15px;
    /* position: absolute;
    right: 0;
    top: 45%; */
}
.flightautocompletecalender input{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    padding: 0px 0px 10px 1px;
    width: 100%;
    height: 30px;
    background: none; 
    cursor: pointer;
    padding-left: 25px;
}

.flightautocompletecalender .calendar_wid_main span{
    display: block;
    margin-right: 10px;
    margin-top: -10px;
    cursor: pointer;
        bottom: 10px;
    position: relative;
}

.flightautocompletecalender .calendar_wid_main{
    margin-top: 1px;
    width: 100%;
    /* border-bottom: 4px solid #e6e6e6; */
}
.flightsearchpage_widget .calendar_wid_container{
   position: relative;
    
}
.flight_autocomplete_value{
    position: absolute;
    width: 300px;
    max-height: 245px;
    z-index: 3;
    top: 60px;
    background-color: #fff;
    box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
    /* border: 1px #dcd8d8 solid; */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid transparent;
    border-radius: 15px;
}
.flighttraveller_drop{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    width: 240px;
    z-index: 9;
    top: 37px;
    right: 0;
    border: 0.5px solid #ddd;
    border-radius: 10px;
}
.flighttraveller_drop_flex{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 0px 10px;
    height: 50px;
  
}
.flighttraveller_drop_adult{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.flighttraveller_drop_paxconfig{
    display: flex;
    flex-direction: column;
    width: 50%;
    border-bottom: 1px #dcd8d8 solid;
}
.flighttraveller_drop_paxconfig .flight_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.flighttraveller_drop_economy{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
    height: 50px;
    position: relative;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label b{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
}
.flighttraveller_drop .flighttraveller_drop_flex .flighttraveller_drop_adult label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
}
.flighttraveller_drop_done{

}
.flighttraveller_drop_done button{
    width: 100%;
    min-height: 40px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    border: 1px solid var(--buttons_color);
    margin-top: 15px;
    cursor: pointer;
}
.flightautocomplemodify{
    width: 22%;
}
.flightautocomplemodify button{
    cursor: pointer;
    border-radius: 5px;
    height: 45px;
    width: 100%;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    border: 1px solid var(--buttons_color);
    border-radius: 0px 0px 15px 15px;
    position: relative;
    padding-left: 10px;
}
.flightautocomplemodify button svg{
    position: absolute;
    top: -2px;
    left: 14px;
    width: 20px;
}

.flightsearchpage_widget_flex .flight_search_error_message {
    position: absolute;
    bottom: 2px;
    width: 95%;
    line-height: 1;
}

.flightsearchpage_widget_flex .flight_search_error_message span{
    font-size: 8px;
    color: #e8664e;
    margin-top: 0px;
}
/* .calendar_wid_container .calendar_wid_main >span svg {
    position: absolute;
    left: 11px !important;
    top: 9px !important;
    width: 25px !important;
    height: 17px !important;
} */
.flightautocompletecalender .calendar_wid_container .calendar_wid_main >span svg {
    position: absolute;
    left: 8px!important;
    top: 0px!important;
    width: 30px!important;
    height: 30px!important;
    /* color: var(--primary_color); */
}
.flight_ptext{
    width: 100%;
}
.flight_ptext p{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #383838;
    white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
}
.flightsearchpage_widget .flight_ptext p{
    font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #383838;
  white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.flight_search_input{
    width: 100%;
}
.flight_cabin_class_arrow{
    position: relative;
}
.flight_cabin_class_arrow svg{
    position: absolute;
    left: 60px!important;
    top: -10px!important;
    width: 20px;
    height: 20px;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    color: var(--primary_color);
}
.flight_search_class_new{
    width: 15%;
    padding-left: 0px !important;
    padding-top: 0px !important;
}
.flight_search_class_new .flight_search_widget_btn{
    /* height: 80px; */
    height: 95%;
    border-radius: 15px;
}
.flight_search_pax_select_main{
    display: block;
    position: absolute;
    top: 63px;
    left: 0;
    z-index: 9;
    padding: 10px 25px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    width: 300px;
}
.flight_cabin_class_arrow_pass{
    position: relative;
    /* width: 100%; */
    /* right: -45px;
    top: -3px; */
}
.flight_cabin_class_arrow_pass_last{
    position: absolute;
    left: 85%;
    top: 45%;
}
.flight_cabin_class_arrow_pass_last svg{
    /* width: 15px;
    height: 15px; */
    object-fit: scale-down;
    color: var(--primary_color);
    fill: #878a8a;
}

.flight_cabin_class_arrow_pass_mod{
    position: relative;
    right: 0;
    top: 15px;
}
.flight_cabin_class_arrow_pass_mod svg{
    /* width: 15px;
    height: 15px; */
    object-fit: scale-down;
    color: var(--primary_color);
    fill: #878a8a;
}

.flight_cabin_class_arrow_pass svg{
    position: absolute;
    left: 25px!important;
    top: 0px!important;
    /* width: 15px;
    height: 15px; */
    object-fit: scale-down;
    color: var(--primary_color);
    fill: #878a8a;
}
.flight_search_leftpaxcount {
    width: 65%;
    display: flex;
}
.flight_search_pax_select_main .flight_search_pax{
    padding-left: 0px;
    padding-right: 0px;
}
.flight_search_pax_newleft{
    width: 70%;
}
.newcontainer{
    width: 100%;
    padding: 10px;
}
/* .flight_cabin_class_arrow_pass svg {
    width: 15px !important;
    right: 0;
    filter: grayscale(1);
    margin-left: auto;
    margin-right: 10px;
} */

.new_cal_main{
    display: flex;
    /* width: 70%; */
    margin: auto;
    justify-content: space-evenly;
    margin-left: 5px;
    cursor: pointer;
}


.new_cal_main:nth-child(1){
    border-right: 2px solid #e6e6e6;
    padding-right: 5%;
}

.flex_cont_cal{
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 8px;
    /* margin-left: 35px; */
    /* margin-top: -10px; */
    height: 43px;
    margin-right: 30px;
    /* width: 100px; */

}

.new_cal_text p{
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
}

.new_cal_digit_text{
    font-size: 30px;
    color: var(--primary_color);
    font-weight: 600;
}

.flight_search_wid_inputfield_img svg{
    width: 30px;
    height: 30px;
}

.new_tap_text {
    font-size: 13px;
    color: var(--font_color);
    font-weight: 600;
}
.flightfirstdiv{
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.flightfirstdiv label {
    cursor: pointer;
} 

.flightfirstdiv input {
    cursor: pointer;
}

.flightsearchpage_widget .flightautocomplete_main .flight_search_wid_inputfield label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .26px;
    color: var(--font_color);
    margin: 0 0 10px;
    padding-left: 22px;
}

.flightsearchpage_widget .flightautocomplete_main .flight_search_wid_inputfield .flight_search_left_icon{
    position: absolute;
    left: 14px;
    top: 11px;
    width: 25px;
    height: 25px;
    object-fit: scale-down;
    color: var(--primary_color);
}

.search_depart_going_date{
    padding-left: 0;
}
.flight_search_details_container .flight_search_depart:nth-child(1){
    border-radius: 15px 0px 0px 15px !important;
}
.flight_search_details_container .flight_search_depart:nth-child(3){
    border-radius: 0px 15px 15px 0px !important;
}
.flight_search_details_container .flight_search_details_main .flight_search_depart_going_date:nth-child(4){
    border-radius: 15px !important;
}
.flight_search_details_container .flight_search_details_main .flight_search_depart_going_date:nth-child(5){
    border-radius: 0px 15px 15px 0px !important;
}
.flight_search_depart_going_date .calendar_wid_main{
    margin-left: 10px;
}
.flight_search_depart_going_date .new_cal_main{
    margin-top: 0;
    width: 20px;
}
.flight_search_depart_going_date .calendar_wid_container{
    border-bottom: 2px solid #000;
    height: 40px;
}
.flight_search_column.flight_search_depart_going_date {
    padding-left: 0 !important;
}
.flight_search_details_container .flight_swap{
    top: 45px !important;
}
.flight_search_column.flight_search_depart {
    padding-left: 20px !important;
}

.flight_search_depart_going_date .flight_cabin_class_cont .flight_cabin_class_arrow_pass svg{
    left: auto !important;
    right: 0;
}
.flight_search_class .flight_cabin_class_arrow_pass svg{
    left: 100% !important;
    right: auto;
}
.flight_search_depart_going_date .calendar_wid_main span svg{
    width: 30px !important;
    height: 35px !important;
}
.flight_search_depart .flight_ptext p{
    width: 90%;
}
.autocomp_container{
    overflow-y: scroll;
    height: 180px;
    margin-right: 5px;
}
.flight_search_inpbox {
    border: 1px solid #b4b4c8;
    margin: 10px 15px;
    border-radius: 20px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
}
.flight_search_inpbox svg {
    display: inline;
    width: auto;
    height: 15px;
    margin-right: 4px;
}
.flight_search_inpbox input {
    outline: none;
    border: none;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #cbe3f5; */
    background-color:#d6e4f4;
    opacity: 0.5;
    color: #464646;
}

.cal_parent_div{
    display: flex;
    justify-content: center;
    gap: 10px;
    /* background: red; */
    border-bottom: 2px solid black;
    padding-bottom: 10px;
}

.main_div{
    width: 100%;
    display: flex;
    align-items: flex-start;
    /* height: 50%; */
    /* padding-top: 10px; */
    /* border-bottom: 4px solid #e9e9eb; */
}
.main_div > .calendar_wid_container{
    /* border-bottom: 4px solid #e9e9eb; */
}

.hightAdj{
    height: max-content;
    width: 100%;
}

.class_select_flex{
    background: #e9e9eb;
    width: 100%;
    height: 4px;
}

.svgoneway{
    height: 40px;
    width: 40px;
    margin-top: 2px;
}