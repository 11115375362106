

.pointer{
    width: 20px !important;
    height: 20px;
}

.dropDownlistmain{
    background-color: #fff;
}
.maininput{
    border: none;
}
.subinput{
  margin: 0 11px 9px 10px;
  padding: 13px 57px 11px 12px;
  border: solid 1px #d6d6d6;
  background-color: #fff;
}
.drop_wd{
  width: 100%;
}






.dropDownlistmain {
    position: relative;
    border: 1px solid #e0e0e0;
    margin-top: 4px;
    z-index: 9;
  }
  
  .dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100%;
    padding: 4px 15px;
    border: 1px solid 
    #d6d6d6;
    border-radius: 50px;
    background: #fff;
  }
  .dropDownlistmain ul li input[type="text"]::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--font_color);
    padding-left: 3px; 
  }
  
  .dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
  }
  
  .dropDownlistmain ul li span {
    margin-right: 4px;
  }
  .dropdown_value_container{
    max-height: 280px;
    overflow: auto;
  }









  .checkbox_label{
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    align-items: flex-start;
  }
  .checkbox_label .bagCheckbox{
    position: relative;
    margin-left: 0 !important;
  }
  .checkbox_label .bagCheckbox input{
    cursor: pointer;
      height: 20px;
      opacity: 0;
      position: absolute;
      width: 20px;
      z-index: 2;
  }
  .checkbox_label .bagBox{
    align-items: center;
    background: #fff;
    border: 1px solid var(--secondary_color);
    border-radius: 15px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    justify-content: center;
    position: relative;
    width: 14px;
    height: 15px;
    margin-left: 0 !important;
}
  
  
  .checkbox_label .bagCheck{
    border-color: #fff;
    -o-border-image: none;
    border-image: none;
    border-style: none solid solid none;
    border-width: medium 2px 2px medium;
    box-sizing: border-box;
    height: 11px;
    opacity: 0;
    position: relative;
    top: -2px;
    left: 2px;
    transform: scale(1) rotate(45deg);
    transition-delay: .1s;
    width: 5px;
    z-index: 1;
    margin-left: 0;
  }
  .checkbox_label input[type=checkbox]:checked~.bagBox {
    background: var(--secondary_color);
    border: 0;
  }
  .checkbox_label .bagCheckbox input[type=checkbox]:checked~.bagBox .bagCheck {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
  .advTitle{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .38px;
    text-align: center;
    color: var(--primary_color);
    /* margin-left: 4px; */
  }
  .dropdown_Flx{
    display: flex !important;
    align-items: center;
    /* padding-left: 15px; */
  }
  .dropdown_Flx svg{
    height: auto !important;
  }


.dropdown_value_container::-webkit-scrollbar {
  width: 10px;
}

.dropdown_value_container::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
.dropdown_value_container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.dropdown_value_container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



.advTitle {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  margin-bottom: 5px;
}

.checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.advTitle::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 2px solid #7f747c;
  border-radius: 50%;
}

.checkbox_label input:checked ~ .advTitle::before {
  border-color: var(--secondary_color); 
}

.checkbox_label input:checked ~ .advTitle::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 1px;
  background-color: var(--secondary_color);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  text-align: center;
  color: white;
  font-family: Times;
  border-radius: 50%;
  margin: 3px;
}

.advTitle:hover::before {
  border-color: #555;
}

.checkbox_label input:disabled ~ .advTitle::before {
  background-color: #ccc;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .advTitle{
    padding-left: 25px !important;
  }
    .dropDownlistmain {
      position: absolute;
      left: 0;
      right: 0;
      border: 1px solid #d4d4d4;
      border-radius: 10px;
      margin-top: 4px;
      z-index: 9;
  }
  .dropDownlistmain ul {
    border-radius: 10px;
  }
}
