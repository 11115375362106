.tnc_txt a {
  color: var(--primary_color) !important;
}
.firstnobanner, .lastnobanner {
  display: block;
}


.holidaymaintabnavbarrow {
  width: 100%;
  border-radius: 3px;
}
.holidaycarouselcontainer {
  width: 100%;
  margin: 0 auto;
}
.navbartab > ul {
  list-style: none;
  margin: 0px 0px;
  padding: 0px 0px;
  display: flex;
  gap: 20px 34px;
  align-items: center;
}

.navbartab ul .activetab {
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  opacity: 1;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  border: solid 2px var(--primary_color);
  background-color: transparent;
  flex-direction: column;
  padding: 0;
  border: 0;
}
.navbartab ul .activetab:first-child {
  border-radius: 15px;
}
.navbartab ul li {
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
}



.search {
  margin: auto;
  padding-bottom: 30px;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main {
  padding: 10px 0px;
  background: #e2e2e2;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible {
  display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header {
  display: flex;
  height: 60px;
  background: #fff;
  align-items: center;
  padding: 0px 15px;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image {
  width: 23px;
  height: 14px;
  margin-right: 25px;
}

.calendar_calendar_wid_container {
  width: 100%;
  margin-top: -5px;
}

.header_hero_cont {
  margin: 20px;
}
.header_hero_cont h2 {
  font-family: var(--font_family);
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.35px;
  color: var(--font_color);
}
.header_hero_cont p {
  font-family: var(--font_family);
  font-size: 16px;
  letter-spacing: 0.35px;
  color: var(--font_color);
}

.service_icon {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 50px;
  padding: 10px;
  background-color: #e4e4e4;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbartab ul .activetab .service_icon {
  background: yellow;
  padding: 0 2px;
  width: 56px;
  height: 56px;
}
.service_icon img {
  width: 20px;
  display: inline;
  width: 100%;
  object-fit: contain;
}
.activetab .service_icon img {
  /* filter: invert(0); */
  outline: none;
  width: 100%;
  object-fit: contain;
}


.widget_container {
  height: auto;
  position: absolute;
  z-index: 0;
  top: -258px;
  left: 0;
  right: 0;
  z-index: 99;
}

.img_banner_home_thtwo {
  margin: 0;
  height: 530px;
}
.styleBanner {
  height: 100%;
  width: 100%;
}
.carouse_container2 {
  padding: 0;
}
.carouse_container2 ul li {
  height: 396px;
  background: #eee;
}
.img_banner_home2 {
  /* height: 550px; */
  height: 70vh;
  width: 100%;
}

.img_banner_home2 a span img {
  object-fit: cover !important;
}
.search_widget_container{
  padding: 0;
}
.mainWidget_container{
  width: 1191px;
  max-width: 1191px;
  margin: 0 auto;
}
.widget_parent_container{
  width: 934px;
  max-width: 934px;
  margin: 0 auto;
  margin-left: 0;
}
.navbartab ul li .service_icon:hover{
  background: #F5F5F5;
}
.navbartab ul li .service_icon:hover img {
  filter: brightness(10) invert(1);
}

.navbartab ul li .service_icon {
  border-radius: 5px 0px 5px 5px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 56px;
  height: 56px;
  padding: 13px 15px;
}
.navbartab ul .activetab .service_icon {
  background: var(--primary_color);
  padding: 13px 15px;
}

.checkbox_container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 21px;
}
.checkbox {
  padding: 0 0 0;
}
.checkbox label {
  font-size: 13px;
  position: relative;
  cursor: pointer;
  padding: 0 5px;
}
.check_label{
  color: var(--font_color);
font-family: var(--font_family);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal; /* 19.6px */
}
.checkbox_label {
  position: relative;
  /* padding-left: 28px; */
  /* margin: 5px 0; */
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
.checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark_fil {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 3px 0px 3px 3px;
  border: 1px solid #B8B8B8;
}
.checkbox_label:hover input ~ .checkmark_fil {
  background-color: transparent;
}
.checkbox_label input:checked ~ .checkmark_fil {
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
}
.checkmark_fil:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox_label input:checked ~ .checkmark_fil:after {
  display: block;
}
.checkbox_label .checkmark_fil:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox_label :global .vms_inputcheckbox_label {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  line-height: normal;
  color: var(--font_color);
  font-family: var(--font_family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
}

.checkbox_label :global .vms_inputcheckbox_indicator {
  margin-right: 9px;
  border-radius: 3px 0px 3px 3px;
  border: 1px solid #B8B8B8;
  width: 20px;
  height: 20px;    
  background: transparent;
}
.checkbox_label :global .vms_inputcheckbox_maindiv .vms_inputcheckbox_input:checked + .vms_inputcheckbox_indicator{
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
}


.checkbox_label :global .vms_inputcheckbox_input:checked + .vms_inputcheckbox_indicator::after {
  content: '';
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none"><path d="M11.7081 1.70806L4.70806 8.70806C4.61518 8.80103 4.50489 8.87479 4.3835 8.92512C4.2621 8.97544 4.13197 9.00134 4.00056 9.00134C3.86914 9.00134 3.73901 8.97544 3.61762 8.92512C3.49622 8.87479 3.38593 8.80103 3.29306 8.70806L0.293056 5.70806C0.105415 5.52042 0 5.26592 0 5.00056C0 4.73519 0.105415 4.4807 0.293056 4.29306C0.480696 4.10541 0.735192 4 1.00056 4C1.26592 4 1.52042 4.10541 1.70806 4.29306L4.00056 6.58681L10.2931 0.293056C10.386 0.200145 10.4963 0.126445 10.6177 0.0761623C10.7391 0.0258797 10.8692 0 11.0006 0C11.132 0 11.2621 0.0258797 11.3835 0.0761623C11.5048 0.126445 11.6151 0.200145 11.7081 0.293056C11.801 0.385966 11.8747 0.496267 11.9249 0.61766C11.9752 0.739053 12.0011 0.869161 12.0011 1.00056C12.0011 1.13195 11.9752 1.26206 11.9249 1.38345C11.8747 1.50485 11.801 1.61515 11.7081 1.70806Z" fill="white"/></svg>');
  background-size: contain;
  border: none;
  transform: translate(-50%, -50%);
  width: 12px;
  background-repeat: no-repeat;
  height: 9px;
  top: 50%;
  left: 50%;
}

/* .checkbox_label :global .vms_inputcheckbox_indicator {
  background: var(--primary_color);
  border: 1px solid var(--primary_color);
} */
.nobanner {
  height: 396px;
  background-color: #ddd;
}
.activetab .service_label {
  font-weight: 500;
}


/* ------ WEDGET POSITION MAC SCREEN------- */
@media only screen and (min-width: 1024px) and (max-width: 1249px) {
  /* .img_banner_home2 {
    height: 80vh;
    width: 100%;
  }

  .widget_container {
    position: absolute;
    top: 35%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  } */
}

/* ------ WEDGET POSITION LARGE SCREEN------- */
@media only screen and (min-width: 1024px) and (max-width: 2140px) {
  .img_banner_home2 {
    /* height: 40vh; */
    height: 223px;
    width: 100%;
  }

  .widget_container {
   
  }
}

/* -----WEDGET POSITON 4K ------ */
@media only screen and (min-width: 2400px) and (max-width: 3850px) {
  .img_banner_home2 {
    height: 40vh;
    width: 100%;
  }
  .widget_container {
    /* position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%); */
    width: max-content;
  }
}

/* ------ WEDGET POSITION SMALLEST SCREEN------- */
/* @media only screen and (min-width: 768px) and (max-width: 102px) {
  .img_banner_home2 {
    height: 100vh;
    width: 100%;
  }

  .widget_container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
} */

/* @media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .img_banner_home2 {
    height: 70vh;
    width: 100%;
  }

  .widget_container {
    position: absolute;
    top: 25%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
} */

/* SMALLEST WIDGET ADJUST */
/* @media only screen and (height: 768px) and (width: 1024px) {
 

  .widget_container {
    position: absolute;
    top: 30%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
} */

.populardest_title {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  margin-right: 20px;
}
.popular_destination {
  background-color: #ececec;
  padding: 9px;
  border-radius: 0 0 8px 8px;
  border-top: none;
  display: flex;
  align-items: center;
}

.destination_container {
  display: flex;
  gap: 20px;
}

.destination_active {
  background-color: var(--primary_color);
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 10px;
}

.destination_item .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  margin-bottom: 3px;
}
.destination_active .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 3px;
}

.destination_item .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: var(--font_color);
}
.destination_active .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #fff;
}

.destination_item {
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 10px;
}
.widget_parent {
  position: relative;
}
/* .holiday_widget {
  height: 130px;
}
.activity_widget {
  height: 150px;
}
.hotel_widget {
  height: 210px;
} */
/* .flight_widget {
  height: 240px;
} */
.home_container {
  min-height: 100vh;
}
.service_label {
  color: var(--secondary_color);
font-family: var(--font_family);
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal; 
}

/* for mobile section =====> */
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .all_section {
    padding: 25px 20px 10px !important;
  }
}

.hotel_header {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family:var(--font_family);
  font-size: 25px;
  line-height: 1.1;
  position: absolute;
    top: -354px;
}

.hotel_header__title {
  color: var(--primary_color);
  font-weight: 700;
  align-self: flex-start;
  margin: 0;
  font-size: 25px;
}

.hotel_header__subtitle {
  color: var(--secondary_color);
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 25px;
}


@media only screen and (max-width: 768px) and (min-width: 320px) {
  .hotel_header {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-family:var(--font_family);
    font-size: 25px;
    line-height: 1.1;
    position: unset;
    padding: 0 15px;
    padding-top: 50px;
  }
  
  .hotel_header__title {
    color: var(--primary_color);
    font-weight: 700;
    align-self: flex-start;
    margin: 0;
    font-size: 18px;
  }
  
  .hotel_header__subtitle {
    color: var(--secondary_color);
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 15px;
  }

  .all_section {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px 30px;
    padding: 25px 10px 20px;
    overflow: auto;
    padding: 25px 15px;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
  }
  .carouse_container2 ul li,
  .nobanner{
    height: 490px;
    margin-top: 15px;
  }

  .navbartab ul li .service_icon {
    width: 45px;
    height: 45px;
    padding: 10px 12px;
}

.navbartab ul .activetab .service_icon {
    width: 45px;
    height: 45px;
    padding: 10px 12px;
}

.navbartab > ul {
    gap: 20px 30px;
    padding: 0 15px;
}

.navbartab ul li {
    gap: 11px 0;
}

  .widget_parent_container{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-left: 0;
  }

  /* width */
  .all_section::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
 
/* Handle */
  .all_section::-webkit-scrollbar-thumb {
  background: #e4e4e4; 
  border-radius: 10px;
}

  .section_div {
    width: max-content;
    border-radius: 10px;
    text-align: center;
    padding: 0 0px;
  }
  .main_tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    gap: 11px;
  }
  .service_icon {
    width: 45px;
    height: 45px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0px 5px 5px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 0;
  }
  .service_icon > img {
    width: 23px;
    height: 25px;
    text-align: center;
    display: inline-block;
  }
  .section_div label {
    display: block;
    font-stretch: normal;
    letter-spacing: 0.45px;
    text-align: center;
    margin-top: 0;
    color: var(--secondary_color);
    font-family: var(--font_family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .active_service {
    background-color: var(--primary_color);
  }
  .active_service img {
    filter: brightness(0) invert(1);
  }
  .section_div .active_label {
    font-weight: 500;
  }
  .mobHeader_container{
    background-color: transparent;
    margin-top: 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: 165px;
  }
.carousal_container{
  position: absolute;
  left: 0;
  right: 0;
}
.mainWidget_container {
  width: 100%;
  max-width: 100%;
}
.widget_container {
  position: static;
}
.carouse_container2 ul li,
.nobanner{
  height: 490px;
  margin-top: 15px;
}
.search {
  padding-bottom: 0;
}
.search_widget_container {
  padding-top: 36px;
}

}


@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  .home_container :global .react-multi-carousel-track {
    width: 100%;
  }
}
