.tnc_txt a {
  color: var(--primary_color) !important;
}

/* .abc{
color: red;
} */

.holidaymaintabnavbarrow {
  width: 100%;
  /* background-color: #ffffff;
    padding: 12px 0px 0px; */
  border-radius: 3px;
}
.holidaycarouselcontainer {
  /* width: 1200px; */
  width: 100%;
  margin: 0 auto;
  /* padding: 0 10px; */
}
.navbartab > ul {
  list-style: none;
  margin: 0px 0px;
  padding: 0px 0px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbartab ul .activetab {
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  opacity: 0.9;
  /* padding: 10px 8px 10px 10px; */
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  border: solid 2px var(--primary_color);
  background-color: #fff;
  height: 46px;
}
.navbartab ul .activetab:first-child {
  /* border-radius: 20px 0 0 0; */
  border-radius: 15px;
}
.navbartab ul li {
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #565656; */
  color: var(--font_color);
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* 
.navbartab >ul >li{
    float: left;
    padding: 0px 20px;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    line-height: 2.02;
    text-align: center;
    color: var(--font_color);
    cursor: pointer;
} */

.search {
  border-radius: 20px 20px 0 0;
  padding: 18px 21px;
  border-radius: 8px;
  box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 85%;
  margin: auto;
  position: relative;
  top: 40px;
  z-index: 1;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main {
  padding: 10px 0px;
  background: #e2e2e2;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible {
  display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header {
  display: flex;
  height: 60px;
  background: #fff;
  align-items: center;
  padding: 0px 15px;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image {
  width: 23px;
  height: 14px;
  margin-right: 25px;
}

.calendar_calendar_wid_container {
  width: 100%;
  margin-top: -5px;
}

.header_hero_cont {
  margin: 20px;
}
.header_hero_cont h2 {
  font-family: var(--font_family);
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.35px;
  color: var(--font_color);
}
.header_hero_cont p {
  font-family: var(--font_family);
  font-size: 16px;
  letter-spacing: 0.35px;
  color: var(--font_color);
}

.service_icon {
  display: inline-block;
  margin-right: 5px;
  border-radius: 50px;
  padding: 10px;
  background-color: #e4e4e4;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbartab ul .activetab .service_icon {
  /* background: yellow; */
  background: transparent;
  padding: 0 2px;
  /* filter: invert(35%) sepia(14%) saturate(4503%) hue-rotate(178deg) brightness(95%) contrast(85%); */
  width: 30px;
  height: 30px;
}
.service_icon img {
  width: 20px;
  display: inline;
  /* filter: invert(1); */
  width: 100%;
  object-fit: contain;
}
.activetab .service_icon img {
  /* filter: invert(0); */
  outline: none;
  width: 100%;
  object-fit: contain;
}
/* .widget_container{
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
} */

.widget_container {
  /* position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: max-content; */
  margin: 0 auto;
  margin-top: -185px;
}

.img_banner_home_thtwo {
  margin: 0;
  height: 530px;
}
.styleBanner {
  height: 100%;
  width: 100%;
}
.carouse_container2 {
  padding: 0;
}
.img_banner_home2 {
  /* height: 550px; */
  height: 70vh;
  width: 100%;
}

.img_banner_home2 a span img {
  object-fit: cover !important;
}

/* ------ WEDGET POSITION MAC SCREEN------- */
@media only screen and (min-width: 1024px) and (max-width: 1249px) {
  /* .img_banner_home2 {
    height: 80vh;
    width: 100%;
  }

  .widget_container {
    position: absolute;
    top: 35%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  } */
}

/* ------ WEDGET POSITION LARGE SCREEN------- */
@media only screen and (min-width: 1024px) and (max-width: 2140px) {
  .img_banner_home2 {
    /* height: 40vh; */
    height: 223px;
    width: 100%;
  }

  .widget_container {
    /* position: absolute;
  top: 55%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: max-content;
  z-index: 5; */
    z-index: 99;
    height: 130px;
    height: unset;
    position: unset;
    margin-top: -185px;
    transform: inherit;
    left: auto;
    right: auto;
    width: auto;
  }
}

/* -----WEDGET POSITON 4K ------ */
@media only screen and (min-width: 2400px) and (max-width: 3850px) {
  .img_banner_home2 {
    height: 40vh;
    width: 100%;
  }
  .widget_container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
}

/* ------ WEDGET POSITION SMALLEST SCREEN------- */
/* @media only screen and (min-width: 768px) and (max-width: 102px) {
  .img_banner_home2 {
    height: 100vh;
    width: 100%;
  }

  .widget_container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
} */

/* @media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .img_banner_home2 {
    height: 70vh;
    width: 100%;
  }

  .widget_container {
    position: absolute;
    top: 25%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
} */

/* SMALLEST WIDGET ADJUST */
/* @media only screen and (height: 768px) and (width: 1024px) {
 

  .widget_container {
    position: absolute;
    top: 30%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
} */

.populardest_title {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  margin-right: 20px;
}
.popular_destination {
  background-color: #ececec;
  padding: 9px;
  border-radius: 0 0 8px 8px;
  border-top: none;
  display: flex;
  align-items: center;
}

.destination_container {
  display: flex;
  gap: 20px;
}

.destination_active {
  background-color: var(--primary_color);
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 10px;
}

.destination_item .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  margin-bottom: 3px;
}
.destination_active .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 3px;
}

.destination_item .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: var(--font_color);
}
.destination_active .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #fff;
}

.destination_item {
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 10px;
}
.widget_parent {
  position: relative;
}
/* .holiday_widget {
  height: 130px;
}
.activity_widget {
  height: 150px;
}
.hotel_widget {
  height: 210px;
} */
/* .flight_widget {
  height: 240px;
} */
.home_container {
  min-height: 100vh;
}
.service_label {
}

/* for mobile section =====> */

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .all_section {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    padding: 25px 10px 20px;
    overflow: scroll;
    padding: 25px 10px 10px !important;
    margin-bottom: 10px;
  }

  /* width */
  .all_section::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
 
/* Handle */
  .all_section::-webkit-scrollbar-thumb {
  background: #e4e4e4; 
  border-radius: 10px;
}

  .section_div {
    width: max-content;
    border-radius: 10px;
    text-align: center;
    padding: 0 5px;
  }
  .main_tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
  }
  .service_icon {
    width: 45px;
    height: 45px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #e4e4e4;
  }
  .service_icon > img {
    width: 23px;
    height: 25px;
    text-align: center;
    display: inline-block;
  }
  .section_div label {
    text-align: center;
    display: block;
    margin-top: 10px;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    text-align: left;
    color: var(--font_color);
  }
  .active_service {
    background-color: var(--primary_color);
  }
  .active_service img {
    filter: brightness(0) invert(1);
  }
  .section_div .active_label {
    color: var(--primary_color);
  }
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .all_section {
    padding: 25px 20px 10px !important;
  }
}
