.tnc_txt a {
  color: var(--primary_color) !important;
}

/* .abc{
color: red;
} */

.holidaymaintabnavbarrow {
  width: 100%;
  /* background-color: #ffffff;
    padding: 12px 0px 0px; */
  border-radius: 3px;
}
.holidaycarouselcontainer {
  /* width: 1200px; */
  width: 100%;
  margin: 0 auto;
  /* padding: 0 10px; */
}
.navbartab > ul {
  list-style: none;
  margin: 0px 0px;
  padding: 0px 0px;
  display: flex;
}

.navbartab ul .activetab {
  /* padding-bottom: 10px;
    padding: 0px 8px 10px; */
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--primary_color);
  background: #fff;
  opacity: 0.9;
}
.navbartab ul .activetab:first-child {
  border-radius: 20px 0 0 0;
}
.navbartab ul li {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  /* color: #565656; */
  color: #fff;
  cursor: pointer;
  padding: 10px 15px;
}

/* 
.navbartab >ul >li{
    float: left;
    padding: 0px 20px;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    line-height: 2.02;
    text-align: center;
    color: var(--font_color);
    cursor: pointer;
} */

.search {
  /* background: #e2e2e2;
    padding: 20px 32px; */
  background: var(--secondary_color);
  border-radius: 20px 20px 0 0;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main {
  padding: 10px 0px;
  background: #e2e2e2;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible {
  display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header {
  display: flex;
  height: 60px;
  background: #fff;
  align-items: center;
  padding: 0px 15px;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image {
  width: 23px;
  height: 14px;
  margin-right: 25px;
}

.calendar_calendar_wid_container {
  width: 100%;
  margin-top: -5px;
}

.header_hero_cont {
  margin: 20px;
}
.header_hero_cont h2 {
  font-family: var(--font_family);
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.35px;
  color: var(--font_color);
}
.header_hero_cont p {
  font-family: var(--font_family);
  font-size: 16px;
  letter-spacing: 0.35px;
  color: var(--font_color);
}

.service_icon {
  display: inline;
  margin-right: 5px;
  padding: 5px;
  border-radius: 8px;
}
.navbartab ul .activetab .service_icon {
  background: yellow;
}
.service_icon img {
  width: 20px;
  display: inline;
  filter: invert(1);
}
.activetab .service_icon img {
  filter: invert(0);
}
/* .widget_container{
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
} */

.widget_container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: max-content;
}

.img_banner_home_thtwo {
  margin: 0;
  height: 530px;
}
.styleBanner {
  height: 100%;
  width: 100%;
}
.carouse_container2 {
  padding: 0;
}
.img_banner_home2 {
  /* height: 550px; */
  height: 70vh;
  width: 100%;
}

.img_banner_home2 a span img{
  object-fit: cover !important;
}
.home_container{
  min-height: 100vh;
}

/* ------ WEDGET POSITION MAC SCREEN------- */
@media only screen and (min-width: 1024px) and (max-width: 1249px){
  .img_banner_home2 {
      height: 80vh;
      width: 100%;
    }
  
    .widget_container {
      position: absolute;
      top: 35%;
      left: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      width: max-content;
    }
}

/* ------ WEDGET POSITION LARGE SCREEN------- */
@media only screen and (min-width: 1250px) and (max-width: 2140px) {
.img_banner_home2 {
  height: 80vh;
  width: 100%;
}

.widget_container {
  position: absolute;
  top: 55%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: max-content;
  z-index: 10;
}
}

/* -----WEDGET POSITON 4K ------ */
@media only screen and (min-width: 2400px) and (max-width: 3850px) {
  .img_banner_home2 {
      height: 40vh;
      width: 100%;
    }
  .widget_container {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      width: max-content;
    }
}

/* ------ WEDGET POSITION SMALLEST SCREEN------- */
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .img_banner_home2 {
      height: 100vh;
      width: 100%;
    }
  
    .widget_container {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      width: max-content;
    }
}


@media only screen and (min-width: 1024px) and (max-width: 1260px){
  .img_banner_home2 {
      height: 70vh;
      width: 100%;
    }
  
    .widget_container {
      position: absolute;
      top: 25%;
      left: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      width: max-content;
    }
}


/* SMALLEST WIDGET ADJUST */
@media only screen and (height: 768px) and (width: 1024px){
  /* .img_banner_home2 {
    height: 70vh;
    width: 100%;
  } */

  .widget_container {
    position: absolute;
    top: 30%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: max-content;
  }
}

.other_icon{
  filter: invert(0) !important;
}