.tnc_txt a{
    color: var(--primary_color) !important;
}

.abc{
color: red;
}



.holidaymaintabnavbarrow{
    width: 100%;
    background-color: #ffffff;
    padding: 12px 0px 0px;
    border-radius: 3px;
} 
.holidaycarouselcontainer{
    /* width: 1200px; */
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
.navbartab >ul{
    list-style: none;
    margin: 0px 0px;
    padding: 0px 0px;
    display: flex;
    justify-content: space-between;
}


.navbartab ul .activetab{
    border-bottom: 2px solid var(--primary_color);
    padding-bottom: 10px;
    padding: 0px 8px 10px;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: var(--primary_color);
}

 .navbartab ul  li {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: #565656;
    cursor: pointer;
}


/* 
.navbartab >ul >li{
    float: left;
    padding: 0px 20px;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    line-height: 2.02;
    text-align: center;
    color: var(--font_color);
    cursor: pointer;
} */


.search{
    background: #e2e2e2;
    padding: 20px 32px;

}


.mob_flight_search_wid_container .mob_flight_search_wid_main{
    padding: 10px 0px;
  background: #e2e2e2;
    
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible{
    display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header{
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
    padding: 0px 15px;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image{
    width: 23px;
    height: 14px;
    margin-right: 25px;
}


.calendar_calendar_wid_container {
    width: 100%;
    margin-top: -5px;
}

.home_container{
    min-height: 100vh;
  }