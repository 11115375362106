.mob_hotel_search_wid_container{
    width: 100%;
    background: #e2e2e2;
    /* min-height: 100vh; */
}

.mob_hotel_search_wid_container .mob_hotel_search_wid_main{
    padding: 10px 0px;
    padding-top: 0;
}

.mob_hotel_search_wid_container .mob_hotel_search_wid_main .mobile_visible{
    display: block;
}

.mob_hotel_search_wid_container .mob_hotel_search_wid_main .mobile_visible .mobo_tab_header{
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
    /* justify-content: space-around; */
    padding: 0px 15px;
}
.mob_hotel_search_wid_container .mob_hotel_search_wid_main .mobile_visible .arrow_image{
    width: 23px;
    height: 14px;
    margin-right: 25px;
}

.mob_hotel_search_wid_container .mob_hotel_search_wid_main .mobile_visible .search_filter_header{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
   color: var(--primary_color);
}

.mob_hotel_search_details_container{
    width: 100%;
    background: #e2e2e2;
    margin-top: 20px;
    padding: 10px;
}
.mob_hotel_search_details_container .mob_hotel_search_details_main .mob_hotel_search_details_row{
    display: flex;
    flex-wrap: wrap;
}
.mob_hotel_search_details_container .mob_hotel_search_details_main .mob_hotel_search_details_row .mob_hotel_search_column{
    float: left;
    margin-right: 3px;
    margin-bottom: 3px;
    position: relative;
    background: #ffff;
    border-radius: 3px;
    height: 65px;
    margin-bottom: 12px;
    width: 100%;
}
.mob_hotel_search_wid_inputfield{
    align-items: center;
    line-height: normal;
    letter-spacing: normal;   
}
.mob_hotel_search_wid_inputfield .mob_hotel_search_wid_inputfield_main{
    display: flex;
    flex-wrap: wrap;
}
.mob_hotel_search_details_main .mob_hotel_search_details_row .mob_hotel_search_name{
    width: 398px;
}
.mob_hotel_search_details_main .mob_hotel_search_details_row .mob_hotel_search_date{
    width: 500px;
}
.mob_hotel_search_details_main .mob_hotel_search_details_row .mob_hotel_search_accomodation{
    width: 251px;
}
.mob_hotel_search_widget_btn:hover, .mob_hotel_search_widget_btn:focus{
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform .3s ease;
}
.mob_hotel_search_details_row .mob_hotel_search_widget_btn{
    background-color: var(--primary_color);
    border: 1px solid var(--primary_color);
    border-radius: 5px;
    width: 100%;
    height: 50px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 3px;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}
.mob_hotel_search_details_row .mob_hotel_search_class{
    text-align: center;
    order: 2;
    margin: 10px 0;
    width: 100%;
    padding: 0;
}

.mob_hotel_search_accomodation .mob_hotel_cabin_class_cont{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}
.mob_hotel_search_details_container .mob_hotel_search_details_row .mob_hotel_search_column .mob_hotel_search_wid_inputfield .mob_hotel_search_wid_inputfield_main .mob_hotel_search_error_message{
    position: relative;
    top: -4px;
    float: left;
    width: 104%;
}
.mob_hotel_search_details_container .mob_hotel_search_details_row .mob_hotel_search_column .mob_hotel_search_wid_inputfield .mob_hotel_search_wid_inputfield_main .mob_hotel_search_error_message span{
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
}
.mob_hotel_search_column .mob_hotel_room_list{
    position: absolute;
    width: 100%;
    top: 60px;
    background-color: #ffffff;
    left: 0px;
    z-index: 99;
    box-sizing: border-box;
    max-height: 315px;
    box-shadow: 0px 0px 3px 0px #aaa;
    overflow: auto;
}

.mob_hotel_search_column .mob_hotel_room_list .mob_hotel_room_list_main{
    width: 360px;
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    border-top: none;
    box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.mob_hotel_search_column .mob_hotel_room_list .mob_hotel_room_list_main .mob_hotel_room_detail_ele{
    text-align: left;
    padding: 10px 10px;
    width: 100%;
}

.mob_hotel_room_detail_ele .mob_hotel_room_title{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
    text-transform: uppercase;
}
.mob_hotel_room_head{    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.mob_hotel_room_remove{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: var(--font_color);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}

.mob_hotel_accomodation_main >div{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.mob_hotel_accomodation_main >div img{
    position: relative;
    top: unset;
    left: unset;
}

.mob_hotel_accomodation_conf{
    display: flex;
    align-items: center;
}

.mob_hotel_accomodation_conf div{
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    width: 50px;
    text-align: center;
}
.mob_hotel_accomodation_main span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
}

.mob_hotel_accomodation_main p{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    margin: 0px 0px;
    display: block;
}
.mob_hotel_room_detail_ele .mob_hotel_room_title span{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--font_color);
    margin-right: 10px;
    width: 50%;
}

.mob_hotel_room_list_main .mob_hotel_room_detail_ele .mob_hotel_accomodation_main{
    display: block;
    margin-top: 0px;
    position: relative;
    width: 100%;
}

.mob_hotel_accomodation_conf .mob_hotel_accomodation_icon{
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: snow;
    border: 0;
}

.age_list_ele{
    cursor: pointer;
    width: 100%;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    padding: 0 15px;
}

.disbale_traveller{
    pointer-events: none;
    cursor: default;
}

.disbale_traveller img{
    opacity: 0.5;
}

.done_button_main{
    width: 100%;
    text-align: left;
    padding-top: 25px;
    background: #fff;
}

.done_button_main .done_button{
    background-color: var(--primary_color);
    border: none;
    color: #ffffff;
    padding: 11px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
}

.mob_hotel_search_details_row label{
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 5px;
}

.mob_hotel_search_details_row >div{
    padding-left: 50px;
    padding-top: 3px;
}

.mob_hotel_search_details_row img{
    position: absolute;
    left: 7px;
    top: 25px;
    width: 28px;
    height: auto;
}
.mob_hotel_search_details_row svg{
    position: absolute;
    left: 7px;
    top: 25px;
    width: 28px;
    height: auto;
}
.mob_hotel_search_details_row svg path{
    fill: var(--primary_color);
}
.mob_hotel_search_details_row input,.mob_hotel_cabin_class_name
{
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    border: 0 !important;
    padding: 0 10px 0 0;
    height: 30px;
    box-sizing: border-box;
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.mob_hotel_search_details_row input::placeholder{
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
}

.mob_hotel_autocomplete_value{
    position: absolute;
    width: 100%;
    max-height: 245px;
    z-index: 1;
    top: 60px;
    background-color: #fff;
    box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
    overflow: auto;
    color: var(--font_color);
    font-size: 12px;
    left: 0;
}

    .mob_hotel_autocomplete_element{
            width: 100%;
            display: flex;
            padding: 5px 17px;  
            cursor: pointer;      
            align-items: flex-start;
            min-height: 70px;
    }

    .mob_hotel_autocomplete_element:hover,
    .mob_hotel_autocomplete_element:focus{
        background: #ccc;
    }


.mob_hotel_autocomplete_name{
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

.mob_hotel_autocomplete_tag{
    width: 100%;
    margin-top: 5px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    text-align: left;
    display: block;
}


.mob_hotel_search_details_row ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .mob_hotel_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .mob_hotel_search_details_row ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }


  .mob_hotel_autocomplete_element img{
      position: relative;
      left: unset;
      top: unset;
      height: 30px;
      width: 30px;
      object-fit: scale-down;
      margin-right: 15px;
  }


.mob_hotel_add_rooms {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    display: flex;
    margin: 0px 10px;
    cursor: pointer;
    width: 45%;
}

.mob_hotel_add_rooms span{

    width: 23px;
    height: 23px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
    margin: -3px 10px 0 0;
    font-weight: normal;
}

.child_row_main label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 15px 0px 10px 0px;
    width: 100%;
    display: block;
}

.child_row_main input{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
    width: 100%;
    height: 35px;
    padding-bottom: 5px;
    border-bottom: 1px #dcd8d8 solid !important;
    /* background: url(../image/down_arrow.jpg) center right no-repeat; */
    background-position: 97% 10px;
    cursor: pointer;
}

.child_row_main input::placeholder{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
}

/********* Calendar *********/

.calendar_wid_container{
    width: 100%;
}

.calendar_wid_container_popup{
    z-index: 100;
    position: fixed;
    min-width: 333px;
    height: 100%;
    background: white;
    overflow: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.calendar_wid_container_popup > img{
    height: 20px !important;
    width: 20px !important;
    z-index: 99;
    top: 10px !important;
    position: fixed !important;
}
.calendar_wid_container_popup .calendar_head_container{
    width: 100%;
    top: 0;
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: rgb(255, 255, 255);
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main{
    width: 100%;
    display: flex;
color: var(--font_color);
    padding: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_left{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_right{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main h5 {
    border-bottom: 2px dashed #ccc;
    display: inline-block;
    font-weight: bold;
}
.calendar_head_main span{
    display: block;
}
.calendar_wid_container .calendar_main_body_popup{
    overflow: auto;
    /* height: 75vh; */
    padding:  20px 30px 70px;
    width: 100%;
    display: block;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    text-transform: uppercase;
    color: var(--font_color);
    width: 100%;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 25px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary_color);
    opacity: .5;
    color: #fff;
}
.calendar_body .calendar_day_list  .day_cell_center_highlight>span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 80%;
    transition: color 0.3s, border 0.3s;
}
.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
color: var(--font_color);
font-size: 14px;
width: 100%;
}
.calendar_body{
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px dashed #ccc;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date
/* ,.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span */
{
    background: url(/images/selected_to.svg) no-repeat;
    background: var(--secondary_color);
    height: 30px;
    width: calc(100% / 7);
    background-size: contain;
    background-position: 55%;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date span{
    font-weight: bold;
    color: #fff;
}

.calendar_footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
}

.calendar_footer button{
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: var(--primary_color);
    border-radius: 3px;
    border: none;
    font-size: 14px;
    padding: 10px 50px;
}
.err_message_text{
    font-size: 11px;
    color: #e8664e;
}

/*********** calendar tooltip ***********/
.container_tooltip{
    position: absolute;
    margin-top: -60px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    white-space: nowrap;
    font-size: 11px;
    pointer-events: none;
    z-index: 99;
}
.container_tooltip:before {
	position: absolute;
	bottom: -5px;
	left: calc(50% - 5px);
	border-top: 5px solid rgba(0, 0, 0, 0.12);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: ""
}
.container_tooltip:after {
	position: absolute;
	bottom: -4px;
	left: calc(50% - 4px);
	border-top: 4px solid #fff;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: ""
}
.calendar_modal_close{
    height: 20px;
    position: relative;
}
.calendar_modal_close svg{
    height: 25px;
    width: 25px;
    background-color: var(--primary_color);
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    z-index: 3;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    position: fixed;
    right: 0;
}


.new_cal_main{
       /* height: 100%; */
       width: 100%!important;
       display: flex;
       align-items: center;
       padding-top: 12px;
}


.new_cal_main:nth-child(1){
border-right: 2px solid #e6e6e6;
padding-right: 5%;
}

.flex_cont_cal{
display: flex;
justify-content: center;
align-items: center;
padding-left: 20px;
gap: 5px;
}

.new_cal_text p{
font-size: 13px;
line-height: 18px;
font-weight: 600;
}

.new_cal_digit_text{
font-size: 35px;
color: var(--primary_color);
font-weight: 600;
}

.flight_search_wid_inputfield_img_cal{
width: 30px;
height: 30px;
}

.calendar_wid_main{
width: 100%;
}

.new_cal_digit{
position: relative;
}
