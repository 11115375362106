.tnc_txt a{
    color: var(--primary_color) !important;
}

.all_section{
    background: #add8e6;
    padding: 35px 10px 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0px;
}
.section_div {
    width: 33%;
    /* padding: 8px; */
    border-radius: 10px;
    text-align: center;
}
.service_icon{
    padding: 15px;
    border-radius: 10px;
    /* box-shadow: 0 0 9px 1px rgba(0,0,0,.11); */
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid #fff;
    background: #ffc400;
    margin: 0 10px;
}
.section_div label{
    text-align: center;
    display: block;
    margin-top: 10px;
    font-weight: bold;
}
.primary_wgt_color{
    background: #e72643;
}
.secondary_wgt_color{
    background: #ffc400;
}
.tertiary_wgt_color{
    background: #32d093;
}

.holidaymaintabnavbarrow{
    width: 100%;
    background-color: #ffffff;
    padding: 12px 0px 0px;
    border-radius: 3px;
} 
.holidaycarouselcontainer{
    /* width: 1200px; */
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
.navbartab >ul{
    list-style: none;
    margin: 0px 0px;
    padding: 0px 0px;
    display: flex;
    justify-content: space-between;
}


.navbartab ul .activetab{
    border-bottom: 2px solid var(--primary_color);
    padding-bottom: 10px;
    padding: 0px 8px 10px;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: var(--primary_color);
}

 .navbartab ul  li {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: #565656;
    cursor: pointer;
}


.search{
    background: #e2e2e2;
    padding: 20px 32px;

}


.mob_flight_search_wid_container .mob_flight_search_wid_main{
    padding: 10px 0px;
  background: #e2e2e2;
    
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible{
    display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header{
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
    padding: 0px 15px;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image{
    width: 23px;
    height: 14px;
    margin-right: 25px;
}


.calendar_calendar_wid_container {
    width: 100%;
    margin-top: -5px;
}
.section_div:nth-child(3n+1) .service_icon {
    background-color: var(--buttons_color);
}
.section_div:nth-child(3n+2) .service_icon {
    background-color: #ffc400;
}
.section_div:nth-child(3n+3) .service_icon {
    background-color: var(--primary_color);
}
.service_icon > img {
    width: 55px;
    height: 55px;
    text-align: center;
    display: inline-block;
}