.hotel_search_wid_container {
  width: 100%;
  /* padding: 20px 25px 30px 30px; */
  padding-top: 50px;
  display: table;
  box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 8px;
}
.hotel_way_title {
  width: 100%;
  text-align: left;
}

.hotel_way_title h2 {
  font-family: var(--font_family);
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.55px;
  text-align: left;
  color: var(--primary_color);
}
.hotel_search_details_container {
  /* width: 100%; */
  margin-top: 20px;
  border: 1px solid #d4d4d4;
  padding: 12px 20px;
  border-radius: 30px;
  margin: 20px 25px 30px 30px;
}
.hotel_search_details_container
  .hotel_search_details_main
  .hotel_search_details_row {
  display: flex;
  align-items: center;
}
.hotel_search_details_container
  .hotel_search_details_main
  .hotel_search_details_row
  .hotel_search_column {
  float: left;
  margin-right: 3px;
  margin-bottom: 3px;
  position: relative;
  background: #ffff;
  border-radius: 0px;
  cursor: default;
  padding: 0px;
}
.hotel_search_wid_inputfield {
  align-items: center;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 11px;
}
.hotel_search_wid_inputfield .hotel_search_wid_inputfield_main {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_name {
  width: 30%;
  border-right: 1px solid #dedddd;
}
.hotel_search_details_main .hotel_search_details_row .hotel_search_date {
  width: 30%;
}
.hotel_search_details_main
  .hotel_search_details_row
  .hotel_search_accomodation {
  /* width: 24%;
    padding-left: 40px !important; */
  width: auto;
}
.hotel_search_widget_btn:hover,
.hotel_search_widget_btn:focus {
  /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
  transition: transform 0.3s ease;
}
.hotel_search_details_row .hotel_search_widget_btn {
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
  border-radius: 15px;
  padding: 18px 15px;
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 0;
  margin-left: 0;
  color: #fff;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.hotel_search_details_row .hotel_search_widget_btn svg {
  position: absolute;
  left: 20px;
  top: 28px;
  width: 25px;
  height: 25px;
  color: var(--primary_color);
  cursor: pointer;
}
.hotel_search_details_row .hotel_search_class {
  margin-left: auto;
  margin-left: 5px;
  order: 2;
  margin-right: 3px;
  padding-left: 0;
  width: 22%;
}
.hotel_search_btn_new {
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin-left: auto;
  width: 15%;
}
.hotel_search_btn_new ::before {
  content: url(/images/location-01.svg) no-repeat center/contain;
  background-image: url(/images/location-01.svg) no-repeat center/contain;
}
.flight_search_class_new {
  padding-left: 0px;
  padding-top: 0px;
  width: 10%;
}
/* .hotel_search_details_row .hotel_search_class_btn{
    margin-left: auto;
    order: 2;
    margin-right: 3px;
    padding-left: 0;
    width: 22%;
} */
.hotel_search_accomodation .hotel_cabin_class_cont {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.hotel_search_column .hotel_room_list {
  position: absolute;
  min-width: 268px;
  border-radius: 5px;
  width: 100%;
  top: 0px;
  background-color: #ffffff;
  left: 0px;
  z-index: 9;
  box-sizing: border-box;
  max-height: 400px;
  overflow: auto;
  box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #d4d4d4;
}

.hotel_search_column .hotel_room_list .hotel_room_list_main {
  width: 360px;
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  border-top: none;
  box-shadow: 0 0 18px 2px rgb(0 0 0 / 7%);
}

.hotel_search_column
  .hotel_room_list
  .hotel_room_list_main
  .hotel_room_detail_ele {
  text-align: left;
  padding: 0px 0px;
  width: 100%;
}

.hotel_adult_accomodation {
  border-bottom: 1px solid #dedddd;
  padding: 12px 18px;
  display: flex;
  justify-content: space-between;
}
.hotel_children_accomodation {
  border-bottom: 1px solid #dedddd;
  padding: 12px 18px;
  display: flex;
  justify-content: space-between;
}
.hotel_room_detail_ele .hotel_room_title {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--secondary_color);
  margin-right: 10px;
  /* width: 50%; */
  text-transform: capitalize;
}
.hotel_room_head {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 15px 18px;
  padding-bottom: 0;
}

.hotel_room_remove {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: var(--primary_color);
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}
.hotel_search_details_container
  .hotel_search_details_row
  .hotel_search_column
  .hotel_search_wid_inputfield
  .hotel_search_wid_inputfield_main
  .hotel_search_error_message {
  /* position: relative;
    top: -10px;
    float: left; */
  width: 100%;
  position: absolute;
  bottom: -5px;
}
.hotel_search_details_container
  .hotel_search_details_row
  .hotel_search_column
  .hotel_search_wid_inputfield
  .hotel_search_wid_inputfield_main
  .hotel_search_error_message
  span {
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
}

.hotel_accomodation_main > div img {
  position: relative;
  top: unset;
  left: unset;
}

.hotel_accomodation_conf {
  display: flex;
  align-items: center;
}

.hotel_accomodation_conf div {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--font_color);
  width: 50px;
  text-align: center;
}
.hotel_accomodation_main span {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}

.hotel_accomodation_main p {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  margin: 0px 0px;
  display: block;
  margin-bottom: 5px;
}
.hotel_room_detail_ele .hotel_room_title span {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--font_color);
  margin-right: 10px;
  width: 50%;
}

.hotel_room_list_main .hotel_room_detail_ele .hotel_accomodation_main {
  display: block;
  margin-top: 0px;
  position: relative;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.hotel_accomodation_conf .hotel_accomodation_icon svg {
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 0;
  position: relative;
  top: unset;
  left: unset;
}

.age_list_ele {
  cursor: pointer;
  width: 100%;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  /* margin: 0px 0px 10px 0px; */
  padding: 5px 15px;
}
.age_list_ele:hover {
  background-color: var(--primary_color);
  color: #fff;
}
.disbale_traveller {
  pointer-events: none !important;
  opacity: 0.5 !important;
  background: #3c3c3c1f !important;
  border-radius: 50px;
}

.disbale_traveller img {
  opacity: 0.5;
}

.done_button_main {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  background: #fff;
}

.done_button_main .done_button {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;

  width: 132px;
  height: 38px;
  padding: 12px 12px 12px;
  border-radius: 10px;
  background-color: var(--buttons_color);
  color: #fff !important;
  margin-bottom: 20px;
}

.hotel_search_details_row label,
.hotel_guestlabel {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}

.hotel_search_details_row > div {
  padding-left: 15px;
  /* padding-top: 10px; */
  /* margin-left: auto; */
}

.hotel_search_details_row svg {
  position: absolute;
  left: 13px;
  top: 8px;
  width: 18px;
  height: 18px;
  color: var(--primary_color);
  cursor: pointer;
}
.hotel_search_wid_inputfield_main svg {
  top: 14px;
}
.hotel_search_details_row input,
.hotel_cabin_class_name {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  border: 0 !important;
  padding: 0 0px 0 0px;
  /* height: 30px; */
  box-sizing: border-box;
  width: 100%;
  box-shadow: none !important;
  outline: none !important;
  /* margin-top: 6px; */
  cursor: pointer;
  /* caret-color: transparent; */
}
.hotel_cabin_class_name span {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.hotel_search_wid_inputfield_main input {
  font-size: 17px;
  font-weight: 600;
}
.hotel_search_details_row input {
  color: var(--secondary_color) !important;
}

.hotel_search_details_row input::placeholder {
  font-weight: bold;
  font-family: var(--font_family);
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.hotel_search_details_row .input_search_destination input {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: #979797;
}
.hotel_search_details_row .input_search_destination input::placeholder {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: #979797;
}

.hotel_autocomplete_value {
  width: 100%;
  max-height: 245px;
  top: 60px;
  background-color: #fff;
  box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
  overflow: auto;
  color: var(--font_color);
  font-size: 12px;
  left: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid transparent;
  border-radius: 15px;
}

.hotel_autocomplete_element {
  width: 100%;
  display: flex;
  padding: 5px 8px;
  cursor: pointer;
  align-items: flex-start;
  min-height: 50px;
  margin-bottom: 10px;
}

.hotel_autocomplete_element:hover,
.hotel_autocomplete_element:focus {
  background: #ccc;
}

.hotel_autocomplete_name {
  width: 100%;
  text-align: left;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
}

.hotel_autocomplete_tag {
  width: 100%;
  margin-top: 5px;
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
  text-align: left;
  display: block;
}

.hotel_search_details_row ::-webkit-scrollbar {
  width: 6px;
}

.arrow_rotate {
  top: 3px !important;
  transform: rotate(180deg);
  transition: all 0.2s linear;
}

/* Track */
.hotel_search_details_row ::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px transparent; */
  /* border-radius: 10px; */
}

/* Handle */
.hotel_search_details_row ::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 10px;
}

.hotel_autocomplete_element img {
  position: relative;
  left: unset;
  top: unset;
  height: 23px;
  width: 23px;
  object-fit: scale-down;
  margin-right: 15px;
}

.hotel_add_rooms {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary_color);
  display: flex;
  align-items: center;
  padding: 20px 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #dedddd;
  text-transform: capitalize;
}

.hotel_add_rooms span {
  width: 26px;
  height: 26px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 26px;
  margin: 0px 4px 0 0;
  font-weight: normal;
  padding-left: 0px;
  padding-top: 2px;
}

.child_row_main label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  margin: 15px 0px 5px 0px;
  width: 100%;
  display: block;
}

.child_row_main input {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 35px;
  padding-bottom: 5px;
  border-bottom: 1px #dcd8d8 solid !important;
  /* background: url(../image/down_arrow.jpg) center right no-repeat; */
  background-position: 97% 10px;
  cursor: pointer;
}

.child_row_main input::placeholder {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
}

/* ==================== Calendar ==================== */

.calendar_wid_container {
  width: 100%;
  display: flex;
}
.calendar_wid_container .calendar_wid_main {
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
}
.calendar_wid_container .calendar_container {
  position: absolute;
  width: 625px;
  margin-top: 0px;
  top: 50px;
  left: 74%;
  transform: translate(-50%, 0);
  z-index: 9;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--font_color);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  /* padding: 10px 8px; */
  padding: 0;
}

.calendar_wid_container .calendar_container.left {
  left: 0 !important;
}

.calendar_container .calendar_main {
  padding: 10px 15px;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 15px;
}

.calendar_container .calendar_main .calendar_head_left_side {
  position: absolute;
  cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side {
  position: absolute;
  cursor: pointer;
  right: 25px;
}

.calendar_main .calendar_whole_body {
  width: 100%;
  box-sizing: content-box;
}
.calendar_main .calendar_head {
  display: flex;
  width: 100%;
}
.calendar_head .calendar_head_left_side {
  text-align: left;
  width: 10%;
}
.calendar_head .calendar_head_center_side {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
  text-transform: capitalize;
  color: var(--font_color);
  padding-bottom: 15px;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
}

.calendar_head .calendar_head_right_side {
  text-align: right;
  width: 10%;
}

.calendar_head .calendar_head_icon {
  position: relative;
  left: 0;
  top: 0;
}

.calendar_body .calendar_week_heads {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendar_week_heads .coln_center_week {
  padding: 5px 0;
  font-size: 12px;
  font-weight: 400;
  width: calc(100% / 7);
  text-align: center;
  font-family: var(--font_family);
  text-transform: capitalize;
  color: var(--font_color);
}
.calendar_body .calendar_day_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.calendar_body .calendar_day_list .day_cell_center {
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  transition: color 0.3s, border 0.3s;
  cursor: pointer;
  height: 44px;
  font-family: var(--font_family);
  /* margin:  3px 0; */
  font-size: 14px;
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_body .calendar_day_list .day_cell_center_highlight {
  text-align: center;
  text-decoration: none;
  transition: color 0.3s, border 0.3s;
  cursor: pointer;
  height: 44px;
  font-family: var(--font_family);
  /* margin:  3px 0; */
  font-size: 14px;
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #cbe3f5; */
  background-color: #d6e4f4;
  /* opacity: 0.5; */
  color: #fff;
}

.calendar_body .calendar_day_list .day_cell_center > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.calendar_body {
  padding: 0 10px;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body
  .calendar_day_list
  .day_cell_center:hover
  > span.calendar_day_disable
  .calendar_body
  .calendar_day_list
  .day_cell_center:focus
  > span.calendar_day_disable {
  cursor: not-allowed;
  color: #999 !important;
  background-color: transparent !important;
  opacity: 0.5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
.calendar_body .calendar_day_list .day_cell_center:hover > span,
.calendar_body .calendar_day_list .day_cell_center:focus > span {
  border-radius: 5px;
  color: #fff;
  background-color: var(--primary_color);
}
.calendar_body
  .calendar_day_list
  .day_cell_center.selected_calendar_date
  > span {
  color: #ffffff;
  font-family: var(--font_family);
}
.calendar_head_icon svg {
  position: relative !important;
  top: unset !important;
  right: unset !important;
  color: var(--secondary_color) !important;
}
.child_row_main {
  position: relative;
  padding: 0px 18px;
}
.child_age_select_arrow svg {
  height: 15px;
  width: 15px;
  position: relative;
  left: unset;
  top: unset;
  transform: rotate(180deg);
}

.child_age_select_arrow_rot svg {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 25px;
  top: 32px !important;
  left: inherit !important;
  top: unset;
}

.child_row_main .child_age_select_arrow {
  position: absolute;
  right: 25px;
  top: 35px;
}
.container_tooltip {
  position: absolute;
  margin-top: -60px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  white-space: nowrap;
  font-size: 11px;
  pointer-events: none;
  z-index: 99;
}
.container_tooltip:before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}
.container_tooltip:after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

.child_row_container .child_row_main .err_message_text {
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
}
.hotel_search_left_icon {
  cursor: pointer;
}
.flight_search_wid_inputfield_img {
  cursor: pointer;
}

.hotel_cabin_class_name input {
  background-color: #ffffff;
}

.adv_search_wrap {
  text-align: end;
  display: flex;
  margin-left: auto;
  width: 50%;
}
.collapsible_title {
  width: 50%;
}
.advmodal_dropcontent {
  position: absolute;
  right: 0;
  background: #fff;
  z-index: 1;
  border-radius: 5px;
  padding: 15px 35px;
  margin-top: 10px;
  box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
  padding-bottom: 25px;
}
.filter_container {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.adv_text {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.check_advance_labels {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

.nationality_wrap {
  position: relative;
  display: inline;
}
.hotel_search_details_row .input_nationality_wd {
  width: 250px !important;
  height: 40px !important;
  padding: 12px 20px 12px 12px !important;
  border-radius: 3px;
  border: solid 1px var(--primary_color) !important;
  background-color: #e2e0e0;
  position: relative;
  margin-top: 3px;
}
.pg_down_arrow i {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.hotel_search_details_row .inputsearch_result {
  width: 250px;
  height: 383px;
  padding: 16px 22px 20px 20px;
  margin-top: 0px;
  border: solid 1px #ccc !important;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.hotel_search_details_row .inputsearch_result input {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #ccc !important;
  background-color: #fff;
  cursor: auto;
  font-size: 14px;
  font-weight: 500;
  caret-color: auto;
}

.hotel_search_details_row_mg {
  /* margin-left: auto;
    padding-left: 0; */
  width: 22%;
}
.hotel_search_details_row_mg .hotel_search_widget_btn {
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
  border-radius: 15px;
  width: 100%;
  height: 40px;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 3px;
  margin-left: 0px;
  color: #ffffff;
  cursor: pointer;
  outline: 0;
}
.hotel_search_widget_btn svg {
  position: absolute;
  left: 37px;
  top: 20px;
  width: 18px;
  height: 18px;
  color: var(--primary_color);
  cursor: pointer;
}
.hotel_search_btnClass {
  /* margin-left: 5px !important; */
  margin-left: auto;
  order: 2;
  margin-right: 3px;
  padding-left: 0 !important;
  width: 22%;
}
.nationality_cnt_wd {
  margin-left: 44.5% !important;
}
.hotel_search_left_icon span img {
  position: absolute;
  top: 15px;
  left: 7px;
  width: 30px;
  height: 30px;
}
.hotel_search_details_main {
  /* display: flex; */
}
.newhoteldate {
  width: 40%;
  background: #ffffff;
  margin-right: 3px;
  border-radius: 15px;
  /* padding: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0 !important;
}
.newlefthoteldate {
  display: flex;
  width: 100%;
  position: relative;
  /* border-right: 1px solid #707070; */
}
.newspanhoteldate {
  width: 40%;
  display: grid;
  height: fit-content;
}
.newrighthoteldate {
  display: flex;
  width: 50%;
}
.newlefthoteldate .calendar_wid_container label {
  padding-left: 0px;
}
.newrighthoteldate input {
  width: 40%;
  padding-left: 10px;
  font-size: 24px;
}
.newrighthoteldate input::placeholder {
  font-family: var(--font_family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  margin-right: 5px;
  color: #3479c5;
}
.newlefthoteldate input {
  font-size: 16px;
  width: 30%;
  margin-left: 17px;
  /* padding-top: 15px; */
}
.newlefthoteldate input::placeholder {
  font-family: var(--font_family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  margin-right: 5px;
  color: var(--primary_color);
}
.newlefthoteldate span {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.newspanhoteldate span {
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}
.hotel_adult_accomodation_tag {
  display: flex;
}
.hotel_adult_accomodation_tag span {
  padding-left: 10px;
}
.hotel_class_arrow {
  position: relative;
}
.hotel_class_arrow span {
  position: absolute;
  left: 14px;
  top: 11px;
  width: 20px;
  height: 20px;
  object-fit: scale-down;
  color: var(--primary_color);
}

.new_cal_main {
  display: flex;
  width: 100%;
  margin: auto;
  cursor: pointer;
}

.new_cal_main:nth-child(1) {
  /* border-right: 2px solid #dedede; */
}
/* .flex_cont_cal:first-child {
    padding-right: 20px;
    border-right: 2px solid #dedede;
} */
.flex_cont_cal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  padding-top: 20px;
  padding-bottom: 11px;
  border-right: 1px solid #dedede;
  padding-left: 40px;
  padding-right: 20px;
}

.new_cal_digit_text,
.new_cal_digit_text span {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}

.flight_search_wid_inputfield_img svg {
  width: 30px;
  height: 30px;
}

.vr_divider {
  width: 3px;
  height: 12px;
  background: #5d5b62;
}

.adult_room_flex {
  display: grid;
  padding-top: 0px;
  position: relative;
}
.hotel_search_details_row .adult_room_flex svg {
  position: absolute;
  right: 31px;
  top: 20px;
  width: 24px;
  height: 24px;
  color: var(--primary_color);
  cursor: pointer;
  left: unset;
}

.input_search_destination input {
  padding: 10px 25px;
  border: 1px solid #d4d4d4 !important;
  border-radius: 50px;
  width: 100%;
  display: block;
  border-radius: 0;
  border-radius: 5px !important;
  box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15) !important;
  box-sizing: border-box;
  height: 41px;
}
.hotel_autocomplete_value .input_search_destination svg {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 20px;
  height: 19px;
  color: var(--button_color);
}
.hotel_guestCont {
  width: 24%;
  padding-left: 30px !important;
  padding-top: 20px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.drop_wrap {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.hotel_dropdown {
  position: relative;
  top: -5px;
}
.hotel_dropdown svg {
  /* position: absolute;
    left: 13px;
    top: 16px; */
  position: relative;
  left: 0;
  right: 0;
  width: 20px;
  height: 22px;
  fill: var(--primary_color);
}
.autocomp_container {
  position: absolute;
  top: 50px;
  z-index: 9;
  width: 330px;
}
.autocomp_countryList {
  padding: 0;
  max-height: 100%;
  overflow: inherit;
  border-radius: 0;
  /* background: transparent !important; */
  background: #fff;
  box-shadow: none;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #d4d4d4;
  border-top: none;
}
.hotel_autocompleteMain {
  /* border-radius: 5px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d4d4d4; */
  border-radius: 0;
  border: none;
  box-shadow: none;
  width: 98%;
}
.hotel_autocomplete_element svg {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 25px;
  height: 30px;
  margin-right: 5px;
}
.hotel_prop_city {
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-align: left;
  color: #979797;
  margin-top: 5px;
}
.hotelnw_city {
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--secondary_color);
  flex: 1;
  text-transform: capitalize;
}

.calCheckin_cont {
  display: flex;
  gap: 63px;
  box-shadow: 0 2px 3px 0 #0000001a;
  padding: 14px 9px;
  padding-bottom: 0;
}

.calCheckin_box {
  display: flex;
  gap: 8px;
}

.calChec_icon {
  color: #474747;
}
.calChec_icon svg {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.calCheck_date {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-bottom: 6px;
  border-bottom: 3px solid var(--primary_color);
}

.calendar_head_icon svg {
  width: 25px;
}
.calendar_head_center_side h3 {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--secondary_color);
}

.calendar_head_center_side h3 span {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}

.populardest_title {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  margin-right: 20px;
}
.popular_destination {
  background-color: #ececec;
  padding: 9px;
  border-radius: 0 0 8px 8px;
  border-top: none;
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.destination_container {
  display: flex;
  gap: 20px;
}

.destination_active {
  background-color: var(--primary_color);
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.destination_inactive {
  background-color: #fff;
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.destination_item .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--font_color);
  margin-bottom: 1px;
}
.destination_active .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 3px;
}

.destination_inactive .destination_name {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--secondary_color);
  margin-bottom: 3px;
}

.destination_item .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--font_color);
}
.destination_active .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fff;
}
.destination_inactive .property_count {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--secondary_color);
}

.destination_item {
  cursor: pointer;
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 10px;
  background: white;
}

.hotel_list_flex {
  display: flex;
  gap: 5px;
}

.no_res {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 10px 15px;
}

.no_res svg {
  width: 23px;
  /* height: 25px; */
  position: inherit !important;
}

.no_res p {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.recent_container {
}
.recent_label {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--secondary_color);
  padding-left: 35px;
  padding-bottom: 14px;
}
.paxPlus_icon {
  margin-right: 8px;
}
.paxPlus_icon svg {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .search_container {
    height: 69px !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .done_button_main {
    padding-top: 18px;
    position: fixed;
    width: 90%;
    z-index: 99;
    bottom: 0px;
    border: none;
    background: unset;
  }
  .paxPlus_icon {
    margin-right: 8px;
  }
  .calendar_wid_main input {
    height: 18px !important;
  }
  .hotel_room_list_main {
    padding-bottom: 90px;
  }

  .hotel_search_wid_container {
    background: var(--primary_color);
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 10px;
  }
  .hotel_search_details_container
    .hotel_search_details_main
    .hotel_search_details_row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 39px; */
  }
  .hotel_search_details_row .hotel_search_btn_new {
    width: 100%;
  }
  .hotel_search_details_row .hotel_search_widget_btn {
    width: 100%;
    padding: 22px 27px;
    border-radius: 10px;
    font-size: 18px;
  }
  .hotel_search_details_row .widget_label {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color);
    display: block;
    margin-bottom: 5px;
  }
  .widgMob_inp {
    line-height: normal;
  }
  .widgMob_inp input {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
  }
  .widgMob_inp input::placeholder {
    font-size: 15px;
    font-weight: bold;
  }
  .hotel_search_details_container
    .hotel_search_details_main
    .hotel_search_details_row
    .hotel_search_column {
    background: transparent;
    border-right: 0;
    width: 100%;
    margin-bottom: 0;
  }
  .widget_mainBox {
    margin-bottom: 39px;
  }
  .hotel_search_details_container {
    margin: 0;
    padding: 0;
    border: none;
  }
  .widget_mainBox {
    background-color: #fff;
    padding: 15px 10px;
    border-radius: 10px;
    width: 100%;
    height: 67px;
  }
  .widgMob_dateInp {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
  }
  .widgMob_Wrapflx {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .widgMob_sec {
    padding: 0;
  }
  .widgMob_arrow svg {
    position: static;
  }
  .autocomp_container {
    position: static;
    width: 100%;
  }
  .hiddenOnMobile {
    display: none;
  }
  .hotel_search_wid_inputfield {
    padding: 0;
  }
  .input_search_destination {
    position: relative;
    padding: 12px 20px;
    background-color: #1b5d9d;
  }
  .hotel_search_wid_inputfield .hotel_search_wid_inputfield_main {
    width: 100%;
  }
  .hotel_autocomplete_value .input_search_destination svg {
    top: 25px;
    left: 27px;
  }
  .hotel_autocomplete_value .input_search_destination svg path {
    fill: #979797;
  }
  .input_search_destination input {
    background-color: #ebebeb;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: #979797;
    caret-color: transparent;
  }
  .input_search_destination input::placeholder {
    font-size: 15px;
    font-weight: normal;
  }
  .autocomp_countryList {
    box-shadow: none;
    border: none;
  }
  .hotel_autocompleteMain {
    max-height: 100%;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
  }
  .hotel_autocomplete_element {
    flex-direction: row-reverse;
    padding: 10px 20px;
    margin-bottom: 0;
  }
  .hotel_autocomplete_element svg {
    width: auto;
    margin-right: 0;
  }
  .hotel_autocomplete_name {
    display: grid;
    align-items: center;
  }
  .suggestion_label {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: var(--secondary_color);
    margin-top: 18px;
    margin-left: 20px !important;
    margin-bottom: 4px;
  }
  .calendar_wid_container .calendar_wid_main {
    /* padding: 12px 20px;
      background-color: #1b5d9d; */
    padding: 0;
  }
  /* .calendar_wid_container .calendar_wid_main input{
      height: 41px;
      padding: 8px 7px;
      border-radius: 5px;
      background-color: #ebebeb;
      width: 100%;
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: left;
      color: #979797;
  } */
  .calendar_wid_container {
    display: block;
  }
  .calendar_wid_container_popup {
    z-index: 100;
    position: fixed;
    min-width: 333px;
    height: 100%;
    background: white;
    overflow: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_modal_close {
    height: 20px;
    position: relative;
  }
  .calendar_modal_close svg {
    height: 25px;
    width: 25px;
    background-color: var(--primary_color);
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    z-index: 3;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    position: fixed;
    right: 0;
  }
  .calendar_modal_close svg {
    height: 25px;
    width: 25px;
    background-color: var(--primary_color);
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    z-index: 3;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    position: fixed;
    right: 0;
  }
  .calendar_wid_container_popup .calendar_head_container {
    width: 100%;
    top: 0;
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: rgb(255, 255, 255);
  }
  .calendar_wid_container_popup .calendar_head_container .calendar_head_main {
    width: 100%;
    display: flex;
    color: var(--font_color);
    padding: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  .calendar_wid_container_popup
    .calendar_head_container
    .calendar_head_main
    .calendar_head_left {
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
  }
  .calendar_head_main span {
    display: block;
  }
  .calendar_wid_container_popup
    .calendar_head_container
    .calendar_head_main
    h5 {
    border-bottom: 2px dashed #ccc;
    display: inline-block;
    font-weight: bold;
  }
  .calendar_wid_container_popup
    .calendar_head_container
    .calendar_head_main
    .calendar_head_right {
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
  }
  .calendar_wid_container .calendar_main_body_popup {
    overflow: auto;
    /* height: 75vh; */
    padding: 20px 15px 70px;
    width: 100%;
    display: block;
  }
  .calendar_week_heads .coln_center_week {
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: #8a8a8a;
  }
  .calendar_week_heads {
    display: flex;
    padding: 0 15px;
    padding-top: 10px;
    padding-bottom: 6px;
    border-bottom: 1px solid #efeff1;
    background-color: #fff;
  }
  .calendar_body {
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 25px;
    /* border-bottom: 2px dashed #ccc; */
  }
  .calendar_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 30px 10px;
    text-align: center;
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
  }

  .calendar_footer button {
    width: 100%;
    height: 57px;
    padding: 21px 20px;
    border-radius: 10px;
    background-color: var(--buttons_color);
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .calendar_footer_disable {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 30px 10px;
    text-align: center;
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
  }
  .calendar_footer_disable button {
    width: 100%;
    height: 57px;
    padding: 21px 20px;
    border-radius: 10px;
    background-color: #f0f0f0;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #727272;
  }
  .hotel_room_detail_ele .hotel_room_title {
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
  }
  .hotel_room_list {
    padding: 0 20px;
    max-height: 90vh;
    overflow: scroll;
  }
  .hotel_room_head {
    padding: 30px 0px;
    border-bottom: 1px solid #dedddd;
  }
  .hotel_adult_accomodation,
  .hotel_children_accomodation {
    padding: 28px 0;
    border-bottom: 1px solid #dedddd;
  }
  .hotel_add_rooms {
    border-bottom: 0;
    padding-top: 30px;
    padding-left: 0px;
  }
  /* .done_button_main {
    margin-top: 66px;
  } */
  .hotel_accomodation_main p {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.38px;
    color: var(--secondary_color);
    margin-bottom: 7px;
  }
  .done_button_main .done_button {
    height: 57px;
    width: 100%;
  }
  .hotel_accomodation_conf .hotel_accomodation_icon svg {
    width: 31px;
    height: 30px;
  }
  .child_row_main input {
    border: 0;
    margin: 0;
    margin-bottom: 5px;
    padding-bottom: 10px;
    padding-left: 0;
  }

  .child_row_main input:focus {
    box-shadow: none;
    outline: none;
  }
  .calendar_wid_main input {
    height: 13px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
    background: transparent;
    padding: 0;
  }
  .modal_header {
    padding: 0 15px;
    background: var(--primary_color);
    height: 65px;
    width: 100%;
    z-index: 9999;
    display: flex;
    /* gap: 55px; */
    align-items: center;
    justify-content: center;
    /* padding-left: 60px; */
  }

  .modal_header svg {
    width: 20px;
    height: max-content;
    position: absolute;
    top: 20px !important;
    object-fit: contain;
    /* margin-top: 10px; */
    left: 15px;
  }

  .header_text {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #fff;
  }

  /* Style for the container holding the input field and icon */
  .search_container {
    position: relative;
    padding: 12px 20px;
    background-color: #1b5d9d;
    height: 65px;
  }

  .search_icon svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 60%;
    left: 27px;
    transform: translateY(-60%);
  }

  .hotel_search_details_row .search_input {
    background: #ebebeb;
    border-radius: 5px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: #979797 !important;
    padding: 14px 14px 14px 25px;
  }

  .hotel_search_details_row .search_input::placeholder {
    color: #979797;
    font-size: 15px;
  }
  .date_header_container {
    position: sticky;
    top: 0;
    z-index: 99;
    background: #fff;
  }
  .calendar_body .calendar_day_list .day_cell_center {
    font-size: 16px;
    color: var(--secondary_color);
    height: 50px;
  }
  .calendar_week_heads .coln_center_week:nth-last-child(-n + 2) {
    color: var(--primary_color);
  }
  .calendar_body .calendar_day_list .day_cell_center_highlight {
    color: #000;
    font-weight: bold;
    background-color: transparent;
  }
  .calendar_body
    .calendar_day_list
    .day_cell_center.selected_calendar_date
    > span {
    font-weight: bold;
    background: var(--primary_color);
    width: 40px;
    height: 44px;
    border-radius: 5px;
  }
  .day_cell_center_highlight span {
    background: #b9d1e9;
    width: 40px;
    height: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container_tooltip {
    color: #000;
    z-index: 9;
  }
  .error_message {
    font-size: 10px;
    color: #e8664e;
    margin-top: -3px;
  }
  .child_row_main {
    padding: 0;
  }
  .child_age_select_arrow_rot svg {
    right: 2px;
    top: 30px !important;
  }
  .child_row_main .child_age_select_arrow {
    right: 2px;
    top: 30px;
  }
  .age_list_ele {
    padding: 10px 2px;
    border-bottom: 1px solid #dedddd;
  }
  .age_list_ele:last-child {
    border-bottom: 0;
  }
  .age_list_main {
    overflow: scroll;
    height: 230px;
  }

  .age_list_main::-webkit-scrollbar {
    width: 7px;
  }

  .age_list_main::-webkit-scrollbar-thumb {
    background: #d0e8ff;
    border-radius: 5px;
  }
  .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date {
    background-color: transparent;
  }
  .hotel_searchlabel {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 230px; */
    width: 90%;
  }
  .hotel_mobileContainer {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    padding-bottom: 0;
  }
  .popular_destination {
    display: block;
    background-color: transparent;
    padding: 9px;
    border-top: 1px solid #c1c1c1;
    padding-left: 20px;
    padding-top: 29px;
    margin-top: 10px;
  }
  .populardest_title {
    margin-bottom: 20px;
    font-size: 15px;
  }
  .destination_active {
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    border: 1px solid var(--primary_color);
    background: transparent;
  }
  .destination_active .destination_name {
    color: var(--primary_color);
  }
  .recent_label {
    border-top: 1px solid #c1c1c1;
    padding-top: 19px;
    padding-left: 18px;
  }

  .hotelnw_city {
    display: none;
  }
  .hotel_autocomplete_city {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--secondary_color);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 230px;
  }
  .hotel_autocomplete_tag {
    width: 40%;
    display: block;
  }
  .hotel_add_rooms span {
    padding-top: 1px;
  }
  .no_res {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 10px;
    padding: 10px 15px;
  }
}

.suggestion_label {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-align: left;
  color: var(--secondary_color);
  margin-top: 7px;
  margin-left: 9px;
  margin-bottom: 10px;
}
