.modal_container {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  background: white;
  /* border: 1px solid #ddd; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.modal_background {
  position: fixed;
  background: rgb(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal_overflow {
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 999;
  width: 100%;
  padding: 40px 0;
}

.modal_content {
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: fit-content;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 0;
  height: max-content;
  border-radius: 5px;
}
.modal_content.mobile_modal {
  padding: 20px 15px;
  border-radius: 10px;
  /* position: relative; */
}
.modal_close svg {
  height: 25px;
  color: #919191;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 15px !important;
  right: 15px;
  width: 25px;
  border: 1px solid #919191;
  padding: 3px;
}

.modal_close {
  float: right;
  cursor: pointer;
  /* position: relative;
    top: -50px;
    right: -15px; */
}

.green_popup .modal_content.mobile_modal {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_container .modal_content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.green_popup .modal_content.mobile_modal {
  background-color: #1a873c;
}

.green_popup .modal_content {
  background-color: #fff;
  position: relative;
  width: 84%;
  box-sizing: border-box;
  margin: auto;
  z-index: 2;
  max-width: 515px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 60px 70px;
  height: -moz-max-content;
  height: max-content;
  border-radius: 30px;
  display: block;
  min-height: auto;
}

.modal_header {
  padding: 0 15px;
  background: var(--primary_color);
  height: 63px;
  width: 100%;
  z-index: 9999;
  display: flex;
  /* gap: 55px; */
  align-items: center;
  justify-content: center;
  /* padding-left: 60px; */
}

.modal_header svg {
  width: 20px;
  height: max-content;
  position: absolute;
  top: 20px !important;
  object-fit: contain;
  /* margin-top: 10px; */
  left: 15px;
}

.modal_header svg {
  position: static;
  width: auto;
  height: auto;
}

.header_text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .green_popup .modal_content {
    background-color: green;
    position: relative;
    width: 91%;
    box-sizing: border-box;
    margin: auto;
    z-index: 2;
    max-width: 515px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 15px;
    height: max-content;
    border-radius: 10px;
    display: block;
  }
  .header_text {
    padding-left: 10px;
    justify-content: flex-start;
    color: var(--secondary_color);
    font-family: var(--font_family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .success_email {
    padding: 20px 15px !important;
    border-radius: 10px;
  }
  .success_email .modal_close svg {
    top: 16px !important;
  }
  .modal_content {
    padding: 20px;
    /* padding-top: 45px; */
  }
  .modal_close svg {
    top: 20px;
  }
  .success_email > div {
    text-align: center;
    margin-right: 20px;
  }
}

.success_email {
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: 400px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 15px;
  padding: 40px 100px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 10px;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .modal_content {
    position: relative;
    width: 95%;
    box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 550px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 70px;
    padding: 10px !important;
    border-radius: 10px;
  }
  .modal_content.mobile_modal {
    padding: 0px 0px;
    border-radius: 0px;
  }
  .modal_overflow {
    padding: 0px 0;
  }
  .modal_header {
    background-color: #fff;
}
}

.pop_title {
  font-size: 18px;
  color: var(--primary_color);
}
@media screen and (max-width: 1440px) and (min-width: 1279px) {
  .modal_overflow {
    overflow: hidden;
    height: 111vh;
  }
  .modal_content {
    min-height: 600px;
    height: 600px;
    overflow: auto;
  }
}

.applyNow {
  margin: 30px auto;
  display: block;
  width: 90%;
  background: #f58648;
  color: #fff;
  font-weight: 700;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
  left: 5%;
  z-index: 9999999999;
  border-radius: 7px;
}

.applyNow.disbale {
  margin: 30px auto;
  display: block;
  width: 90%;
  background: #666666;
  color: var(--font_color);
  font-weight: 700;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
  left: 5%;
  z-index: 9999999999;
  border-radius: 7px;
}
