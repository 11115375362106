.mob_flight_search_wid_container {
  width: 100%;
  /* min-height: 100vh; */
  /* background: #e2e2e2; */
  padding-bottom: 10px;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main {
  padding: 0;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible {
  display: block;
  border-bottom: 1px solid #00000059;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.mob_flight_search_wid_container
  .mob_flight_search_wid_main
  .mobile_visible
  .mobo_tab_header {
  display: flex;
  height: 60px;
  background: #fff;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid grey;
  /* box-shadow: 1px 1px 13px #bcbcbc;
  margin-top: 25px; */
}
.mob_flight_search_wid_container
  .mob_flight_search_wid_main
  .mobile_visible
  .arrow_image
  img {
    width: 29px;
    cursor: pointer;
    height: 29px;
    margin-right: 15px;
    color: var(--font_color);
}
.mob_flight_search_wid_container
  .mob_flight_search_wid_main
  .mobile_visible
  .arrow_image {
  display: inline-block;
}
.mob_flight_search_wid_container
  .mob_flight_search_wid_main
  .mobile_visible
  .search_filter_header {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: left;
  color: var(--font_color) !important;
}

.mob_flight_way_tab {
  width: -webkit-fill-available;
  text-align: center;
  background: #fff;
  margin: 15px 10px;
  display: flex;
  height: 40px;
}

.mob_flight_way_tab .mob_flight_way_btngrp {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  width: 90%;
  gap: 20px;
  margin: auto;
}
.main_flight{
  padding: 0px 10px 20px;
}

.mob_flight_way_btngrp .active_way {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: 0.4px;
  text-align: center;
  padding: 9px;
  color: #fff;
  background: var(--primary_color);
  border-radius: 50px;
  height: 40px;
  transition: 0.3s;
  width: 50%;
}
.close_icon{
  width: 20px;
  background: red;
  padding: 3px;
  border-radius: 20px;
  margin-top: -20px;
}
.mob_flight_way_btngrp .button_style {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
  width: 50%;
  border-radius: 50px;
}

.mob_flight_way_btngrp .button_style:focus {
  color: var(--primary_color);
}
.mob_flight_way_btngrp .button_style:hover {
  color: var(--primary_color);
}

.mob_flight_search_details_container {
  width: 100%;
  margin-top: 20px;
  max-height: 68vh;
  overflow-y: auto;
}

.mob_flight_search_details_container
  .mob_flight_search_details_main
  .mob_flight_search_details_row {
  display: block;
  /* padding: 0 0px; */
}
.mob_flight_search_details_container
  .mob_flight_search_details_main
  .mob_flight_search_details_row
  .mob_flight_search_column {
  margin-bottom: 0px;
  position: relative;
  background: #ffff;
  border-radius: 3px;
  /* height: 105px; */
  height: auto;
  border: 1px solid #bbb;
  border-radius: 10px;
}

.mob_flight_search_details_container
  .mob_flight_search_details_main
  .mob_flight_search_details_row 
  .flgt_cont_flx:first-child
  .mob_flight_search_column {
    min-height: 105px;
    height: auto;
  }
.mob_flight_search_depart:first-child,
.mob_flight_search_depart_going_date:first-child {
  border-radius: 10px 0px 0px 10px !important;
}
.mob_flight_search_depart:last-child,
.mob_flight_search_depart_going_date:last-child {
  border-radius: 0px 10px 10px 0 !important;
}
.flight_search_details_main
  .flight_search_details_row
  .flight_search_column
  .flight_search_error_message {
  position: relative;
  top: -8px;
  float: left;
  width: 104%;
}
.flight_search_details_main
  .flight_search_details_row
  .flight_search_column
  .flight_search_error_message
  span {
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
}
.mob_flight_search_details_container
  .mob_flight_search_details_main
  .mob_flight_search_details_row
  .mob_flight_swap_main {
  position: initial;
}
.mob_flight_search_details_container
  .mob_flight_search_details_main
  .mob_flight_search_details_row
  .mob_flight_swap_main
  .mob_flight_swap {
  position: absolute;
  left: 45%;
  z-index: 1;
  top: 40px;
  width: 34px;
  /* transform: rotate(270deg); */
}

.mob_flight_search_details_row .mob_flight_search_depart {
  width: 100%;
}

.mob_flight_search_details_row .mob_flight_search_depart_date {
  width: 100%;
}
.mob_flight_search_details_row .mob_flight_search_depart_going_date {
  width: 49%;
  cursor: pointer;
}

.mob_flight_search_details_row .mob_flight_search_pax {
  width: 100%;
  font-family: var(--font_family);
  padding-left: 15px;
  padding-top: 3px;
  padding-right: 15px;
  height: 70px !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
  border: none !important;
}
.mob_flight_search_details_row .mob_flight_search_class {
  width: 100%;
  height: 40px !important;
  border: none !important;
  /* padding-left: 15px; */
  /* border-top: 1px solid #bbb !important; */
  /* border-radius: 0 !important; */
}
.mob_flight_pax_new{
  line-height: 10px;
}
.mob_flight_pax_new label{
  padding-bottom: 0!important;
  font-size: 20px!important;
  margin-bottom: 0!important;
  padding-top: 15px;
}
.mob_flight_pax_new span{
  font-size: 10px !important;
}
.mob_flight_whole{
  padding: 0 70px;
}
.mob_flight_search_details_row .mob_flight_search_widget_btn {
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
  border-radius: 50px;
  width: 100%;
  height: 50px;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  /* margin-top: 3px; */
  margin-left: 0px;
  color: #ffffff;
  cursor: pointer;
  outline: 0;
  margin: 20px 0;
}

.mob_flight_search_details_row .mob_flight_search_widget_btn:hover,
.mob_flight_search_details_row .mob_flight_search_widget_btn:focus {
  /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
  box-shadow: 1px 1px 10px var(--buttons_color);
  transition: transform 0.3s ease;
}

.mob_flight_search_wid_inputfield {
  width: 100%;
  /* height: 50px !important; */
  /* margin-bottom: 10px; */
  margin-left: 0px !important;
  /* padding-top: 10px; */
}
.mob_flight_search_wid_inputfield p{
  padding: 0 13px;
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left !important;
  color: #000;
}
.mob_flight_search_wid_inputfield .mob_flight_search_flex_adjust {
  display: flex;
}
.flight_search_wid_inputfield .disable_pax_btn {
  /* opacity: 0.5; */
}

.mob_flight_search_wid_inputfield .disable_pax_btn {
  /* opacity: 0.5; */
}

.mob_flight_search_wid_inputfield .add_pax {
  width: 20px !important ;
  height: 20px !important;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  background-color: transparent;
  border: 0;
  object-fit: contain;
  height: 40px !important;
    width: 40px !important;
}

.mob_flight_search_wid_inputfield .minus_pax {
  width: 40px !important;
  height: 40px !important;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  background-color: transparent;
  border: 0;
  object-fit: contain;
}

.mob_flight_search_wid_inputfield .disbale_traveller {
  opacity: 0.5;
}

.mob_flight_search_details_main
  .mob_flight_search_details_row:first-child
  label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-bottom: 5px;
  padding-right: 5px;
  padding-top: 5px;
  width: 170px;
  display: inline-block;

}

.mob_flight_search_details_main
  .mob_flight_search_details_row:first-child
  > div {
  padding-left: 50px;
  padding-top: 3px;
  position: relative;
  margin-top: 0px;
}
.flight_ptext p{
  text-align: left;
  font-size: 10px;
}
.mob_flight_search_details_main
  .mob_flight_search_details_row:first-child
  > div
  img {
  /* position: absolute;
  left: 7px;
  top: 10px;
  width: 25px;
  height: auto; */
}
.mob_flight_search_details_main
  .mob_flight_search_details_row:first-child
  > div
  input {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: none;
  padding: 0 10px 0 0;
  height: 30px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;
  cursor: pointer;
}

.mob_flight_search_details_main
  .mob_flight_search_details_row:first-child
  > div
  input::placeholder {
  font-size: 15px;
  color: var(--font_color);
  font-weight: normal;
}

.mob_flight_autocomplete_value {
  position: absolute;
  width: 100%;
  max-height: 245px;
  z-index: 100;
  top: 53px;
  background-color: #fff;
  box-shadow: 0px 19px 22.3px 6.7px rgb(153 153 153 / 13%);
  overflow: auto;
  color: var(--font_color);
  font-size: 12px;
  left: 0;
}

.mob_flight_autocomplete_element {
  width: 100%;
  display: flex;
  padding: 5px 17px;
  cursor: pointer;
  align-items: center;
  min-height: 70px;
}

.mob_flight_autocomplete_element:hover,
.mob_flight_autocomplete_element:focus {
  background: #ccc;
}

.mob_flight_autocomplete_tag {
  padding: 1px 5px;
  background-color: #48a842;
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  vertical-align: top;
  display: inline-block;
  border-radius: 7px;
}

.mob_flight_autocomplete_name {
  width: 100%;
  text-align: left;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
}

.mob_flight_autocomplete_name span {
  width: 100%;
  margin-top: 5px;
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  text-align: left;
  display: block;
}

.mob_flight_search_details_row ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mob_flight_search_details_row ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

/* Handle */
.mob_flight_search_details_row ::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.mob_flight_search_pax span {
  margin-bottom: 10px;
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
  display: inline-block;
  color: var(--font_color);
}

.mob_flight_search_pax label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
  display: inline-block;
  color: var(--font_color) !important;
  margin-right: 3px;
}

.mob_flight_search_pax .mob_flight_search_wid_inputfield {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin-left: -20px !important;
  color: var(--primary_color);
  /* margin-top: 15px; */
}
.mob_flight_search_wid_inputfield .pax_block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -15px;
}
.mob_flight_search_pax .mob_flight_search_wid_inputfield span {
  margin-bottom: 0;
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  padding: 0px 10px;
  position: relative;
  /* height: 30px; */
  text-align: center;
  border: none;
  /* margin-top: -2px; */
  display: block;
  width: 40px;
}

.mob_flight_cabin_class_cont {
  display: flex;
  align-items: center;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: var(--font_color);
  padding: 0 0 0px 0;
  cursor: pointer;
  height: 40px;
  justify-content: space-between;
  position: relative;
}
.mob_flight_cabin_class_arrow svg {
  position: relative;
  top: 10px;
}
.flight_cabin_class_name {
  height: 40px;
  padding-left: 15px;
  border-top: 1px solid #bbb;
  display: flex;
  align-items: center;
}
.mob_flight_cabin_class_cont img {
  max-width: 25px;
  margin: 0 17px 0 8px;
  height: auto;
  object-fit: contain;
}
.mob_flight_cabin_class_list {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  /* border: 1px solid #e2e0e0; */
  border-radius: 10px;
  border-top: none;
  background: #fff;
  z-index: 1;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.mob_flight_cabin_class_ele {
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px;
    margin: 0;
    border: 1px solid var(--primary_color);
    border-radius: 20px;
    margin: 10px 0;
}
.mob_flight_cabin_class_ele_active {
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px;
    margin: 0;
    border: 1px solid var(--primary_color);
    border-radius: 20px;
    margin: 10px 0;
    background-color: var(--primary_color);
    color:white;
}

.mob_flight_cabin_class_ele:hover {
  background-color: var(--primary_color);
  color: #ffffff;
}

.mob_flight_cabin_class_name {
  width: 170px;
  padding-left: 15px;
}


.mob_flight_cabin_class_arrow_img {
  position: relative !important;
  /* top: unset !important; */
  left: unset !important;
  width: 20px !important;
}

.mob_flight_cabin_class_cont img{
  position: absolute;
    left: 7px !important;
    top: 1px !important;
    width: 25px;
    height: auto;
}
.mob_flight_search_error_message {
  color: #e8664e;
  font-size: 11px;
  position: relative;
  top: -5px;
  margin-top: 10px;
  padding-left: 10px;
}

.btn_search {
  padding-left: 0 !important;
  position: relative;
}
.btn_search svg{
  position: absolute;
  width: 30px;
  height: 30px;
  top: 35px;
  left: 100px;
}

/************ Calendar Module ************/

.calendar_wid_container {
  width: 100%;
  /* width: 100%;
  height: 70px; */
  /* border-bottom: 1px solid #bbb; */
  /* padding-top: 10px; */
  /* display: flex; */
  /* align-items: center; */
}

.calendar_wid_container_popup {
  z-index: 100;
  position: fixed;
  min-width: 333px;
  height: 100%;
  background: white;
  overflow: auto;
  left: 0;
  top: 65px;
  right: 0;
  bottom: 0;
}
.calendar_wid_container_popup > img {
  height: 20px !important;
  width: 20px !important;
  z-index: 99;
  top: 10px !important;
  position: fixed !important;
}
.calendar_wid_container_popup .calendar_head_container {
  width: 100%;
  top: 0;
  position: sticky;
  left: 0px;
  z-index: 2;
  background-color: rgb(255, 255, 255);
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main {
  width: 100%;
  display: flex;
  color: var(--font_color);
  padding: 20px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.calendar_wid_container_popup
  .calendar_head_container
  .calendar_head_main
  .calendar_head_left {
  width: 50%;
  text-align: center;
  font-size: small;
  align-items: center;
}
.calendar_wid_container_popup
  .calendar_head_container
  .calendar_head_main
  .calendar_head_right {
  width: 50%;
  text-align: center;
  font-size: small;
  align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main h5 {
  border-bottom: 2px dashed #ccc;
  display: inline-block;
  font-weight: bold;
}
.calendar_head_main span {
  display: block;
}
.calendar_wid_container .calendar_main_body_popup {
  overflow: auto;
  /* height: 75vh; */
  padding: 20px 30px 70px;
  width: 100%;
  display: block;
}

.calendar_container .calendar_main {
  padding: 10px 15px;
  display: flex;
  flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side {
  position: absolute;
  cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side {
  position: absolute;
  cursor: pointer;
  right: 25px;
}

.calendar_main .calendar_whole_body {
  width: 100%;
  box-sizing: content-box;
}
.calendar_main .calendar_head {
  display: flex;
  width: 100%;
}
.calendar_head .calendar_head_left_side {
  text-align: left;
  width: 10%;
}
.calendar_head .calendar_head_center_side {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  text-transform: uppercase;
  color: var(--font_color);
  width: 100%;
}

.calendar_head .calendar_head_right_side {
  text-align: right;
  width: 10%;
}

.calendar_head .calendar_head_icon {
  position: relative;
  left: 0;
  top: 0;
}

.calendar_body .calendar_week_heads {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.calendar_week_heads .coln_center_week {
  padding: 5px 0;
  font-size: 13px;
  width: calc(100% / 7);
  text-align: center;
  font-family: var(--font_family);
  text-transform: uppercase;
  color: var(--font_color);
}
.calendar_body .calendar_day_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.calendar_body .calendar_day_list .day_cell_center {
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  transition: color 0.3s, border 0.3s;
  cursor: pointer;
  height: 25px;
  font-family: var(--font_family);
  margin: 3px 0;
  font-size: 14px;
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_body .calendar_day_list .day_cell_center_highlight {
  text-align: center;
  text-decoration: none;

  cursor: pointer;
  height: 30px;
  font-family: var(--font_family);
  margin: 3px 0;
  font-size: 14px;
  width: calc(100% / 7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar_body .calendar_day_list .day_cell_center_highlight > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 100%;
  background-color: #cbe3f5;
  transition: color 0.3s, border 0.3s;
  /* border-radius: 5px; */
}
.calendar_body .calendar_day_list .day_cell_center > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--font_color);
  font-size: 14px;
  width: 100%;
}
.calendar_body {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px dashed #ccc;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body
  .calendar_day_list
  .day_cell_center:hover
  > span.calendar_day_disable
  .calendar_body
  .calendar_day_list
  .day_cell_center:focus
  > span.calendar_day_disable {
  cursor: not-allowed;
  color: #999 !important;
  background-color: transparent !important;
  opacity: 0.5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date
/* ,.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span */ {
  /* background: url(/images/selected_to.svg) no-repeat;
  background: var(--secondary_color);
  height: 30px;
  width: calc(100% / 7); */
  background-size: contain;
  background-position: 55%;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date span {
  font-weight: bold;
  color: #fff;
  /* width: 35px; */
  height: 30px;
  background: var(--primary_color);
  border-radius: 4px;
}

.calendar_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
}

.calendar_footer button {
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: var(--primary_color);
  border-radius: 3px;
  border: none;
  font-size: 14px;
  padding: 10px 50px;
}
.calendar_modal_close {
  height: 20px;
  position: relative;
}
.calendar_modal_close svg {
  height: 25px;
  width: 25px;
  background-color: var(--primary_color);
  color: #fff;
  border-radius: 50px;
  padding: 4px;
  z-index: 3;
  margin-left: auto;
  margin-top: 10px;
  margin-right: 10px;
  position: fixed;
  right: 0;
}
.flgt_cont_flx {
  display: flex;
  padding-left: 0 !important;
  gap: 4px;
}
.flgt_cont_flx .mob_flight_search_depart {
  width: 49%;
}
.flgt_cont_flx .mob_flight_search_wid_inputfield label,
.flgt_cont_flx .calendar_wid_container label {
  padding-left: 45px !important;
}
.flgt_cont_flx .mob_flight_search_wid_inputfield input,
.flgt_cont_flx .calendar_wid_container input {
  padding-left: 15px !important;
  margin-top: 5px;
}
.flgt_cont_flx .calendar_wid_container div img {
  top: 12px !important;
}
.economy_lbl_wp {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #bbb;
}
.economy_lbl_wp span {
  font-size: 16px;
  color: var(--font_color);
}
.economy_lbl_wp img {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: 15px !important;
}
.country_details {
  padding: 0 15px;
}
.cntry_code {
  font-size: 16px;
  color: var(--font_color);
}
.cntry_full_dtail {
  font-size: 12px;
  color: var(--font_color);
}
.passenger_wp {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #bbb;
  border-radius: 10px;
  height: 50px;
  align-items: center;
  padding-top: 10px !important;
  padding-left: 15px !important;
}
.passenger_wp span {
  font-size: 16px;
  color: var(--font_color);
}
.passenger_wp img {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: 25px !important;
}
.pop_up_drop_down {
  padding: 15px!important;
  border-radius: 35px;
  position: absolute !important;
  bottom: 0px !important;
  top: 50%;
  /* height: max-content; */
  z-index: 9;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.backDrop {
  background: #c8c8d873;
  position: absolute !important;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 5;
}
/* .class_active{
    background-color: var(--primary_color);
    color:white;
} */
.passDrop{
  letter-spacing: normal;
  padding-left: 20px !important;
  padding-top: 9px !important;
  border: 1px solid #bbb;
  border-radius: 20px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-bottom: 9px;
  margin-top: 5px !important;
}
.mob_flight_main_tops{
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.mob_flight_main_top{
    width: 30px;
    border: 1px solid #707070;
    border-radius: 20px;
}
.mob_flight_main_top svg{
  padding: 5px;
}
.flight_pop{
  padding-left: 0px !important;
}

.pax_btn{
float: left;
width: 100%;

}

.pax_btn button{
 background-color: var(--primary_color);
    border: 1px solid var(--primary_color);
    border-radius: 50px;
    width: 100%;
    height: 50px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .4px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    outline: 0;
    margin: 20px 0;
}

.new_cal_main{
      height: 100%;
      width: 100% !important;
      display: flex;
      align-items: center;
      /* padding-bottom: 15px; */
}


.new_cal_main:nth-child(1){
  border-bottom: 1px solid #e6e6e6;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
  height: 70px;
}

.flex_cont_cal{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 5px;
  gap: 10px;
  margin-top: 5px;
}

.new_cal_text p{
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}

.new_cal_digit_text{
  font-size: 35px;
  color: var(--primary_color);
  font-weight: 600;
}

.flight_search_wid_inputfield_img_cal{
  width: 30px;
  height: 30px;
}

.calendar_wid_main{
  /* width: 100%;
  padding: 5px;
  padding-left: 0px;
  padding-right: 0;
  display: flex;
  align-items: center;
  gap: 5px; */
  /* height: 70px; */
}

.new_cal_digit{
  position: relative;
}
.new_cal_digit h1{
  width: 75%;
}
.input_search_destination input {
  padding: 20px 40px !important;
  margin: 10px auto;
  border: 1px solid rgba(0,0,0,.16)!important;
  border-radius: 50px;
  width: 90% !important;
  display: block;
  height: 25px !important;
}
.mob_flight_autocomplete_value .input_search_destination svg{
  position: absolute !important;
  left: 15px !important;
  top: 18px !important;
  width: 20px !important;
  height: 19px !important;
  color: var(--button_color) !important; 
}
.flgt_cont_flx .mob_flight_search_wid_inputfield .input_search_destination input{
  padding-left: 30px !important;
}

.check_add_box{
  display: flex;
  width: 80%;
  padding-left: 0 !important;
}
.non_stop_box{
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px
}
.non_stop_box input{
  width: 15px !important;
  height: 15px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
}

.prefAir_box{
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px
}

.prefAir_box input{
  width: 15px !important;
  height: 15px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
}
.calendar_wid_container .calendar_wid_main img{
  width: 25px;
  height: auto;
  position: absolute;
  top: 8px;
  left: 10px;
}

.flgt_cont_flx .tapToadd{
    display: flex;
    align-items: flex-start;
    padding: 10px;
    gap: 20px;
    margin-bottom: 9px;
    border-radius: 0px 10px 10px 0 !important;
    position: relative;
    border: 1px solid #bbb;
    font-weight: 600;
}

.flight_search_wid_inputfield_img_cal{
  width: 30px;
  margin-top: 3px;
  height: auto;
  position: inherit !important;
}
.flight_search_wid_inputfield_img_cal svg{
  width: 30px;
  height: auto;
  margin-top: 3px;
  position: inherit!important;

}
.mob_flight_cabin_class_cont_last{
  margin-top: 2px;
  border-top: 1px solid #bbb;
  padding: 10px;
  height: 40px;
}
.new_main_flight_sec_1 {
  float: left;
  margin-bottom: 9px;
  position: relative;
  background: #fff;
  border: 1px solid #bbb;
  width: 49%;
  cursor: pointer;
  border-radius: 10px 0 0 10px!important;
  height: 100%;
}
.new_main_flight_sec_2{
   float: right;
    margin-bottom: 0px;
    position: relative;
    background: #fff;
    height: auto;
    border: 1px solid #bbb;
    width: 49%;
    cursor: pointer;
    border-radius: 0px 10px 10px 0px!important;
    position: relative;
}
.new_main_flight_sec_2 .new_cal_main {
  display: flex;
  align-items: center;
  padding: 10px 0 5px 20px;
  gap: 10px;
  margin-bottom: 0px;
  border-radius: 0 10px 0px 0!important;
  position: relative;
  font-weight: 600;
  height: 112px;
}

.new_main_flight_sec_2  .mob_flight_search_column.mob_flight_search_class
{
margin-bottom: 0px !important;
}
.new_main_flight_sec_22 .new_cal_main{
  height: 70px;
}
.return_economy{
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.new_main_flight_sec_22 svg{
  margin-top: -3px;
}
.new_main_flight_sec_2 svg{
  margin-top: -3px;
}

.new_main_flight_sec_2 .showCrossIconrounday img{
  right: 10px;
  cursor: pointer;
  position: absolute;
  top: 8px !important;
  /* z-index: 99; */
  left: unset !important;
  right: 10px !important;
  cursor: pointer !important;   
}
.new_main_flight_sec_22 .flex_cont_cal{
  margin: 0;
}
/* .new_main_flight_sec_2 .new_cal_digit h1 {
  font-size: 16px;
} */

.flex_tap_pos{
  display: flex;
  align-items: center;
  position: absolute;
  top: 3px;
}

.flex_tap_pos .new_cal_digit{
  padding-left: 30px;
  padding-top: 10px;
}

.flex_tap_pos .flight_search_wid_inputfield_img_cal{
  margin-top: -7px;
}

.mob_flight_search_depart img{
  position: absolute;
  width: 25px;
  left: 12px;
  top: 10px;
}



.mob_flight_search_depart:last-child img{
  position: absolute;
  width: 25px;
  left: 12px;
  top: 5px !important;
}