/********* Calendar *********/

.calendar_wid_container{
    width: 100%;
}

.calendar_wid_container_popup{
    z-index: 100;
    position: fixed;
    min-width: 333px;
    height: 100%;
    background: white;
    overflow: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.calendar_wid_container_popup > img{
    height: 20px !important;
    width: 20px !important;
    z-index: 99;
    top: 10px !important;
    position: fixed !important;
}
.calendar_wid_container_popup .calendar_head_container{
    width: 100%;
    top: 0;
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: rgb(255, 255, 255);
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main{
    width: 100%;
    display: flex;
color: var(--font_color);
    padding: 20px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_left{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main .calendar_head_right{
    width: 50%;
    text-align: center;
    font-size: small;
    align-items: center;
}
.calendar_wid_container_popup .calendar_head_container .calendar_head_main h5 {
    border-bottom: 2px dashed #ccc;
    display: inline-block;
    font-weight: bold;
}
.calendar_head_main span{
    display: block;
}
.calendar_wid_container .calendar_main_body_popup{
    overflow: auto;
    /* height: 75vh; */
    padding:  20px 30px 70px;
    width: 100%;
    display: block;
}

.calendar_container .calendar_main{
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
}

.calendar_container .calendar_main .calendar_head_left_side{
    position: absolute;
    cursor: pointer;
}
.calendar_container .calendar_main .calendar_head_right_side{
    position: absolute;
    cursor: pointer;
    right: 25px;
}

.calendar_main .calendar_whole_body{
    width: 100%;
    box-sizing: content-box;
}
.calendar_main .calendar_head{
    display: flex;
    width: 100%;
}
.calendar_head .calendar_head_left_side{
    text-align: left;
    width: 10%;
}
.calendar_head .calendar_head_center_side{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    text-transform: uppercase;
    color: var(--font_color);
    width: 100%;
}

.calendar_head .calendar_head_right_side{
    text-align: right;
    width: 10%;
}

.calendar_head .calendar_head_icon{
    position: relative;
    left: 0;
    top: 0;    
}

.calendar_body .calendar_week_heads{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.calendar_week_heads .coln_center_week{
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: var(--font_color);
}
.calendar_body .calendar_day_list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}   

.calendar_body .calendar_day_list .day_cell_center{
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 25px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.calendar_body .calendar_day_list  .day_cell_center_highlight{
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    height: 30px;
    font-family: var(--font_family);
    margin:  3px 0;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary_color);
    opacity: .5;
    color: #fff;
    border-radius: 50px;
}
.calendar_body .calendar_day_list  .day_cell_center_highlight>span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 80%;
    transition: color 0.3s, border 0.3s;
}
.calendar_body .calendar_day_list .day_cell_center>span{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
color: var(--primary_color);
font-size: 14px;
width: 100%;
}
.calendar_body{
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px dashed #ccc;
}
.calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
.calendar_body .calendar_day_list .day_cell_center:hover>span.calendar_day_disable
.calendar_body .calendar_day_list .day_cell_center:focus>span.calendar_day_disable{
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: .5;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date
/* ,.calendar_body .calendar_day_list .day_cell_center:hover>span,
.calendar_body .calendar_day_list .day_cell_center:focus>span */
{
    background: url(/images/selected_to.svg) no-repeat;
    background: var(--primary_color);
    height: 30px;
    width: calc(100% / 7);
    background-size: contain;
    background-position: 55%;
}

.calendar_body .calendar_day_list .day_cell_center.selected_calendar_date span{
    font-weight: bold;
    color: #fff;
}

.calendar_footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
}

.calendar_footer button{
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: var(--primary_color);
    border-radius: 3px;
    border: none;
    font-size: 14px;
    padding: 10px 50px;
}
.err_message_text{
    font-size: 11px;
    color: #e8664e;
}

.calendar_modal_close{
    height: 20px;
    position: relative;
}
div.calendar_modal_close svg{
    height: 25px!important;
    width: 25px!important;
    background-color: var(--primary_color);
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    z-index: 3;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    position: fixed;
    right: 10px;
    top: 0px !important;

}
.calendar_wid_main{
    outline: 0;
}