

.pointer{
  width: 20px !important;
  height: 20px;
}

.dropDownlistmain{
  background-color: #fff;
}
.maininput{
  border: none;
}
.subinput{
margin: 0 11px 9px 10px;
padding: 13px 57px 11px 12px;
border: solid 1px #d6d6d6;
background-color: #fff;
}








.dropDownlistmain {
  /* position: relative; */
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  margin-top: 4px;
  z-index: 9;
}

.dropDownlistmain ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  /* background-color: #fff; */
}

.dropDownlistmain ul li {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.dropDownlistmain ul li input[type="text"] {
  height: 40px !important;
  width: 100% !important;
  padding: 4px 15px !important;
  border: 1px solid 
  #d6d6d6 !important;
  border-radius: 50px !important;
  background: #fff !important;
}
.dropDownlistmain ul li input[type="text"]::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: var(--font_color) !important;
  padding-left: 3px; 
}

.dropDownlistmain ul li input[type="checkbox"] {
  margin-right: 4px;
}

.dropDownlistmain ul li span {
  margin-right: 4px;
}
.dropdown_value_container{
  max-height: 280px;
  overflow: auto;
}









.checkbox_label{
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  align-items: center;
}
.checkbox_label .bagCheckbox{
  position: relative;
  margin-left: 0 !important;
}
.checkbox_label .bagCheckbox input{
  cursor: pointer;
    height: 20px;
    opacity: 0;
    position: absolute;
    width: 20px;
    z-index: 2;
}
.checkbox_label .bagBox{
  align-items: center;
    background: #fff;
    border: 1px solid var(--secondary_color);
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 0 !important;
}

.checkbox_label .bagCheck{
  border-color: #fff;
  -o-border-image: none;
  border-image: none;
  border-style: none solid solid none;
  border-width: medium 2px 2px medium;
  box-sizing: border-box;
  height: 11px;
  opacity: 0;
  position: relative;
  top: -2px;
  left: 2px;
  transform: scale(1) rotate(45deg);
  transition-delay: .1s;
  width: 5px;
  z-index: 1;
  margin-left: 0;
}
.checkbox_label input[type=checkbox]:checked~.bagBox {
  background: var(--secondary_color);
  border: 0;
}
.checkbox_label .bagCheckbox input[type=checkbox]:checked~.bagBox .bagCheck {
  opacity: 1;
  transform: scale(1) rotate(45deg);
}
.advTitle{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .38px;
  text-align: left;
  color: var(--primary_color);
  margin-left: 2px;
}
.dropdown_Flx{
  display: flex;
  align-items: center;
  /* padding-left: 15px; */
}
.dropdown_Flx svg{
  height: auto !important;
}


.dropdown_value_container::-webkit-scrollbar {
width: 10px;
height: 10px;
}

.dropdown_value_container::-webkit-scrollbar-track {
background: #f1f1f1; 
border-radius: 10px;
}

.dropdown_value_container::-webkit-scrollbar-thumb {
background: #888; 
border-radius: 10px;
}

.dropdown_value_container::-webkit-scrollbar-thumb:hover {
background: #555; 
}
.drop_wd{
  width: 100%;
}
.dropdown_Flx input {
  width: 100%;
  border: 1px solid transparent;
  background: transparent;
  height: 30px;
  padding-left: 0;
  color: var(--primary_color);
    font-weight: 600;
    font-size: 13px;
}
.dropdown_Flx input:focus{
  border: none;
  box-shadow: none;
}
.dropdown_Flx input::placeholder{
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: var(--primary_color);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .checkbox_label .bagCheck{
    top: -2px;
    left: 7px;
  }
}