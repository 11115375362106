.tnc_txt a {
  color: var(--primary_color) !important;
}

.abc {
  color: red;
}

.holidaymaintabnavbarrow {
  width: 100%;
  /* background-color: #ffffff; */
  /* padding: 12px 0px 0px; */
  margin-bottom: 5px;
  border-radius: 3px;
}
.holidaycarouselcontainer {
  /* width: 1200px; */
  width: 100%;
  margin: 0 auto;
  padding: 0 5px;
}
.navbartab > ul {
  list-style: none;
  margin: 0px 0px;
  padding: 0px 0px;
  display: flex;
  gap: 35px;
}
.service_title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.34px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.serv_flx {
  display: flex;
  align-items: center;
}
.navbartab ul .activetab {
  border-bottom: 2px solid var(--secondary_color);
  border-radius: 50px;
  background: var(--secondary_color);
  padding-bottom: 10px;
  padding: 15px 10px;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--primary_color);
  display: flex;
  align-items: center;
  position: relative;
  width: 60px;
  height: 60px;
}
/* .navbartab ul .activetab::after{
    content: "";
    display: block;
    position: absolute;
    bottom: -45px;
    left: 25px;
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 15px;
    transform: translateX(-50%) rotate(135deg);
    z-index: 1;
} */
.navbartab ul .activetab::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -45px;
  left: 10px;
  border-color: transparent transparent #fff #fff;
  border-style: solid;
  border-width: 15px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #fff;
}

.navbartab ul li {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: #565656;
  cursor: pointer;
}
.navbartab ul li.inactive {
  padding: 2px;
  display: flex;
  align-items: center;
}
.navbartab ul li .service_icon img {
  width: 40px;
}

/* 
.navbartab >ul >li{
    float: left;
    padding: 0px 20px;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    line-height: 2.02;
    text-align: center;
    color: var(--font_color);
    cursor: pointer;
} */

.search {
  /* background: #e2e2e2; */
  padding: 20px 5px;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main {
  padding: 10px 0px;
  background: #e2e2e2;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible {
  display: block;
}

.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .mobo_tab_header {
  display: flex;
  height: 60px;
  background: #fff;
  align-items: center;
  padding: 0px 15px;
}
.mob_flight_search_wid_container .mob_flight_search_wid_main .mobile_visible .arrow_image {
  width: 23px;
  height: 14px;
  margin-right: 25px;
}

.calendar_calendar_wid_container {
  width: 100%;
  margin-top: -5px;
}
.widget_container {
  /* margin-top: -25%; */
  z-index: 9;
  position: relative;
}

/* .parentWid{
    position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
background: #305572b5;
z-index: 9999;
cursor: pointer;
overflow: scroll;
}

.childWid{
position: relative;
top: 50%;
cursor: pointer;
} */

/* .widget_container{
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
}*/

.header_hero_cont {
  /* margin: 20px;
    margin-top: 70px;
    margin-bottom: 15px; */
  margin: 0;
}
.header_hero_cont h2 {
  font-family: var(--font_family);
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 0.35px;
  text-align: center;
  color: #fff;
}
.header_hero_cont p {
  font-family: var(--font_family);
  font-size: 30px;
  letter-spacing: 0.35px;
  text-align: center;
  color: #fff;
}
.home_container {
  min-height: 100vh;
}

/* .theme4_ban{
    height: 600px;
} */

@media screen and (min-width: 320px) and (max-width: 768px) {
  /* .home_container{
        position: relative;
        z-index: 1000;
    } */
  .mobile_home_container {
    position: relative;
    z-index: 1000;
  }
}

@media only screen and (height: 768px) and (width: 1024px) {
  .header_hero_cont {
    margin-top: 50px;
  }
  .widget_container {
    margin-top: -30%;
    z-index: 9;
    position: relative;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .header_hero_cont {
    margin-top: 50px;
  }
  .widget_container {
    margin-top: -28%;
    z-index: 9;
    position: relative;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1450px) {
  .header_hero_cont {
    margin-top: 0px;
  }
  .widget_container {
    margin-top: -21%;
  }
}
@media only screen and (min-width: 1451px) and (max-width: 1800px) {
  .widget_container {
    margin-top: -18%;
  }
}

@media only screen and (min-width: 1801px) and (max-width: 2140px) {
  .widget_container {
    margin-top: -16%;
  }
}

@media only screen and (min-width: 2141px) and (max-width: 3850px) {
  .header_hero_cont {
    margin-top: -50px;
    margin-bottom: 150px;
  }
  .widget_container {
    margin-top: -28%;
  }
}

.enquiy_container {
  width: 100%;
  text-align: center;
  padding: 27px 0;
}
.quote_success {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
}
.btn_container {
  margin-top: 28px;
}
.ok_button {
  padding: 0 28px;
  height: 37px;
  border-radius: 30px;
  background-color: var(--buttons_color);
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
}
.btn_container_space {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nobanner {
  height: 406px;
  background-color: #ddd;
}
