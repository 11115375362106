.car_searchwidget_container {
  width: 100%;
  padding: 20px;
  display: table;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .15);
  margin-top: 0;
  position: relative;
}
.car_way_tab {
    width: 100%;
    text-align: left;
    padding-bottom: 0px;
}
.car_way_btngrp {
    display: flex;
    flex-wrap: wrap;
    color: var(--primary_color);
    position: relative;
    margin: 0 40px;
}
.class_div {
    width: 100%;
}
.active_way input {
    position: absolute;
    z-index: 5;
    opacity: 0;
    padding: 0 !important;
}
.active_way input[type="radio"] + label {
    font-weight: 600;
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.active_way input[type="radio"]:checked + label:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em var(--secondary_color);
}
.active_way input[type="radio"] + label:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em #fff;
}
.active_way input[type="radio"] + label:after,
.active_way input[type="radio"] + label:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 21px;
    height: 21px;
    text-align: center;
    color: #fff;
    font-family: Times;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 1px solid var(--secondary_color);
}
.active_way input[type="radio"] + label {
    display: inline-block;
    transition: all 0.3s ease-in-out;
    padding-left: 1.5em;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    position: relative;
    cursor: pointer;
    padding-top: 5px;
}
.active_way input[type="radio"]:checked + label:before {
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em var(--primary_color);
}
.active_way input[type="radio"] + label:after,
.active_way input[type="radio"] + label:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 0;
    width: 17px;
    height: 17px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease;
    border: 1px solid var(--primary_color);
}
.car_way_btngrp .active_way {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    background: 0 0;
    border-radius: 15px;
    padding: 6px 15px;
    transition: 0.3s;
    display: flex;
    align-items: center;
}
.radio_cont {
    display: flex;
    margin-top: 10px;
}
.car_way_btngrp .radio_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-right: 33px;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    cursor: pointer;
}
.radio_button input {
    display: none;
    width: 0;
    height: 0;
}
.radio_button span {
    width: 21px;
    height: 21px;
    position: relative;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid var(--primary_color);
}
.radio_active {
    font-weight: 600;
}
.radio_button input:checked label {
    font-weight: 600;
}
.radio_button input:checked + span:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary_color);
}
.radio_button input:checked + span {
    background-color: #fff;
    border: 1px solid var(--primary_color);
}
.radio_button input:disabled + span {
    background-color: #e5e5e5;
}
.radio_button span:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    background-color: #76b176;
}


.car_search_details_container{
    width: 100%;
    margin-top: 10px;
}

.car_search_details_wrap{
    display: flex;
    position: relative;
    align-items: center;
    border: 1px solid #d4d4d4;
    padding: 10px 20px;
    border-radius: 30px;
    margin: 0 40px;
    align-items: flex-end;
    /* height: 100px; */
    height: 85px;
    margin: 0;
    padding-top: 0;
    border: 0;
    padding-right: 0;
    padding-left: 0;
}

.car_search_details_main label:first-child,
.car_search_details_new_wrap label:first-child,
.car_searchwidget_calendar_container label:first-child,
.car_searchwidget_pickup_container label:first-child:first-child,
.car_searchwidget_distance_container label:first-child {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0;
    text-transform: capitalize;
    padding-bottom: 10px;
    display: block;
}
.car_button_wrap{
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-left: 10px;
}
.car_button{
  background-color: var(--buttons_color);
  border: 1px solid var(--buttons_color);
  border-radius: 25px;
  width: 94px;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: .4px;
  text-align: center;
  margin-top: 0;
  margin-left: 0;
  color: #fff;
  cursor: pointer;
  outline: 0;
  padding: 8px 12px;
}

.car_search_details_main{}

.car_autocomplete_container{
    /* padding-right: 30px; */
      padding-right: 10px;
    padding-left: 10px;
    align-self: flex-end;
    position: relative;
    bottom: -15px;
}

.car_searchwidget_icon{}

.car_searchwidget_icon svg {
    margin: auto;
    width: 30px;
    height: 30px;
    fill: var(--primary_color);
    cursor: pointer;
}

.car_search_details_main{
  align-self: flex-end;
  border-bottom: 2px solid #dedede;
    padding-bottom: 7px;
}
.flight_search_wid_inputfield {
  position: relative;
}

.car_search_details_new_wrap{}

.car_search_details_icon{}

.car_searchwidget_MobileContainer{}

.car_searchwidget_calendar_container{
  border-right: 0 !important;
}

.car_searchwidget_calendar_guest{}

.car_searchwidget_pickup_container{
  width: 25%;
  padding: 5px 0;
  border-bottom: 2px solid #dedede;
  margin-left: 15px;
  height: 55px;
}

.car_searchwidget_distance_container{
  width: 25%;
  display: flex;
}

.autocomplet_wrap{
    padding: 0;
    display: flex;
    align-items: center;
}

.autocomplet_wrap input {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    border: none;
    padding: 0 !important;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    caret-color: transparent;
}

.autocomplet_wrap input::placeholder{
    font-size: 14px !important;
    font-weight: 700;
    color: var(--primary_color) !important;
}
.autocomplet_wrap input:focus{
    box-shadow: none;
}

.calendar_wid_main{
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    cursor: pointer;
}
.flight_search_wid_container {
    width: 100%;
    /* padding: 30px 25px 50px 30px; */
    padding: 20px 0 0px;
    /* padding-bottom: 40px; */
    display: table;
    background: #ffffff;
    margin-bottom: 30px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.15);
    margin-top: 0px;
    position: relative;
    padding-top: 60px;
  }
  .popular_destination1{
    display: flex;
    align-items: center;
  }
  
  .flight_way_tab {
    width: 100%;
    text-align: left;
    /* border-bottom: 1px solid #dedede; */
    padding-bottom: 10px;
    /* height: 50px; */
  }
  
  .flight_way_btngrp {
    display: flex;
    flex-wrap: wrap;
    color: var(--primary_color);
    position: relative;
    /* max-width: 1200px;
      margin: auto; */
    margin: 0 40px;
    margin-left: 0;
    margin-right: 0;
  }
  
  .flight_way_btngrp button svg {
    fill: var(--secondary_color);
  }
  
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_column
    .flight_search_error_message {
    position: relative;
    top: -6px;
    float: left;
    width: 100%;
    top: 5px;
    left: 0;
  }
  
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_column
    .flight_search_error_message
    span {
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
  }
  .fare_tooltip{
    border-radius: 6px;
    padding: 10px 15px;
    position: absolute;
    z-index: 9;
    top: 130%;
    left: 50%;
    border: 1px solid #d8d8d8;
    line-height: 18px;
    font-size: 12px;
    width: 285px;
    transform: translateX(-50%);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    color: #000;
    font-weight: 500;
    background: #fff;
  }
  .fare_tooltip:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 18px);
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #d8d8d8;
  }
  .fare_tooltip:after {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 17px);
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
  }
  
  .flight_search_wid_container .flight_way_btngrp .active_way {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    background: transparent;
    border-radius: 15px;
    padding: 6px 15px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    margin-right: 20px;
    height: 30px;
  }
  
  .roundText{
    cursor: pointer;
    font-family: var(--font_family);
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--font_color);
  }
  
  .flight_search_wid_container .flight_way_btngrp .button_style {
    border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-right: 20px;
    height: 30px;
  }
  
  .flight_search_wid_container .flight_way_btngrp svg {
    transform: rotate(180deg);
    margin-left: 5px;
    margin-top: 5px;
  }
  
  .flightsearchpage_widget .flight_way_btngrp .active_way {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    background: #ebf2ff;
    border-radius: 15px;
    padding: 6px 15px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary_color);
  }
  
  .flightsearchpage_widget .flight_way_tab {
    /* width: 1200px; */
    margin: auto;
    height: 45px;
  }
  
  .flightsearchpage_widget .flight_way_btngrp .button_style {
    border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .flightsearchpage_widget .flight_way_btngrp svg {
    /* transform: rotate(180deg); */
    margin-left: 5px;
    width: 13px !important;
  }
  
  .flight_way_btngrp .button_style:focus {
    color: var(--secondary_color);
  }
  
  .flight_way_btngrp .button_style:hover {
    color: var(--secondary_color);
  }
  
  .flight_search_details_container {
    width: 100%;
    /* width: 1200px;
      margin: auto; */
    margin-top: 10px;
  }
  
  .flight_search_details_container_mobile {
    width: 100%;
    /* width: 1200px;
      margin: auto; */
    margin-top: 10px;
    display: flex;
  }
  
  .flight_search_details_main_mobile {
    width: 100%;
  }
  
  .flight_search_details_container .flight_search_details_main {
    position: relative;
  }
  
  .flight_search_details_container
    .flight_search_details_main
    .flight_search_details_row {
    display: flex;
    position: relative;
    align-items: center;
  }
  
  .flight_widgetContainer .flight_search_depart_going_date{
    position: unset!important;
  }
  .flight_widgetContainer .flight_search_depart_going_date .close_iocn{
    left: 73%!important;
    right: auto;
  }
  
  .flight_search_details_container
    .flight_search_details_main
    .flight_search_details_row
    .flight_search_column {
    float: left;
    margin-right: 0px;
    margin-bottom: 0px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    /* height: auto; */
    border-bottom: 0;
    padding-top: 0px;
  }
  
  .flight_widgetContainer .flight_search_depart_going_date:nth-child(4) {
    border-right: 0 !important;
  }
  
  .flight_search_column.flight_search_pax:last-child {
    border-bottom: 0 !important;
  }
  
  .advance_add {
    margin-left: auto;
  }
  
  .flight_search_column {
    float: left;
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-left: 0px;
    padding-top: 10px;
    /* width: 20%;
      display: flex; */
  }
  
  /* .flight_search_column.flight_search_depart_going_date .calendar_wid_container label{
      padding-left: 22px !important;
  } */
  .flight_search_details_container
    .flight_search_details_main
    .flight_search_details_row
    .flight_swap {
    position: absolute;
    left: 17%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    width: 30px;
    border-radius: 50%;
  }
  
  .flight_search_details_container
    .flight_search_details_main
    .flight_search_details_row
    .flight_swap
    svg {
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
  
  .flight_search_details_row .flight_search_depart {
    width: 22%;
  }
  
  /* .flight_search_depart::before{
      content: '';
      width: 20px;
      height: 10px;
      background: #fff;
      position: absolute;
      bottom: -15px;
      right: 50%;
      transform: translate(-50%, -50%);
      border-left: 50px solid white;
      border-right: 50px solid white;
      z-index: 999;
      border: 1px solid var(--secondary_color);
  } */
  .flight_search_details_row .flight_search_depart_date {
    width: 17%;
  }
  
  .flight_search_details_row .flight_search_depart_going_date {
    /* width: 17%; */
    width: 50% !important; 
    cursor: default;
    /* padding: 0 34px !important; */
    padding: 0 15px !important;
    padding-right: 15px !important;
    border-right: 1px solid #dedede;
    border-left: 1px solid #dedede;
  }
  .desktop_div.flight_search_details_row .flight_search_depart_going_date{
    width: 50% !important; 
    cursor: pointer;
    padding: 0 10px !important;
    /* background: red; */
  }
  
  .flight_search_pax {
    height: 35px !important;
    display: flex !important;
    width: 100%;
    justify-content: space-between;
  }
  
  /* .flight_search_pax .flight_search_wid_inputfield{
      align-items: center;
      line-height: normal;
      letter-spacing: normal;
      display: flex;
      flex-wrap: wrap;   
  } */
  .flight_search_details_row .flight_search_pax {
    width: 100%;
    font-family: var(--font_family);
    padding-left: 30px;
    padding-top: 3px;
    padding-right: 15px;
  }
  
  .flight_search_details_row .flight_search_class {
    width: 20%;
    display: flex;
  }
  
  .flight_search_details_row .flight_search_widget_btn {
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 140px;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
  }
  
  .flight_search_details_row .flight_search_widget_btn:hover,
  .flight_search_details_row .flight_search_widget_btn:focus {
    /* box-shadow: 0 0 0 0.25rem rgb(37 99 128 / 50%) !important; */
    box-shadow: 1px 1px 10px var(--buttons_color);
    transition: transform 0.3s ease;
  }
  
  .flight_search_details_container .flight_search_wid_inputfield {
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
  }
  
  .flightsearchpage_widget_flex .flight_search_wid_inputfield {
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    /* display: flex; */
    /* display: flex; */
    /* flex-wrap: wrap;    */
  }
  
  .flight_search_left_icon svg path {
    fill: var(--primary_color);
  }
  
  .flight_search_divpax {
    display: flex;
    width: 35%;
  }
  
  .flight_search_divpax small {
    padding: 0 5px;
  }
  
  .flight_search_divpax small svg {
    position: unset !important;
  }
  
  /* .flight_search_wid_inputfield .disable_pax_btn{
      opacity: 0.5;
  } */
  .flight_search_wid_inputfield > div svg {
    position: inherit !important;
    color: #fff;
    margin-right: 0px !important;
    /* border-radius: 20px; */
    width: 20px;
    /* height: 27px; */
  }
  .flight_autocomplete_element:hover .fltg_icon svg path, 
.flight_autocomplete_element:focus .fltg_icon svg path {
    fill: #fff;
}
  .suggestion_main_container {
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    border-top: 0;
    margin-right: 11px;
  }
  .suggestion_main_container::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  
  .suggestion_main_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
  }
  
  .suggestion_main_container::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 10px;
  }
  .disable_pax_name svg {
    position: inherit !important;
  }
  
  /* .flight_search_wid_inputfield .add_pax{
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      background-color: transparent;
      border: 0;
  }
  
  .flight_search_wid_inputfield .minus_pax{
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      background-color: transparent;
      border: 0;
  } */
  
  .flight_search_wid_inputfield .disbale_traveller {
    opacity: 0.5;
  }
  
  .flight_search_wid_inputfield .flight_search_wid_inputfield_main {
    display: flex;
    flex-wrap: wrap;
  }
  
  .flight_search_details_main .flight_search_details_row:first-child label {
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
    padding-bottom: 10px;
    user-select: none;
  }
  
  .flight_search_paxcount {
    display: flex;
    float: left;
    margin-right: 3px;
    position: relative;
    padding: 10px 10px 10px 0;
    width: 100%;
  }
  
  .flight_search_paxcount label {
    padding-left: 15px !important;
  }
  
  .flight_search_paxcount span {
    font-family: var(--font_family);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 5px;
  }
  
  .flight_search_wid_inputfield .flight_search_wid_inputfield_main {
    display: flex;
    flex-wrap: wrap;
  }
  
  .flight_search_details_main .flight_search_details_row:first-child label {
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
    padding-bottom: 10px;
  }
  
  .flight_search_paxcount {
    display: flex;
    float: left;
    margin-right: 3px;
    position: relative;
    padding: 10px 10px 10px 0;
    width: 100%;
  }
  
  .flight_search_paxcount label {
    padding-left: 15px !important;
  }
  
  .flight_search_paxcount span {
    font-family: var(--font_family);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 5px;
  }
  
  .flight_search_details_main .flight_search_details_row:first-child > div {
    /* padding-left: 2px;
      padding-top: 0px; */
    position: relative;
    padding-top: 5px !important;
    padding-bottom: 5px;
  }
  
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_class
    svg {
    position: absolute;
    left: 0;
    top: 39px;
    width: 20px;
    height: 20px;
  }
  
  .flight_search_details_main .flight_search_details_row:first-child > div svg {
    /* position: absolute;
      left: 14px;
      top: 11px; */
    width: auto;
    height: 20px;
    object-fit: scale-down;
    /* margin-right: 5px; */
  }
  
  .flight_search_details_main
    .flight_search_details_row:first-child
    > div
    svg
    path {
    /* fill: #7f747c; */
  }
  
  .flights_search_main {
    position: initial !important;
    margin-top: 28px;
  }

  .desktop_div > .flight_search_column:first-child {
    border-right: 1px solid transparent;
  }
  .flights_search_main svg {
    position: absolute !important;
    left: unset !important;
    right: 10px;
    width: 25px !important;
    height: 25px !important;
    top: 17px !important;
  }
  
  /* .flight_search_wid_inputfield svg{
      color: #fff;
      background: var(--secondary_color);
      border-radius: 20px;
  } */
  .Flt_tab_dsk {
    color: var(--font_color);
    position: absolute;
    top: -50px;
    left: 0;
    padding: 15px 40px;
    background: #fff;
    border-radius: 10px 10px 0 0;
    display: flex;
    gap: 3px;
  }
  
  .Flt_tab_dsk img {
    width: 20px;
    display: inline;
    margin-right: 5px;
  }
  
  .flight_search_details_main .flight_search_details_row:first-child > div input {
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    border: none;
    padding: 0 0px 0 0 !important;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    /* background: transparent; */
  }
  .flight_search_details_main .flight_search_details_row .flight_InputVal .search_widget_input_new {
    font-weight: normal !important;
  }
  .flight_search_details_main .flight_search_details_row .flight_InputVal .search_widget_input_new::placeholder {
    font-weight: normal !important;
  }
  .flight_search_details_main
    .flight_search_details_row:first-child
    > div
    input:focus {
    color: var(--font_color);
    /* opacity: .6; */
  }
  
  .flightModify_wrap .flight_swapOnewayAdv {
    position: absolute;
    left: 17.5%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #def1fa;
    width: 45px;
    border-radius: 50px;
  }
  
  .flightModify_wrap .flight_swapOnewayAdv svg {
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
  
  .flightModify_wrap .flight_swap_round {
    position: absolute;
    left: 13.2%;
    z-index: 6;
    top: 50px;
    width: 45px;
    cursor: pointer;
    background: #def1fa;
    border-radius: 50px;
  }
  
  .flightModify_wrap .flight_swap_round svg {
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
  
  .flightModify_wrap .flight_swapAdv {
    position: absolute;
    left: 10%;
    z-index: 6;
    top: 58px;
    width: 45px;
    cursor: pointer;
    background: #def1fa;
  }
  
  .flightModify_wrap .flight_swapAdv svg {
    margin: auto;
    width: 33px;
    height: 33px;
  }
  
  .flight_search_details_main
    .flight_search_details_row:first-child
    > div
    input::placeholder {
    font-size: 15px;
    color: var(--font_color);
    font-weight: normal;
  }
  
  /* .flight_autocomplete_value{
      position: absolute;
      width: 100%;
      max-height: 50px;
      z-index: -2;
      top: 60px;
      background-color: rgb(175, 78, 78);
      box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2);
      overflow: auto;
      color: var(--font_color);
      font-size: 12px;
      left: 0;
  } */
  .flights_city_main svg {
    left: 16px !important;
    width: 38px !important;
    height: 30px !important;
  }
  
  .flights_city_main svg:hover {
    fill: #ffffff;
  }
  
  .flight_autocomplete_element {
    width: auto;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px 9px;
    cursor: pointer;
    gap: 5px;
    border-radius: 5px;
  }
  
  .flight_autocomplete_element:hover,
  .flight_autocomplete_element:focus {
    background: var(--primary_color);
  }
  
  .flight_autocomplete_element:hover,
  .flight_autocomplete_element:focus {
    background: var(--primary_color);
  }
  
  .flight_autocomplete_element:hover .flight_autocomplete_name,
  .flight_autocomplete_element:focus .flight_autocomplete_name {
    color: #fff;
  }
  
  .flight_autocomplete_element:hover .flight_autocomplete_name span,
  .flight_autocomplete_element:focus .flight_autocomplete_name span {
    color: #fff;
  }
  
  .flight_autocomplete_element:hover .plane_img path,
  .flight_autocomplete_element:focus .plane_img path {
    fill: #fff !important;
  }
  
  .flight_autocomplete_element:hover .flight_autocomplete_tag,
  .flight_autocomplete_element:focus .flight_autocomplete_tag {
    color: #fff !important;
  }
  
  .flight_autocomplete_element:hover .flight_country,
  .flight_autocomplete_element:focus .flight_country {
    color: #fff !important;
  }
  
  .flight_autocomplete_tag {
    padding: 3px 5px;
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  
  .flight_autocomplete_name {
    width: 100%;
    color: #000;
    font-family: var(--font_family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .flight_cityCountTitle {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
  }
  
  .search_widget_input_new {
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0;
    padding-left: 5px;
    font-weight: bold;
    font-size: 17px;
    color: var(--primary_color);
  }
  
  .search_widget_input_new::placeholder {
    font-size: 17px !important;
    font-weight: bold !important;
    color: var(--secondary_color) !important;
  }
  
  .search_widget_input_new:focus {
    box-shadow: none;
    border: none;
  }

  .flight_InputVal input:focus {
    box-shadow: none;
    border: none;
    color: var(--font_color);
    opacity: 1;
  }
  
  .flightmaintops:first-child .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    border: none;
    padding: 0 10px 0 0;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    background: transparent;
  }
  
  .flight_search_left_icon {
    cursor: pointer;
    /* background-color: red; */
    float: left;
    position: absolute;
    left: 0;
    top: 28px;
  }
  
  .flight_search_details_container .flight_search_left_icon svg {
    display: block;
  }
  
  .flight_search_details_container .flight_search_left_icon svg path {
    fill: var(--primary_color) !important;
  }
  
  .flight_search_class svg path {
    fill: var(--primary_color) !important;
  }
  
  .flight_search_details_row ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  /* Track */
  .flight_search_details_row ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .flight_search_details_row ::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 10px;
  }
  
  .flight_search_pax span {
    margin-bottom: 10px;
    font-family: var(--font_family);
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0px;
    display: inline-block;
    color: var(--font_color);
  }
  
  .flight_search_pax label {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0px;
    display: block;
    color: var(--font_color);
    margin-right: 3px;
  }
  
  .flight_search_pax .flight_search_wid_inputfield {
    display: flex;
    justify-content: space-between;
    /* margin-left: -15px; */
    margin-top: -4px;
    width: 35%;
    user-select: none;
  }
  
  .flight_search_pax .flight_search_wid_inputfield span {
    margin-bottom: 0;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 4px 4px;
    position: relative;
    height: 30px;
    text-align: center;
    border: none;
  }
  
  .flight_cabin_class_cont {
    display: flex;
    flex-direction: column;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: left;
    color: var(--font_color);
    padding: 0 0 0px 0;
    cursor: pointer;
    /* width: 100%; */
    padding-left: 0px;
    padding-top: 0px;
  }
  
  .flight_trav_title {
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    /* text-transform: capitalize; */
    padding-bottom: 10px;
  }
  
  .flight_cabin_class_name {
    /* flex: 1 1; */
    margin-top: 0px;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    padding-top: 0px;
  }
  
  .flight_cabin_class_name.cabin_pd {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  
  .flt_trav_label {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    margin-top: 2px;
    display: none;
  }
  
  .flight_cabin_class_cont img {
    max-width: 25px;
    margin: 0 17px 0 8px;
    height: auto;
    object-fit: contain;
  }
  
  .flight_cabin_class_list {
    position: absolute;
    width: 190px;
    border: 1px #e2e0e0 solid;
    top: 70px;
    left: 0px;
    border-top: none;
    background: #ffffff;
    z-index: 1;
  }
  
  .flight_cabin_class_ele {
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    padding: 10px 10px;
    margin: 0px 0px;
    display: block;
    padding: 10px 0px;
    padding-left: 0 !important;
    margin: 0px 10px;
    display: block;
    width: auto;
    border-bottom: 1px solid #eaeaea;
  }
  
  .flight_cabin_class_ele:last-child {
    border: none;
  }
  
  .flight_cabin_class_ele:hover {
    /* background-color: var(--primary_color);
      color: #ffffff !important; */
    outline: none;
  }
  
  .flight_cabin_class_name {
    /* flex: 1 1; */
    /* margin-top: 13px; */
  }
  
  .flight_cabin_class_icon_img {
    height: 28px;
    width: 28px;
    color: var(--primary_color);
    margin: 0 12px 0 8px;
  }
  
  .minus_pax,
  .add_pax {
    height: 23px;
    width: 23px;
    color: var(--secondary_color);
    cursor: pointer;
  }
  
  .flight_cabin_class_arrow svg {
    height: 15px;
    width: 15px;
    margin: 0 10px;
    color: var(--primary_color);
  }
  
  .flightautocomplete_main .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    /* padding: 0px 0px 10px 1px; */
    width: 100%;
    /* height: 30px; */
    background: none;
  }
  
  .flight_autocomplete {
    width: 15%;
  }
  
  .flightsearchpage_widget {
    padding: 20px 40px;
    /* margin: 0px 0px 10px 0px;     */
    /* background: #005a94; */
    background: #def1fa;
    /* border-radius: 15px; */
    margin-top: 0px;
  }
  
  .flightsearchpage_widget_flex {
    display: flex;
    position: relative;
    margin-top: 0px !important;
    position: relative;
    /* max-width: 1200px;
      margin: auto; */
  }
  
  .flightsearchpage_widget_flex label {
    font-family: var(--font_family);
    font-size: 14px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: var(--font_color);
    margin: 0px 0px 10px 0px;
  }
  
  .flightautocompletetraveller.passenger label {
    color: var(--primary_color);
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 90%;
    overflow: hidden;
  }
  
  .flightautocomplete {
  }
  
  .flightmaintops {
    display: flex;
    margin-top: 20px !important;
    position: relative;
    /* max-width: 1200px;
      margin: auto; */
    align-items: flex-end;
  }
  
  .flight_swap {
    position: absolute;
    left: 192px;
    z-index: 6;
    top: 47px;
    width: 30px;
    cursor: pointer;
    /* background: white; */
  }
  
  .flight_swap_round {
    position: absolute;
    left: 13%;
    z-index: 6;
    top: 50px;
    border-radius: 50px;
    /* width: 30px; */
    cursor: pointer;
    background: #fff;
    padding: 0 5px;
  }
  
  .flight_swap_round svg {
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
  
  .flightModify_wrap .flight_swap {
    position: absolute;
    left: 15%;
    z-index: 6;
    top: 47px;
    width: 30px;
    cursor: pointer;
    background: #def1fa;
  }
  
  .flightModify_wrap .flight_swap svg {
    fill: var(--primary_color);
  }
  
  .flightautocomplete_main {
    width: 19%;
    margin-right: 20px;
    /* background: #ffffff; */
    /* border-radius: 15px; */
    position: relative;
    display: flex;
    /* padding: 10px; */
    border-bottom: 2px solid #b9d4e1;
    /* border-radius: 15px; */
    height: 60px;
    margin-left: 24px;
  }
  
  .flightautocomplete_main_new {
    width: 19%;
    margin-right: 30px;
    position: relative;
    display: flex;
    /* padding: 10px; */
    border-bottom: 2px solid #b9d4e1;
    height: 60px;
  }
  
  .flightautocompletecalender {
    width: 19%;
    margin-right: 20px;
    display: block;
    /* background: #fffff; */
    /* border-radius: 15px; */
    /* padding: 0px 10px; */
    position: relative;
    border-bottom: 2px solid #b9d4e1;
    height: 60px;
  }
  
  .return_calendar input {
    /* color: var(--font_color) !important; */
    outline: none;
  }
  
  .flightautocompletetraveller {
    width: 100%;
    /* background: #ffffff; */
    padding: 6px 2px 6px 2px;
    /* border-radius: 15px 15px 0px 0px; */
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #b9d4e1;
  }
  
  .flightautocompletetraveller label {
    margin: 0px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .flightautocompletetraveller input {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--font_color);
    border: none;
    padding-left: 10px;
    width: 100%;
    height: 30px;
    background: none;
    cursor: pointer;
  }
  
  .flightautocompletetraveller.passenger input {
    width: 10%;
    color: var(--primary_color);
    padding-right: 0;
  }
  
  .flightautocompletecalender input {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    /* padding: 0px 0px 10px 1px; */
    width: 100%;
    height: 30px;
    background: none;
    cursor: pointer;
  }
  
  .dropPsg_icon {
    fill: var(--secondary_color);
    margin-left: auto;
  }
  
  .flightModify_wrap {
    max-width: 1270px;
    margin: auto;
    padding: 0 1rem;
  }
  
  .flightautocompletecalender .calendar_wid_main span {
    display: block;
    /* margin-left: 10px; */
    margin-top: 0px;
    cursor: pointer;
  }
  
  /* .flightautocompletecalender .calendar_wid_main{
      margin-top: 5px;
  } */
  .calendar_wid_main svg path {
    fill: var(--primary_color);
  }
  
  .flightautocompletetraveller span svg path {
    fill: var(--primary_color);
  }
  
  .flightautocomplemodifybutton button svg path {
    fill: #fff;
  }
  
  .flightsearchpage_widget .calendar_wid_container {
    position: relative;
  }
  
  .flight_autocomplete_value {
    position: absolute;
    width: 420px;
    max-height: 450px;
    z-index: 90;
    top: 60px;
    background-color: #fff;
    box-shadow: 0 19px 22.3px 6.7px hsla(0, 0%, 60%, 0.13);
    /* overflow: auto; */
    color: var(--primary_color);
    font-size: 12px;
    left: 0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    /* overflow-y: scroll; */
  }
  
  .flight_autocomplete_value::-webkit-scrollbar {
    width: 5px;
    /* background: #a4a4a4; */
    border-radius: 20px;
  }
  
  .flight_autocomplete_value::-webkit-scrollbar-thumb {
    background: #cdccce;
    width: 5px;
    height: 50%;
    border-radius: 20px;
  }
  
  .flighttraveller_drop {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    background-color: #ffffff;
    width: 235px;
    z-index: 9;
    top: 47px;
    right: 0;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 5px 5px;
  }
  
  .flighttraveller_drop_flex {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    padding: 10px 10px 10px 10px;
    /* height: 50px; */
  }
  
  .flighttraveller_drop_adult {
    display: flex;
    flex-direction: column;
    width: 65%;
  }
  
  .flighttraveller_drop_paxconfig {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-end;
    /* border-bottom: 1px #dcd8d8 solid; */
  }
  
  .flighttraveller_drop_paxconfig .flight_search_wid_inputfield {
    align-items: center;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .flighttraveller_drop_economy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
    margin-top: 30px;
    /* height: 50px; */
    position: relative;
  }
  
  .flighttraveller_drop
    .flighttraveller_drop_flex
    .flighttraveller_drop_adult
    label
    b {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
  }
  
  .flighttraveller_drop
    .flighttraveller_drop_flex
    .flighttraveller_drop_adult
    label {
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--font_color);
    margin: 0px 0px;
  }
  
  .flighttraveller_drop_done {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flighttraveller_drop_done button {
    width: 50% !important;
    /* min-height: 40px; */
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    border: 1px solid var(--buttons_color);
    margin-top: 15px;
    cursor: pointer;
    height: auto !important;
    padding: 15px 0;
    margin-bottom: 15px;
    border-radius: 5px !important;
  }
  
  .flightautocomplemodify {
    width: 30%;
    margin-right: 10px;
    /* height: 68px; */
  }
  
  .flightautocomplemodifyn {
    width: 100%;
    margin-right: 10px;
    height: 65px;
    margin-top: 1px;
  }
  
  .flightautocomplemodify button {
    background-color: var(--buttons_color);
    border-radius: 10px;
    padding: 7px 30px;
    color: #fff;
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
  }
  
  .flightautocomplemodifybutton {
    width: 10%;
    margin-left: 5px;
  }
  
  .flightautocomplemodifybutton button {
    cursor: pointer;
    /* border-radius: 5px; */
    height: 45px;
    width: 100%;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    border-radius: 25px;
    text-align: center;
    background-color: var(--buttons_color);
    color: #fff;
    /* border: 1px solid var(--buttons_color); */
    /* border-radius: 0px 0px 15px 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 35px;
  }
  
  .flightsearchpage_widget_flex .flight_search_error_message {
    position: absolute;
    bottom: 0px;
    left: 48px;
    left: 0;
    bottom: -20px;
  }
  
  .flightsearchpage_widget_flex .flight_search_error_message span {
    font-size: 11px;
    color: #e8664e;
    margin-top: 10px;
  }
  
  .calendar_wid_container .calendar_wid_main > span img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 32px;
    height: auto;
    /* color: var(--primary_color); */
  }
  
  .flight_ptext p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #383838; */
    color: var(--primary_color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }
  
  .flight_search_input {
    width: 100%;
  }
  
  .flight_cabin_class_arrow {
    position: relative;
  }
  
  .flight_cabin_class_arrow svg {
    position: absolute;
    left: 92px !important;
    top: -22px !important;
    width: 20px;
    height: 20px;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    color: var(--primary_color);
  }
  
  .flight_search_class_new {
    /* width: 19%; */
    padding-left: 0px !important;
    padding-top: 0px !important;
    /* position: absolute !important;
      bottom: -80px;
      right: 0px; */
  }
  
  .flight_search_class_new .flight_search_widget_btn {
    border-radius: 40px;
  }
  
  .flight_search_pax_select_main {
    display: block;
    position: absolute;
    top: 55px;
    left: -88px;
    right: 0;
    z-index: 9;
    /* padding: 20px 20px;
      padding-left: 25px; */
    background: #ffffff;
    width: 431px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d4d4d4;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .flight_cabin_class_arrow_pass {
    position: relative;
    width: 100%;
  }
  
  .flight_pax_title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: var(--primary_color);
  }
  
  .flight_search_Pax_main .flight_search_pax label {
    padding-left: 0 !important;
  }
  
  .flight_search_Pax_main .cabin_class_list_them input {
    margin-left: 0;
    height: 20px;
    width: 20px !important;
  }
  
  .flight_cabin_bxd {
    display: flex;
    align-items: center;
    /* margin-bottom: 9px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e0e0e0; */
    width: 50%;
  }
  
  .flight_cabin_bxd label {
    padding-left: 21px !important;
    padding-top: 0px;
    font-size: 14px;
  }
  
  .class_div {
    width: 100%;
  }
  
  .flight_cabin_class_arrow_pass svg {
    position: absolute;
    left: 25px !important;
    top: -5px !important;
    width: 20px;
    height: 20px;
    object-fit: scale-down;
    color: var(--primary_color);
  }
  
  .flight_search_leftpaxcount {
    width: 65%;
    display: flex;
  }
  
  .flight_search_pax_select_main .flight_search_pax {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 8px;
    /* border-bottom: 1px solid #e0e0e0; */
    height: auto !important;
    padding-bottom: 15px;
  }
  
  .flight_search_pax_select_main .flight_search_pax:nth-child(4) {
    border-bottom: transparent;
  }
  
  .flight_search_pax_newleft {
    display: flex;
    flex-direction: column;
  }
  
  .newcontainer {
    width: 100%;
    padding: 10px;
  }
  
  .pax_btn_thme {
    text-align: center;
  }
  
  .pax_btn_thme button {
    background-color: var(--buttons_color);
    border-radius: 10px;
    padding: 9px 30px;
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  
  .flight_cabin_class_list_them {
    /* margin-top: 10px;
      margin-bottom: 20px; */
    display: flex;
    flex-wrap: wrap;
  }
  
  /* .flight_cabin_class_list_them input:focus{
      opacity: inherit;
  } */
  
  .pax_input {
    border: 1px solid #ddd !important;
    margin-bottom: 0;
    margin-left: 0;
    width: 20px !important;
    height: 20px !important;
    /* background: red; */
  }
  
  .flight_cabin_bxd .pax_input {
    width: 15px !important;
    height: 15px !important;
  }
  
  .cabin_class_sec {
    margin-top: 30px;
    display: inline-block;
  }
  
  .flighttraveller_drop_done {
    text-align: center;
  }
  
  .plane_img {
    background: none !important;
    color: var(--primary_color) !important;
    margin-left: 0px;
    fill: var(--primary_color);
  }
  
  .flight_autocomplete_value .arrow_wiget {
    position: absolute !important;
    top: -10px;
    left: 30%;
    background: none !important;
    width: 15px;
    height: 15px;
  }
  
  .flights_city_parent {
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 0px 0px 5px 5px;
    /* box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15); */
    /* border: solid 1px #d4d4d4 !important; */
    background-color: #fff;
    border-top: 0;
    margin-right: 11px;
    /* margin-top: 10px; */
  }
  
  .flights_city_parent::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  
  /* Track */
  .flights_city_parent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .flights_city_parent::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 10px;
  }
  
  .buttons_icon {
    padding: 3px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
  }
  .buttons_icon_disbale {
    opacity: 0.5;
  }
  
  .flight_search_column .flight_search_Pax_main .arrow_pass {
    position: absolute !important;
    top: -13px !important;
    left: 55% !important;
  }
  
  .pass_arrow_mod {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -13px;
    left: 60%;
  }
  
  .flight_search_details_row_multicity {
    display: flex;
    justify-content: flex-end;
  }
  
  .flight_search_details_row_multicity .flight_search_widget_btn {
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 5px;
    width: 200px;
    height: 60px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
  }
  
  .flightsearchpage_widget_flex_multicity {
    display: flex;
    width: 50%;
    gap: 10px;
  }
  
  .flightautocomplete_main_multicity {
    width: 50%;
    /* margin-right: 35px; */
    display: flex;
    /* background-color: #fff; */
    /* border-bottom: 1px #dcd8d8 solid; */
    position: relative;
    /* padding: 10px; */
    border-bottom: 2px solid #b9d4e1;
    height: 65px;
  }
  
  .flightautocomplete_left {
    margin-right: 35px;
  }
  
  .flightautocomplete_main_multicity .search_widget_input_new {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    padding: 12px 5px 0 34px;
    width: 100%;
    /* height: 30px; */
    background: none;
    padding: 0;
    padding-left: 5px;
  }
  
  .flightautocompletecalender_multicity {
    width: 50%;
    margin-right: 10px;
    /* background-color: #fff; */
    border-bottom: 2px #b9d4e1 solid;
    display: flex;
    position: relative;
    height: 65px;
  }
  
  .flightautocompletecalender_multicity input {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_color);
    border: none;
    padding: 0px 0px 0px 1px;
    width: 100%;
    height: 30px;
    background: none;
    cursor: pointer;
  }
  
  .flightautocompletecalender_multicity .calendar_wid_main span {
    display: block;
    margin-right: 10px;
    /* margin-top: -10px; */
    cursor: pointer;
  }
  
  .flightautocompletecalender_multicity .calendar_wid_main {
    margin-top: 2px;
    padding-top: 0;
  }
  
  .label_text label {
    color: #000;
    font-weight: 700;
  }
  
  .label_text span {
    padding: 0 !important;
    font-weight: 400 !important;
  }
  
  .flight_search_pax_multicity_modify {
    width: 23%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding-left: 0 !important;
    border-bottom: 1px solid #dcd8d8;
    height: 100%;
  }
  
  .flightsearchpage_widget_multicity {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
    display: flex;
    position: relative;
    /* width: 1200px;
    margin: auto; */
    margin-top: 20px;
  }
  
  .flight_search_pax_multicity_modify span {
    padding: 0 25px;
    font-weight: 700;
    stroke: 0.3px black;
  }
  
  .flight_search_pax_multicity_modify .minus_pax,
  .add_pax {
    color: grey !important;
  }
  
  .flight_search_class_modify_multicity {
    width: 25%;
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcd8d8;
    position: relative;
  }
  
  .flightsearchpage_widget_flex_multicity_non_rep {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 60px;
  }
  
  .flightsearchpage_widget_flex_multicity_non_rep .flightautocomplemodify {
    width: 30%;
  }
  
  .modifySearchWidget_divider {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .flightsearchpage_widget_flex_multicity_modify {
    display: flex;
    width: 100%;
    gap: 10px;
    position: relative;
  }
  
  .remove_flight_button {
    /* position: absolute;
      right: -30px;
      top: 50%;
      transform: translate(-50%, -50%); */
    position: relative;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  
  .flight_search_classMulticity {
    margin-top: 18px;
    width: 20%;
  }
  
  .flight_search_classMulticity svg {
    position: absolute;
    top: 39px;
  }
  
  .flight_search_classMulticity svg path {
    fill: var(--primary_color) !important;
  }
  
  .search_button_section {
    position: absolute !important;
    right: 0;
    /* height: 40px; */
    bottom: 50px;
    border: 1px solid var(--secondary_color);
    border: 0;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .flight_swap_multicity {
    position: absolute;
    left: 27.5%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    width: 45px;
    border-radius: 50px;
  }
  
  .flight_swap_multicity svg {
    width: 35px !important;
    height: 35px !important;
    margin: auto;
  }
  
  .flight_swap_multicity svg path {
    fill: var(--primary_color) !important;
  }
  
  .search_button_section {
    /* width: 40%; */
    float: right;
    margin-top: 100px;
  }
  
  .flightBtobflx {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  
  .search_button_section button {
    color: var(--secondary_color);
    font-family: var(--font_family);
    font-weight: 600;
    font-size: 14px;
  }
  
  .flight_swap_modify {
    position: absolute;
    left: 102%;
    z-index: 2;
    top: 46px;
    cursor: pointer;
    color: var(--primary_color);
    /* background-color: #fff; */
    border-radius: 50%;
    border-color: var(--secondary_color);
  }
  
  .flight_swap_modify svg {
    fill: var(--primary_color);
  }
  
  .flightautocompletecalender_multicity .calendar_wid_container {
    /* padding: 10px; */
  }
  
  .flightsearchpage_widget_multicity .search_button_section {
    position: absolute !important;
    right: 0;
    /* height: 40px; */
    bottom: 0;
    /* border: 1px solid var(--secondary_color); */
    /* border-radius: 10px; */
    /* padding: 5px 10px; */
    cursor: pointer;
  }
  
  .flightsearchpage_widget_multicity .search_button_section button {
    color: var(--secondary_color);
    font-family: var(--font_family);
    font-weight: 500;
    font-size: 14px;
  }
  
  .flightsearchpage_widget_flex_multicity_modify
    .flightautocomplete_main_multicity
    .flight_search_wid_inputfield {
    /* display: flex; */
    align-items: center;
  }
  
  .flightsearchpage_widget_flex_multicity_modify .remove_flight_button svg {
    color: var(--primary_color);
  }
  
  .dropdown_arrowModify {
    position: absolute;
    right: -4.5%;
    top: 20px;
  }
  
  .dropdown_arrowModify svg {
    fill: #fff;
  }
  
  .dropArrow_mod {
    fill: var(--primary_color);
    margin-left: auto;
  }
  
  /* ====================== Desktop cal Style ====================== */
  
  .calendar_wid_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .calendar_wid_container .calendar_wid_main {
    /* align-items: center; */
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
    cursor: pointer;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 5px;
  }
  .calendar_wid_main span svg {
    width: 20px;
    height: 20px;
}
  
  .calendar_wid_container .calendar_container {
    position: absolute;
    width: 625px;
    margin-top: 0px;
    top: 65px;
    left: 0;
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--primary_color);
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/9%);
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding: 0px 0px;
  }
  
  .calendar_wid_container .calendar_container.left {
    left: 0 !important;
  }
  
  .calendar_wid_container .calendar_container.calendar_container_single {
    position: absolute;
    width: 320px;
    top: 65px;
    left: 50%;
    z-index: 10 !important;
    background-color: #fff;
  }
  
  .calendar_container .calendar_main {
    padding: 10px 10px;
    padding-top: 0px;
    display: flex;
    flex-wrap: nowrap;
    gap: 25px;
  }
  
  .calendar_container .calendar_main .calendar_head_left_side {
    position: absolute;
    cursor: pointer;
    left: 20px;
    top: 2px;
  }
  
  .calendar_container .calendar_main .calendar_head_right_side {
    position: absolute;
    cursor: pointer;
    right: 40px;
    top: 2px;
  }
  
  .calendar_main .calendar_whole_body {
    width: 100%;
    box-sizing: content-box;
  }
  
  .calendar_main .calendar_head {
    display: flex;
    width: 100%;
  }
  
  .calendar_head .calendar_head_left_side {
    text-align: left;
    width: 10%;
  }
  
  .calendar_head .calendar_head_center_side {
    padding-bottom: 15px;
    width: 100%;
    padding-top: 15px;
  }
  
  .calendar_head .calendar_head_center_side h3 {
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--secondary_color);
    user-select: none;
  }
  
  .calendar_head .calendar_head_right_side {
    text-align: right;
    width: 10%;
  }
  
  .calendar_head .calendar_head_icon {
    position: relative;
    left: 0;
    top: 0;
  }
  
  .calendar_body .calendar_week_heads {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .calendar_week_heads .coln_center_week {
    padding: 5px 0;
    font-size: 12px;
    width: calc(100% / 7);
    font-family: var(--font_family);
    text-transform: capitalize;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--font_color);
  }
  
  .calendar_body .calendar_day_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .calendar_body .calendar_day_list .day_cell_center {
    text-decoration: none;
    border-radius: 5px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 40px;
    font-family: var(--font_family);
    margin: 3px 0px 3px 4px;
    font-size: 14px;
    width: calc(100% / 7);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #dbdbdc; */
  }
  
  .calendar_body .calendar_day_list .day_cell_center {
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    transition: color 0.3s, border 0.3s;
    cursor: pointer;
    height: 40px;
    font-family: var(--font_family);
    margin: 3px 0;
    font-size: 14px;
    width: calc(100% / 7);
  
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .calendar_body .calendar_day_list .day_cell_center > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-family: var(--font_family);
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: normal;
    color: var(--secondary_color);
  }
  
  .day_cell_center.selected_calendar_date span {
    color: #fff !important;
    font-weight: bold !important;
  }
  
  .calendar_body {
    /* padding: 0 10px; */
  }
  
  .calendar_body .calendar_day_list .day_cell_center .calendar_day_disable,
  .calendar_body
    .calendar_day_list
    .day_cell_center:hover
    > span.calendar_day_disable
    .calendar_body
    .calendar_day_list
    .day_cell_center:focus
    > span.calendar_day_disable {
    cursor: not-allowed;
    color: #999 !important;
    background-color: transparent !important;
    opacity: 0.5;
  }
  
  .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date,
  .calendar_body .calendar_day_list .day_cell_center:hover > span,
  .calendar_body .calendar_day_list .day_cell_center:focus > span {
    border-radius: 5px;
    color: #fff;
    background-color: var(--primary_color);
  }
  
  .calendar_head_icon svg {
    position: relative !important;
    /* top: unset !important;
      right: unset !important;
      color: var(--secondary_color) !important; */
    width: 30px;
    height: 30px;
    object-fit: scale-down;
    color: #fff;
    position: relative;
    top: 7px;
    left: 10px;
  }
  
  .button_style label {
    padding-left: 1.5em;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    position: relative;
    cursor: pointer;
    padding-top: 3px;
  }
  
  .active_way input,
  .button_style input {
    position: absolute;
    z-index: 5;
    opacity: 0;
    padding: 0 !important;
  }
  
  .active_way input[type="radio"] + label {
    font-weight: 600;
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .active_way input[type="radio"]:checked + label:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em var(--secondary_color);
  }
  
  .active_way input[type="radio"] + label:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
  }
  
  .button_style input[type="radio"] + label:before,
  .button_style input[type="radio"] + label:after,
  .active_way input[type="radio"] + label:before,
  .active_way input[type="radio"] + label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 21px;
    height: 21px;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 1px solid var(--secondary_color);
  }
  
  .button_style input[type="radio"] + label:after {
    border: 1px solid var(--font_color);
  }
  
  /* .flight_search_wid_container:before {
      content: "";
      display: block;
      position: absolute;
      top: -15px;
      left: 55px;
      border-color: transparent transparent #fff #fff;
      border-style: solid;
      border-width: 15px;
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -2px 2px 2px rgba(57,73,76,.2);
  } */
  
  .trav_pd0 {
    padding-left: 0;
  }
  
  .flight_cabin_class_nameAdvance {
    flex: 1 1;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: var(--primary_color);
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 100px;
    min-width: 100px;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
  
  .flight_travler {
    padding-left: 5px;
    padding-top: 0;
  }
  
  .flight_travIcon svg {
  }
  
  .flight_travIconRound {
    position: absolute;
    top: 36px;
  }
  
  .flight_travIconRound svg {
    width: 20px;
    height: 20px;
  }
  
  .flight_travIconRnd {
    position: absolute;
    top: 37px;
  }
  
  .flight_travIconRnd svg {
    min-width: 22px;
    height: 100%;
  }
  
  .flight_travlerRnd {
    padding-top: 15px;
    padding-left: 25px;
  }
  
  .flight_travIconMulti {
    position: absolute;
    top: 39px;
  }
  
  .flight_travIconMulti svg {
    /* width: 20px;
      height: 20px; */
    min-width: 22px;
    height: 100%;
    fill: var(--primary_color);
  }
  
  .flight_travel_cont {
    padding-left: 0;
  }
  
  .flight_cabin_class_arrow4 svg {
    position: relative !important;
    right: 0;
    left: -4px !important;
    top: -13px !important;
    margin-left: auto;
  }
  
  .flight_cabin_class_arrow4 svg path,
  .flight_cabin_class_icon4 svg path {
    fill: var(--primary_color);
  }
  
  .flight_search_details_main
    .flight_search_details_row:first-child
    > div
    input::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
  }
  
  .flightmaintops:first-child .search_widget_input_new::placeholder,
  .flightautocomplete_main_multicity .search_widget_input_new::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
  }
  
  .flight_search_depart_multicity,
  .flight_search_depart_going_date_multicity {
    width: 33%;
  }
  
  .flight_simple_wrap {
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 45px;
  }
  
  .flight_simpleBtn {
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 40px;
    width: 100%;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
  }
  
  .flight_swapOnewayAdv {
    position: absolute;
    left: 17.5%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: #fff;
    width: 45px;
    border-radius: 50px;
  }
  
  .flight_swapOnewayAdv svg {
    margin: auto;
    width: 33px;
    height: 33px;
    fill: var(--primary_color);
  }
  
  .flight_swapAdv {
    position: absolute;
    left: 22%;
    /* z-index: 99; */
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    /* width: 45px; */
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .flight_swapAdv svg {
    margin: auto;
    width: 37px;
    height: 36px;
    fill: var(--primary_color);
  }
  
  .flight_swapAdv svg path {
    fill: var(--primary_color);
  }
  
  .flight_search_wid_inputfield .buttons_icon {
    background: transparent !important;
    /* border: 1px solid var(--primary_color) !important; */
    width: 33px !important;
    height: 33px !important;
  }
  
  .buttons_icon path {
    fill: var(--secondary_color) !important;
  }
  
  .wd_5 {
    width: 5% !important;
  }
  
  .wd_10 {
    width: 10% !important;
  }
  
  .wd_15 {
    width: 15% !important;
  }
  
  .wd_20 {
    width: 20% !important;
  }
  
  .wd_25 {
    width: 25% !important;
  }
  
  .wd_30 {
    width: 30% !important;
  }
  
  .wd_35 {
    width: 35% !important;
  }
  
  .wd_40 {
    width: 40% !important;
  }
  
  .wd_100 {
    width: 100% !important;
  }
  
  .flightmainbots {
    margin-top: 20px;
  }
  
  .flightfare {
    width: 18%;
    border-bottom: 2px solid #b9d4e1;
  }
  
  .flightfares {
    width: 100%;
    border-bottom: 2px solid #b9d4e1;
  }
  
  .flightmainfare {
    display: flex;
    gap: 5px;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .flightmainfare span {
    /* width: 16%; */
    padding: 0;
  }
  
  .flightmainfare span svg path {
    color: var(--primary_color);
  }
  
  .flightmainfare label {
    margin: 0px;
    width: 80%;
    font-size: 14px;
    font-weight: bold !important;
    color: var(--primary_color) !important;
  }
  
  .flightfare label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
  }
  
  .flightfares label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
  }
  
  .flightmaindrops {
    display: block;
  }
  
  .flightautocomplemodifymain {
    display: block;
    width: 20%;
  }
  
  .flightautocomplemodifysfare {
    margin-top: 20px;
  }
  
  .advance_search_card_container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .traveller_button {
    width: 30px;
    height: 30px;
    padding: 5px;
    padding-top: 2px;
    line-height: 1;
    background-color: var(--primary_color);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50px;
  }
  
  .guesttext {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    text-align: left;
    color: var(--primary_color);
    margin-left: 5px;
  }
  
  .calendar_container .calendar_main .calendar_head_icon {
    /* position: absolute; */
    /* right: 0; */
    left: 10px;
    top: 0px;
    width: 100%;
  }
  
  .flight_search_details_main
    .flight_search_details_row:first-child
    > div
    .calendar_container
    .calendar_main
    .calendar_head_icon
    svg {
    width: 25px !important;
    height: 25px !important;
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0;
    bottom: 0;
  }
  
  .calendar_container .calendar_main .calendar_head_icon svg path {
    fill: var(--primary_color) !important;
  }
  
  .pointer {
    width: 20px !important;
    height: 20px;
  }
  
  .dropDownlistmain {
    background-color: #fff;
  }
  
  .maininput {
    border: none;
  }
  
  .subinput {
    margin: 0 11px 9px 10px;
    padding: 13px 57px 11px 12px;
    border: solid 1px #d6d6d6;
    background-color: #fff;
  }
  
  .dropDownlistmain {
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid #e0e0e0;
    margin-top: 4px;
    z-index: 9;
    width: 200px;
  }
  
  .dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  
  .dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100%;
    padding: 4px 15px;
    border: 1px solid #d6d6d6;
    border-radius: 50px;
    background: #fff;
  }
  
  .dropDownlistmain ul li input[type="text"]::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--font_color);
    padding-left: 3px;
  }
  
  .dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
  }
  
  .dropDownlistmain ul li span {
    margin-right: 4px;
  }
  
  .dropdown_value_container {
    max-height: 280px;
    overflow: auto;
    position: relative;
  }
  
  .adv_tabs_splitDepart .checkbox_label .bagCheckbox {
    position: relative;
    margin-left: 0 !important;
  }
  
  .checkbox_label {
    display: flex;
    cursor: pointer;
    align-items: flex-start;
    padding: 5px;
    justify-content: flex-end;
  }
  
  .checkbox_label .bagCheckbox input {
    cursor: pointer;
    height: 20px;
    opacity: 0;
    position: absolute;
    width: 20px;
    z-index: 2;
  }
  
  .checkbox_label .bagBox {
    align-items: center;
    background: #fff;
    border: 1px solid var(--secondary_color);
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 0 !important;
  }
  
  .checkbox_label .bagCheck {
    border-color: #fff;
    -o-border-image: none;
    border-image: none;
    border-style: none solid solid none;
    border-width: medium 2px 2px medium;
    box-sizing: border-box;
    height: 11px;
    opacity: 0;
    position: relative;
    top: -2px;
    left: 2px;
    transform: scale(1) rotate(45deg);
    transition-delay: 0.1s;
    width: 5px;
    z-index: 1;
    margin-left: 0;
  }
  
  .checkbox_label input[type="checkbox"]:checked ~ .bagBox {
    background: var(--secondary_color);
    border: 0;
  }
  
  .checkbox_label
    .bagCheckbox
    input[type="checkbox"]:checked
    ~ .bagBox
    .bagCheck {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
  
  .advTitle {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
    margin-left: 4px;
  }
  
  .dropdown_value_container::-webkit-scrollbar {
    width: 3px;
  }
  
  .dropdown_value_container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .dropdown_value_container::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 10px;
  }
  
  .dropdown_value_container::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  
  .departure_main_box li {
    outline: none;
  }
  
  .classTitle {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .CabinLabel {
    padding-left: 10px;
  }
  
  .CabinLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .classTitle::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 15px;
    height: 15px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease;
    border: 1px solid var(--secondary_color);
  }
  
  .CabinLabel input:checked ~ .classTitle::before {
    border-color: var(--secondary_color);
  }
  
  .CabinLabel input:checked ~ .classTitle::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 1px;
    background-color: var(--secondary_color);
    width: 13px;
    height: 13px;
    text-align: center;
    color: white;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  
  /* .classTitle:hover::before {
      border-color: #555;
    } */
  
  .CabinLabel input:disabled ~ .classTitle::before {
    background-color: #ccc;
  }
  
  .flighttraveller_drop_economy .flight_cabin_bxd input {
    color: unset !important;
  }
  
  .active_way input[type="radio"] + label {
    display: inline-block;
    transition: all 0.3s ease-in-out;
    padding-left: 1.5em;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    position: relative;
    cursor: pointer;
    padding-top: 5px;
  }
  
  .active_way input[type="radio"]:checked + label:before {
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em #fff, inset 0 0 0 1em var(--primary_color);
  }
  
  .active_way input[type="radio"] + label:after,
  .active_way input[type="radio"] + label:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 0;
    width: 17px;
    height: 17px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease;
    border: 1px solid var(--primary_color);
  }
  
  .autocomplet_wrap {
    padding: 0;
    padding-top: 0px;
    display: flex;
    align-items: center;
  }
  
  .autocomplet_wrap .flight_search_left_icon {
    position: relative;
    top: auto;
    left: auto;
  }
  
  .autocomplet_wrap .flight_search_left_icon svg {
    position: relative;
    background: none;
  }
  
  .flt_pasngrFlx {
    /* padding-top: 11px; */
    padding-left: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  
  .pasngrFlx .flight_cabin_class_name {
    display: inline-block;
    margin-right: 2px;
  }
  
  .pd_tp {
    padding-top: 12px;
  }
  
  .cabin_pd {
    padding-left: 22px;
    padding-top: 12px;
  }
  
  .multicity_fare_container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .dropdown_Flx {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
  
  .dropdown_Flx svg {
    height: auto !important;
    width: 18px;
  }
  
  .pax_main svg {
    border-radius: 20px;
  }
  
  .flight_search_error_message span {
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
  }
  
  .error_srp {
    /* margin-top: 15px; */
    position: relative;
    width: 100%;
    top: 5px;
    left: 0;
  }
  .error_desk{
    position: absolute;
    top: inherit;
    left: 0;
    bottom: -30px;
  }
  
  .error_srp span {
    font-size: 11px;
    color: #e8664e;
    line-height: 13px;
    margin-top: 10px;
    white-space: nowrap;
    z-index: 1;
    background-color: #fff;
    position: relative;
  }
  
  .dropDownlistmain ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .dropDownlistmain ul li {
    display: flex;
    align-items: center;
    padding: 0px;
    cursor: pointer;
  }
  
  .dropDownlistmain ul li input[type="text"] {
    height: 37px;
    width: 100% !important;
    padding: 4px 15px !important;
    border: 1px solid #d6d6d6 !important;
    border-radius: 50px !important;
    background: #fff !important;
  }
  
  .dropDownlistmain ul li input[type="text"]::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--font_color);
    padding-left: 3px;
  }
  
  .dropDownlistmain ul li input[type="checkbox"] {
    margin-right: 4px;
  }
  
  .fltg_icon svg {
    outline: 0;
  }
  
  .flightBtobflx > div > div > div > div:nth-child(1) {
    margin-top: 0px;
  }
  
  .adv_tabs_split {
    display: flex;
    position: relative;
  }
  
  .label_fare label {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0;
    text-transform: capitalize;
  }
  
  .icon_box_advInput {
    display: flex;
    align-items: center;
    padding-top: 3px;
  }
  
  .fare_main_box {
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-top: 10px;
  }
  
  .icon_box_advFare {
    align-self: flex-start;
    margin-top: 2px;
  }
  
  .mt_25 {
    margin-top: 25px;
  }
  
  .icon_box_advFare svg {
    width: auto !important;
    height: 20px;
  }
  
  .icon_box_advFare svg path {
    fill: var(--primary_color);
  }
  
  .flight_Adv_wrapMU {
    text-align: right;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .flight_AdvBtnMU {
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 15px;
    padding: 18px 5px;
    font-family: var(--font_family);
    font-size: 17px;
    min-width: 140px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 0;
    margin-left: 0;
    color: #fff;
    cursor: pointer;
    outline: 0;
    position: relative;
    height: 60px;
  }
  
  .calendar_wid_main input {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: var(--primary_color);
    line-height: normal;
    border: none;
    padding: 0 10px 0 0;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    background: transparent;
    padding-right: 0;
    caret-color: transparent !important;
    padding-left: 0;
  }
  .calendar_wid_main input::placeholder {
    font-family: var(--font_family);
    font-size: 14px;
    color: var(--primary_color) !important;
  }

  
  .calendar_wid_main input:focus {
    border: none;
    box-shadow: none;
  }
  
  .mt_15 {
    margin-top: 15px;
  }
  
  /* new advanced search css */
  .advance_container {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
    width: 80%;
  }
  
  .advance_container .adv_class {
  }
  
  .advance_container .departure_main_box {
    margin-right: 15px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    border-radius: 0;
    height: 70px;
    border-bottom: 2px solid #dedede;
    padding-top: 10px;
  }
  
  .advance_container .icon_Rbd_advInputnw {
    display: flex;
    gap: 5px;
    padding-top: 5px;
  }
  
  .advance_container .icon_Rbd_advInputnw .icon_box_adv svg {
    width: auto;
    height: 20px;
    object-fit: scale-down;
    margin-top: 1px;
  }
  
  .advance_container .icon_Rbd_advInputnw .icon_box_adv svg path {
    fill: var(--primary_color);
  }
  
  .advance_container .icon_Rbd_advInputnw div span {
    padding-left: 0;
  }
  
  .advance_container .adv_tabs_split input {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
    border: none;
    width: 100%;
    padding: 0;
    background: transparent;
    white-space: nowrap;
    display: inline-block;
    /* display: table-column-group; */
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
  
  .advance_container .adv_tabs_split input::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary_color);
  }
  
  .advance_container .adv_tabs_split input:focus {
    border: none;
    box-shadow: none;
  }
  
  .advance_container .cabin_cont_flx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
  
  .advance_container .cabin_flx {
    display: flex;
    align-items: center;
  }
  
  .advance_container .flight_cabin_arrowMn {
    padding: 0;
  }
  
  .advance_container .flight_cabin_class_nameAdvance {
    padding-top: 0;
  }
  
  .wd_33 {
    width: 33% !important;
  }
  
  .dropdown_container {
    width: 100%;
  }
  
  .label_nameAdv {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
  }
  
  .srpwidget {
    background: #f4fcfc;
    /* box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15); */
    background: linear-gradient(
      180deg,
      rgba(15, 91, 164, 1) 10%,
      rgba(202, 219, 235, 1) 100%
    );
    padding: 30px 0;
    padding-top: 10px;
    margin-top: 18px;
  }
  
  .srpwidget .flight_widgetContainer {
    background-color: #fff;
  }
  
  .srpwidget_wrap {
    width: 1270px !important;
    margin: 0 auto;
    padding: 1rem 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
  }
  
  .trave_flx {
    display: flex;
    align-items: center;
    padding-top: 0px;
  }
  
  .advMN_flx {
    display: flex;
    padding-top: 3px;
  }
  
  .icon_box_adv {
    margin-top: 2px;
  }
  
  .icon_box_adv svg {
    width: auto !important;
    height: 20px;
  }
  
  .icon_box_adv svg path {
    fill: var(--primary_color);
  }
  
  .advTrav_flx {
    display: flex;
    padding-top: 5px;
  }
  
  .trav_mainCont {
    position: relative;
    width: 35%;
    padding-left: 34px;
  }
  
  .flight_travIcon {
    margin-top: 0px;
  }
  
  .flight_travIcon svg {
    width: auto !important;
    height: 20px;
  }
  
  .flight_travIcon svg path {
    fill: var(--primary_color);
  }
  
  .pointer {
    width: 20px !important;
    height: 20px;
  }
  
  .adv_tabs_splitDepart .checkbox_label {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    align-items: flex-start;
  }
  
  .adv_tabs_splitDepart .checkbox_label input[type="checkbox"]:checked ~ .bagBox {
    background: var(--secondary_color);
    border: 0;
  }
  
  .adv_tabs_splitDepart .advTitle {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.38px;
    text-align: center;
    color: var(--primary_color);
  }
  
  .adv_tabs_splitDepart .advTitle {
    position: relative;
    padding-left: 25px !important;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .adv_tabs_splitDepart .checkbox_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .adv_tabs_splitDepart .advTitle::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 2px solid #7f747c;
    border-radius: 50%;
  }
  
  .adv_tabs_splitDepart .checkbox_label input:checked ~ .advTitle::before {
    border-color: var(--secondary_color);
  }
  
  .adv_tabs_splitDepart .checkbox_label input:checked ~ .advTitle::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 1px;
    background-color: var(--secondary_color);
    border-radius: 50%;
    width: 7px;
    height: 7px;
    text-align: center;
    color: #fff;
    font-family: Times;
    border-radius: 50%;
    margin: 3px;
  }
  
  .adv_tabs_splitDepart .advTitle:hover::before {
    border-color: #555;
  }
  
  .adv_tabs_splitDepart .checkbox_label input:disabled ~ .advTitle::before {
    background-color: #ccc;
  }
  
  .srpwidget_nrml .flight_oneway {
    position: absolute;
    left: 22%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .srpwidget_nrml .flight_roundTrip {
    position: absolute;
    left: 22%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .srpwidget_nrml .flight_onewayAdvance,
  .srpwidget_Advance .flight_onewayAdvance {
    left: 27%;
  }
  
  .srpwidget_nrml .flight_multicity,
  .srpwidget_Advance .flight_multicity {
    position: absolute;
    left: 30%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .srpwidget {
    background: transparent !important;
    background: linear-gradient(
      180deg,
      rgba(87, 141, 191, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    padding: 30px 0;
    padding-top: 10px;
    margin-top: 18px;
    padding-bottom: 0;
  }
  
  .srpwidget .flight_widgetContainer {
    background-color: #fff;
  }
  
  .srpwidget_wrap {
    width: 1270px !important;
    margin: 0 auto;
    padding: 1rem 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .trave_flx {
    display: flex;
    align-items: center;
    padding-top: 0px;
  }
  
  .advMN_flx {
    display: flex;
    padding-top: 3px;
  }
  
  .icon_box_adv {
    margin-top: 2px;
  }
  
  .icon_box_adv svg {
    width: auto !important;
    height: 20px;
  }
  
  .icon_box_adv svg path {
    fill: var(--primary_color);
  }
  
  .advTrav_flx {
    display: flex;
    padding-top: 5px;
  }
  
  .trav_mainCont {
    position: relative;
    width: 35%;
    padding-left: 34px;
  }
  
  .flight_travIcon {
    margin-top: 0px;
  }
  
  .flight_travIcon svg {
    width: auto !important;
    height: 20px;
  }
  
  .flight_travIcon svg path {
    fill: var(--primary_color);
  }
  
  .pointer {
    width: 20px !important;
    height: 20px;
  }
  
  .adv_tabs_splitDepart .checkbox_label {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    align-items: flex-start;
  }
  
  .adv_tabs_splitDepart .checkbox_label input[type="checkbox"]:checked ~ .bagBox {
    background: var(--secondary_color);
    border: 0;
  }
  
  .adv_tabs_splitDepart .advTitle {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.38px;
    text-align: center;
    color: var(--primary_color);
  }
  
  .adv_tabs_splitDepart .advTitle {
    position: relative;
    padding-left: 25px !important;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .adv_tabs_splitDepart .checkbox_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .adv_tabs_splitDepart .advTitle::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 2px solid #7f747c;
    border-radius: 50%;
  }
  
  .adv_tabs_splitDepart .checkbox_label input:checked ~ .advTitle::before {
    border-color: var(--secondary_color);
  }
  
  .adv_tabs_splitDepart .checkbox_label input:checked ~ .advTitle::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 1px;
    background-color: var(--secondary_color);
    border-radius: 50%;
    width: 7px;
    height: 7px;
    text-align: center;
    color: #fff;
    font-family: Times;
    border-radius: 50%;
    margin: 3px;
  }
  
  .adv_tabs_splitDepart .advTitle:hover::before {
    border-color: #555;
  }
  
  .adv_tabs_splitDepart .checkbox_label input:disabled ~ .advTitle::before {
    background-color: #ccc;
  }
  
  .srpwidget_nrml .flight_oneway {
    position: absolute;
    left: 22%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .srpwidget_nrml .flight_roundTrip {
    position: absolute;
    left: 22%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .srpwidget_nrml .flight_onewayAdvance,
  .srpwidget_Advance .flight_onewayAdvance {
    left: 27%;
  }
  
  .srpwidget_nrml .flight_multicity,
  .srpwidget_Advance .flight_multicity {
    position: absolute;
    left: 30%;
    z-index: 1;
    top: 50px;
    cursor: pointer;
    color: var(--primary_color);
    background-color: transparent;
    padding: 0 5px;
    border-radius: 50px;
  }
  
  .flight_way_btngrp .button_style {
    border-radius: 3px;
    padding: 5px 15px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .flight_way_btngrp .active_way {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--secondary_color);
    background: transparent;
    border-radius: 15px;
    padding: 6px 15px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    /* border: 1px solid var(--secondary_color) */
  }
  
  .dropdown_container {
    width: 100%;
  }
  
  .rbderr {
    font-size: 11px;
    color: #e8664e;
    margin-top: 12px;
    display: block;
  }
  
  /* new advanced search css */
  .advance_flx {
    display: flex;
    align-items: center;
  }
  
  .flight_cabin_class_cont label {
    line-height: 1.5;
  }
  
  .pd_10 {
    padding-top: 10px;
  }
  
  .srpwidget_nrml {
    outline: 0;
    padding: 0px 30px 0 0;
  }
  
  .srpwidget_nrml .flight_oneway,
  .srpwidget_nrml .flight_roundTrip,
  .srpwidget_nrml .flight_multicity {
    background-color: white;
    margin-top: 8px;
  }
  
  .srpwidget_nrml .flight_oneway,
  .srpwidget_nrml .flight_roundTrip {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .srpwidget_Advance .flight_oneway,
  .srpwidget_Advance .flight_roundTrip,
  .srpwidget_Advance .flight_multicity {
    /* background-color: #def1fa;
      margin-top: 8px; */
    position: relative;
    top: 0;
    left: -8px;
    right: 0;
    bottom: 0;
  }
  
  .remove_flight_buttonMulti,
  .remove_flight_buttonMultiAdv {
    align-items: center;
  }
  
  .remove_flight_buttonMulti svg,
  .remove_flight_buttonMultiAdv svg {
    width: 30px !important;
    height: 30px !important;
  }
  
  /* .checkbox_label_flexy{
      float: right;
  } */
  .flight_widgetContainer {
    border: 1px solid #d4d4d4;
    padding: 10px 20px;
    border-radius: 30px;
    margin: 0 40px;
    /* align-items: center; */
  }
  
  .flight_airlineName {
    margin-top: 4px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    user-select: none;
  }
  
  .radio_cont {
    display: flex;
    margin-top: 10px;
  }
  
  .radio_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-right: 33px;
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
      color: var(--primary_color);
    cursor: pointer;
  }
  
  .radio_button input {
    display: none;
    width: 0;
    height: 0;
  }
  
  .radio_button span {
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid var(--primary_color);
  }
  
  .car_way_btngrp .radio_active {
    font-weight: 600;
  }
  
  .radio_button input:checked label {
    font-weight: 600;
  }
  
  .radio_button input:checked + span:after, .radio_button input:checked + font + span:after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--secondary_color);
    
  }
  
  .radio_button input:checked + span {
    background-color: #fff;
    border: 1px solid var(--secondary_color);
  }
  .radio_button.radio_active {
    font-size: 14px;
    color: var(--secondary_color);
    background: #ebf2ff;
    border-radius: 15px;
    padding: 6px 15px;
    border: 1px solid var(--primary_color);
}
  
  .radio_button input:disabled + span {
    background-color: #e5e5e5;
  }
  
  .radio_button span:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    background-color: #76b176;
  }
  
  .flight_search_details_main
    .flight_search_details_row:first-child
    .flight_InputVal
    input {
    width: 100%;
    padding: 10px 14px 10px 25px !important;
    border-radius: 5px;
    box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.15) !important;
    /* border: solid 1px #d4d4d4; */
    background-color: #fff;
  }
  
  .flight_search_details_main
    .flight_search_details_row:first-child
    .flight_InputVal
    input:focus {
    color: var(--font_color);
    opacity: 1;
  }
  
  .flight_autocomplete_name .flight_autoCityfull {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: #979797;
    margin-top: 8px;
    display: block;
  }
  
  .flight_autocomplete_element:hover
    .flight_autocomplete_name
    .flight_cityCountTitle,
  .flight_autocomplete_element:focus
    .flight_autocomplete_name
    .flight_cityCountTitle {
    color: #fff;
  }
  
  .search_Autoicon {
    position: absolute;
    top: 12px;
    left: 7px;
  }
  
  .flight_InputVal {
    /* position: relative; */
  }
  
  .flight_autocomplete_value .flight_InputVal .search_Autoicon svg {
    position: relative !important;
    background: transparent;
    width: 15px !important;
    height: 14px !important;
  }
  
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_pax_newleft
    .flight_title {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 0;
  }
  
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_pax_newleft
    .flight_ageLbl {
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: var(--font_color);
    margin-top: 4px;
  }
  
  .flight_search_pax .flight_search_wid_inputfield {
    flex-direction: row;
    align-items: center;
    width: 25%;
  }
  
  .pax_main svg path {
    fill: var(--primary_color) !important;
  }
  
  .trav_wrap {
    padding: 20px 20px;
    padding-bottom: 0;
  }
  
  .class_wrap {
    padding: 16px 10px;
    padding-bottom: 16px;
    box-shadow: 0px -3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 40px;
    border-radius: 0 0 5px 5px;
  }
  
  .flight_search_details_main .flight_search_details_row .flight_cabin_bxd label {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    /* padding-bottom: 0; */
  }
  
  .populardest_title {
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    margin-right: 20px;
  }
  
  .popular_destination {
    background-color: #ececec;
    padding: 9px;
    border-radius: 0 0 8px 8px;
    border-top: none;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .popular_destiSRP{
    margin-top: 10px;
    padding-left: 0;
    background: transparent;
  }
  
  .destination_container {
    display: flex;
    gap: 20px;
  }
  
  .destination_active .destination_name {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  
  .destination_item .property_count {
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: var(--font_color);
  }
  .destination_active {
    position: relative;
  }
  
  .destination_active .property_count {
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: #fff;
  }
  
  .destination_item {
    border: 1px solid #000;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    position: relative;
  }
  
  .destination_active {
    background-color: var(--primary_color);
    border: 1px solid var(--primary_color);
    border-radius: 10px;
    padding: 10px 10px;
  }
  
  .rounddatedisable {
    color: var(--font_color);
  }
  
  .destination_name {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
  }
  
  .calCheckin_cont {
    display: flex;
    gap: 63px;
    box-shadow: 0 2px 3px 0 #0000001a;
    padding: 14px 9px;
    padding-bottom: 0;
    padding-left: 19px;
  }
  
  .calCheckin_box {
    display: flex;
    gap: 8px;
  }
  
  .calChec_icon {
    color: #474747;
  }
  
  .calChec_icon svg {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .calCheck_date {
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-bottom: 6px;
    /* border-bottom: 3px solid var(--primary_color); */
  }
  
  .calendar_head_icon svg {
    width: 25px;
  }
  
  .Stop_wrap {
    margin-left: auto;
  }
  
  .Stop_wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .Stop_wrap input[type="checkbox"] {
    display: none;
  }
  
  .Stop_wrap label {
    position: relative;
    padding-left: 25px;
    padding-top: 3px;
    cursor: pointer;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
  }
  
  .Stop_wrap label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .Stop_wrap input[type="checkbox"]:checked + label:before {
    background-color: var(--primary_color);
    border: 1px solid var(--primary_color);
  }
  
  .Stop_wrap label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 6px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  .Stop_wrap input[type="checkbox"]:checked + label:after {
    display: block;
  }
  
  .srpwidget_wrap .radio_button {
    color: #fff;
  }
  
  /* .srpwidget_wrap .radio_button input:checked + span:after{
      color: #fff;
    } */
  .srpwidget_wrap .radio_button input:checked + span:after {
    background-color: #fff;
  }
  
  .srpwidget_wrap .radio_button input:checked + span {
    background-color: transparent;
    border: 1px solid #fff;
  }
  
  .srpwidget_wrap .radio_button span {
    background-color: transparent;
    border: 1px solid #fff;
  }
  
  .srpwidget_wrap .srpwidget_wrap .radio_button input:checked + span:after {
    background: #fff;
  }
  
  .srpwidget_wrap .flight_widgetContainer,
  .srpwidget_wrap .radio_cont {
    margin: 0;
  }
  
  .srpwidget_wrap .popular_destination {
    /* display: none; */
  }
  
  .flight_search_details_main
      .flight_search_details_row:first-child
      > div .roundDateDisable {
    color: #848484 !important;
  }
  
  .srpwidget_Advance {
    padding: 0px 30px 0 0;
  }
  
  .close_iocn {
    position: absolute;
    top: -2px;
    left: 39%;
    align-items: center;
    cursor: pointer;
  }
  
  .suggestion_label {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: left;
    color: var(--font_color);
    margin-top: 13px;
    margin-left: 9px;
    margin-bottom: 10px;
  }
  
  .flight_autoIcon {
    width: 40px;
    height: 20px;
  }
  
  .flight_autoIcon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }
  
  .flight_country {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary_color);
    padding: 2px 0;
  }
  
  .no_result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 15px;
  }
  .calendar_head_left_side span svg {
    top: 50px !important;
  }
  
  .calendar_head_right_side span svg {
    top: 55px !important;
  }
  .no_resMsg{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
  }
  
  .fltMulticity_container{
    padding: 0;
  }
  
  .fltMulticity_container .fltFrom_multicity {
    border-right: 1px solid #dedede;
    margin-right: 20px !important;
    width: 235px;
  }
  .flight_search_details_main .fltMulticity_Card:first-child > .fltFrom_multicity,
  .flight_search_details_main .fltMulticity_Card:first-child > .fltTo_multicity,
  .flight_search_details_main .fltMulticity_Card:first-child > .desktop_div,
  .flight_search_details_main .fltMulticity_Card:first-child > .trav_mainCont
  {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .fltMulticity_container .fltTo_multicity{
    width: 284px;
  }
  .fltMulticity_container .desktop_div{
    width: auto !important;
  }
  .fltMulticity_container .trav_mainCont{
    width: auto;
    padding-left: 18px;
  }
  
  .fltMulticity_container  .desktop_div > .flight_search_column:first-child{
    width: 229px !important;
    border-right: 1px solid #dedddd;
  }
  .fltMulticity_container .flight_Adv_wrapMU {
    width: auto;
    margin-left: auto;
  }
  .fltMulticity_container .search_button_section {
    position: static !important;
    right: 0;
    /* height: 40px; */
    border: 0;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 0;
    text-align: center;
  
  }
  .fltMulticity_container .search_button_section button{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
    position: relative;
    /* border-bottom: 1px solid var(--primary_color); */
  }
  .fltMulticity_container .search_button_section button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: 1px;
    background-color: var(--primary_color);
    opacity: 0.3;
    z-index: 1;
  }
  .fltMulticity_container .fltMulticity_Card {
    margin-top: 9px;
    height: 103px;
  }
  .fltMulticity_container .remove_flight_buttonMulti svg,
  .fltMulticity_container .remove_flight_buttonMultiAdv svg {
    width: 26px !important;
    height: 26px !important;
    cursor: pointer;
  }
  .fltMulticity_container .remove_flight_buttonMulti svg path,
  .fltMulticity_container .remove_flight_buttonMultiAdv svg path{
    fill: var(--primary_color);
  }
  .remove_flight_buttonMulti, .remove_flight_buttonMultiAdv {
    border-left: 1px solid #dedddd;
    height: 70px;
    padding-left: 15px;
  }
  .srpwdgtTop{
    padding-top: 30px;
  }
  .multicity_wrap input {
    font-size: 12px !important;
  }
  .multicity_wrap input::placeholder {
    font-size: 12px !important;
  }
  .multicity_Calwrap input {
    font-size: 12px !important;
    text-overflow: inherit !important;
  }
  .multicity_Calwrap input::placeholder {
    font-size: 12px !important;
  }
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_column
  .multicity_error{
    top: -8px !important;
  }

  .busStopContainer_main{
    /* position: absolute;
    top: 65px;
    right: 0;
    z-index: 9;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    width: 344px;
    overflow: hidden; */
  }

  .busStopContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    /* border-bottom: 1px solid #d4d4d4; */
  }
  .busStopContainer_main {
    border-bottom: 1px solid #d4d4d4;
  }
  .busStopContainer:last-child, .busStopContainer_main:last-child{
    border-bottom: 0;
  }
  
  .locationInfo {
    display: flex;
    gap: 9px;
    margin: auto 0;
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    width: 100%;
    padding-left: 12px;
  }
  
  .locationIcon {
    aspect-ratio: 2.82;
    object-fit: contain;
    object-position: center;
    width: 31px;
    margin: auto 0;
  }
  
  .locationName {
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
  
  .statusContainer {
    display: flex;
    gap: 17px;
    padding-right: 12px;
  }

  .cab_stops_container{
    align-self: center;
  }
  .cab_stops_container_main{
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid #dedede;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
  }
  .cab_stops_container_main_wrap{
    padding: 5px 0;
  }
  .cab_seat_add_icon{}
  
  .statusDivider {
    background-color: #d9d9d8;
    width: 1px;
    height: 46px;
  }
  
  .statusIndicator {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
    padding: 5px 7px;
    border: 1px solid var(--primary_color);
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .statusIndicatorPlus{
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
    padding: 14px 6px;
    border: 1px solid var(--primary_color);
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 5px 8px;
  }
  
  .statusDot {
    background-color: var(--primary_color);
    width: 12px;
    height: 2px;
  }

  /* New Plus Icon */
.statusDotPlus {
  position: relative;
  width: 12px;  
  height: 12px; 
}

.plusVertical, .plusHorizontal {
  position: absolute;
  background-color: var(--primary_color);
  width: 2px;
  height: 12px;
}

.plusVertical {
  left: 5px;
}

.plusHorizontal {
  top: 5px;
  width: 12px;
  height: 2px;
}

.cabStop_main{}
.cabStop{}
.cabStopInfo{}
.cab_main_container{
  position: absolute;
  top: 52px;
  right: 0;
  z-index: 9;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  width: 344px;
  overflow: hidden;
}
.cab_main_container .autocomplet_wrap {
  padding: 8px 10px;
}
.cabStopInfo :global .autosuggest_container .autosuggest_value{
  position: static;
  width: auto;
  border: none;
  padding-bottom: 10px;
}
.cabStopInfo :global .autosuggest_container label:first-child{
  /* border-top: 1px solid #dedede; */
  display: block;
  padding: 0 10px;
  padding-top: 5px;
  font-size: 14px;
}
.cabStopInfo :global .autosuggest_container .theme5_autocomplet_wrap__8PJ36{
  padding: 0 10px;
  padding-bottom: 10px;
  padding-top: 5px;
}
.cabStopInfo :global .autosuggest_container .theme5_autocomplet_wrap__8PJ36 input{
  caret-color: auto;
}
.cabStopInfo :global .autosuggest_container .theme5_suggestion_label__NidVk{
  margin-top: 5px;
}
.cabStopInfo :global .autosuggest_container .autosuggest_value .theme5_flight_autocomplete_element__9cqdH{
  margin-bottom: 0;
}

.calendar_width{
  width: 60%;
}
.cabStop_err{
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.locationName{
  width: 85%;
}
.locationInfo{
  align-items: center;
}
.srpwidget .car_searchwidget_container {
    padding: 0 !important;
    margin-top: 0;
    background-color: transparent;
    box-shadow: none;
}
.srpwidget{
      margin: 0;
    padding-top: 0;
}

.srpwidget .car_search_details_container .car_search_details_wrap {
    background-color: transparent;
}
.srpwidget .radio_button input:checked+span, 
.srpwidget .radio_button span {
    background-color: transparent;
    border: 1px solid var(--primary_color);
}
.srpwidget .radio_button input:checked+span:after {
    background-color: var(--primary_color);
}
.srpwidget .radio_button {
    color: var(--primary_color);
}
.airport_selector {
  padding: 5px 0;
  position: relative;
  width: 100%;
  height: 100%;
  /* border-right: 1px solid #dedede; */
  border-bottom: 2px solid #dedede;
}
.airport_selector__display_wrap {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  justify-content: space-between;
}
.airport_selector__display {
  cursor: pointer;
  padding-left: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.car_searchwidget_airport_selector_container{
  width: 30%;
  padding-right: 15px;
}

.scrollable {
  overflow-y: scroll;
  max-height: 200px; /* Adjust as needed */
  position: relative;
}

.scrollable .draggable-selector {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px; /* This could be a thin line or a larger span depending on the UI */
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.car_searchwidget_container :global .theme4_collapsible_title_add__VxnLn{
  position: absolute;
  top: 20px;
}
.srpwidget .car_searchwidget_container :global .theme4_collapsible_title_add__VxnLn{
  position: absolute;
  top: 10px;
}

  
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  .srpwidget_wrap{
    width: 992px !important;
  }
  .srpwidget_wrap .flight_widgetContainer{
    padding: 10px;
  }
  .srpwidget_wrap .flight_search_depart_multicity{
    width: 21%;
  }
  }
  @media only screen and (max-width: 992px) and (min-width: 768px){
  .flight_mobile_calendar{
  width: 450px !important;
  }
  
  }
  @media only screen and (max-width: 768px) and (min-width: 320px) {
    .popular_destination1{
      display: block;
    }
    .popular_destination1 .destination_container{
      margin-top: 0px;
      margin-bottom: 0px;
      flex-wrap: wrap;
    }
    .popular_destination  .Stop_wrap {
      justify-content: flex-end;
      position: absolute;
      right: 20px;
      bottom: 100px;
  }
  .busStopContainer_main {
    border-bottom: 1px solid var(--primary_color);
    border-top: 1px solid var(--primary_color);
}
.busStopContainer_main:first-child{
  border-bottom: 0;
}
.statusDivider{
  background-color: transparent;
}
  .flight_search_details_main .flight_search_details_row .Stop_wrap label {
    font-size: 13px;
  font-weight: bold;
    padding-top: 2px;
  }
  .flight_search_details_container .flight_search_details_main .flight_search_details_row .Stop_wrap{
    margin-bottom: 0px!important;
  
  }
  .flight_search_details_container .flight_search_details_main .flight_search_details_row .Stop_wrap label{
    margin-bottom: 0px!important;
    color: #fff;
  
  }
  .flight_search_details_container .flight_search_details_main .flight_search_details_row .Stop_wrap label:before{
  left: -30px;
  }
  .flight_search_details_container .flight_search_details_main .flight_search_details_row .Stop_wrap label:after{
    left: -23px;
  }
  .flight_search_details_container{
    position: relative;
  }
  .Stop_wrap input[type="checkbox"]:checked + label:before {
    background-color: #fff;
    border: 1px solid #fff;
  }
  .Stop_wrap label:after{
    border: solid var(--primary_color);
    border-width: 0 2px 2px 0;
  }
  
    .destination_item .property_count {
      color: #fff;
    }
    .destination_active .property_count {
      color: var(--primary_color);
    }
    .calendar_wid_container {
      height: 56px!important;
      display: block;
    }
    .flight_search_details_main
        .flight_search_details_row:first-child
        > div .roundDateDisable {
      color: #848484 !important;
      font-size: 13px!important;
    }
    .fltMulticity_container .search_button_section button::after {
      content: '';
      height: 1px;
      background-color: transparent;
      opacity: 0;
      z-index: 0;
    }
    .flight_mobileContainer {
      width: 100%;
      max-width: 100%;
      padding: 10px;
      padding-bottom: 0;
      margin-top: 0;
    }
  
    .flight_search_wid_container {
      background: var(--primary_color);
      box-shadow: 0px 3px 15px 0 rgba(0, 0, 0, 0.1);
      padding: 29px 0px 0px;
      position: unset;
    }
  
    .flight_way_btngrp {
      margin: 0px 0px 0px 10px;
    }
  
    .radio_active {
      color: #fff;
    }
  
    .radio_button {
      color: #fff;
    }
  
    .flight_search_details_container
      .flight_search_details_main
      .flight_search_details_row {
      display: flex;
      flex-direction: column;
      gap: initial;
      margin: 0;
      border: none;
      border-radius: 0;
      position: unset;
    }
  
    .flight_widgetContainer {
      padding: 10px;
    }
  
    .flight_search_details_container
      .flight_search_details_main
      .flight_search_details_row
      .flight_search_column {
      padding: 10px !important;
      height: 77px;
      position: unset;
      background: #fff;
      width: 100%;
      border-radius: 10px !important;
    }
    /* .flight_search_details_container .flight_search_wid_inputfield{padding:10px!important} */
  
    .flight_search_depart_multicity,
    .flight_search_depart_going_date_multicity {
      width: 100%;
      background: #fff !important;
      border-radius: 15px !important;
    }
  
    .flight_search_details_main .flight_search_details_row:first-child label {
      font-size: 15px;
      /* padding-bottom: 9px; */
      padding-top: 4px;
      padding-bottom: 8px;
      line-height: 1;
    }
    .flight_search_pax_select_main .flight_search_pax{
      padding-bottom: 28px;
      border-bottom: 1px solid #dedddd;
      padding-top: 22px;
    }
  
    .flight_search_details_main
      .flight_search_details_row:first-child
      > div
      input {
        font-family: var(--font_family);
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        text-align: left;
        color: var(--secondary_color) !important;
    }
  
    .rounddatedisable {
      color: #4b4b4b !important;
    }
  
    .flight_airlineName {
      margin-top: 0px;
      font-size: 13px;
      color: var(--secondary_color);
      /* line-height: 1; */
    }
  
    .flight_InputVal input {
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      border-radius: 5px !important;
      background-color: #fff;
      display: flex;
      height: 41px;
      padding: 8px 10px;
      padding-left: 30px;
      color: var(--font_color);
    }
    .flight_InputVal input::placeholder {
      color: var(--font_color) !important;
      font-family: var(--font-family);
      font-size: 13px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal; 
  }
    .flight_InputVal input:focus {
      box-shadow: none;
      border: none;
      color: var(--font_color);
      opacity: 1;
  }
  
    .trav_mainCont {
      width: 100%;
      background-color: #ffffff;
      padding-left: 0px;
      border-radius: 10px;
    }
  
  
    .srpwidget_nrml .flight_oneway,
    .srpwidget_nrml .flight_roundTrip,
    .srpwidget_nrml .flight_multicity {
      background-color: transparent;
      margin-top: 8px;
      left: unset;
      right: -22px;
      position: absolute;
      top: 0;
    }
  
    .srpwidget_nrml {
      outline: 0;
      padding: 0px 0px 0 0;
      position: relative;
    }
  
    .flight_search_lastchild {
      margin-top: 20px;
      margin-bottom: 20px !important;
    }
  
    .flight_swapAdv {
      transform: rotate(90deg);
    }
  
    .flight_trav_title {
      font-size: 15px;
      line-height: 1;
    }
  
    .flight_cabin_class_name {
      font-size: 15px;
    }
  
    .flt_trav_label {
      font-size: 13px;
      margin-top: 0;
      line-height: 1;
    }
  
    .flight_search_details_main .flight_search_details_row:first-child > div {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      margin-top: 0;
      margin-bottom: 30px !important;
    }
  
  
  
    .flight_Adv_wrapMU {
      width: 100%;
      display: block;
      text-align: center;
      margin-top: 30px;
    }
  
    .flight_AdvBtnMU {
      width: 100%;
      height: 67px;
      font-size: 18px;
    }
  
    .popular_destination {
      background: #1b5d9d;
      padding: 20px 10px;
      margin-top: 0px;
      display: block;
    }
  
    .populardest_title {
      color: #ffffff;
    }
  
    .destination_container {
      margin-top: 27px;
    }
  
    .destination_item {
      border: 1px solid #fff;
      background: transparent;
    }
  
    .destination_name {
      color: #fff;
    }
  
    .destination_active {
      background-color: #fff;
      color: var(--primary_color);
    }
  
    .destination_active .destination_name {
      color: var(--primary_color);
    }
  
    .Stop_wrap {
      justify-content: flex-end;
    }
    .Stop_wrap label {
      font-family: var(--font_family);
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      padding-top: 4px;
    }
  
    .flights_city_parent {
      margin: 0px;
      max-height: 100vh;
      border-radius: 0;
    }
    .flights_city_parent::-webkit-scrollbar {
      display: none;
    }
  
    .flights_city_parent .headerAuto {
      display: flex;
      align-items: center;
      padding: 16px 19px;
      background-color: var(--primary_color);
      font-family: var(--font_family);
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: left;
      color: #fff;
      /* position: fixed; */
      /* top: 0; */
      width: 100%;
      height: 65px;
      
    }
  
    .flights_city_parent > div > span {
      /* padding-right: 130px; */
    }
  
    .flight_autocomplete_element {
      flex-direction: row-reverse;
      border-bottom: 1px solid #E5E5E5;
      border-radius: 0;
      padding-bottom: 15px;
    }
  
    .suggestion_label {
      margin-left: 13px;
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: left;
      color: var(--secondary_color);
    }
    .header_title{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
    }
  
    .flight_autocomplete_name .flight_autoCityfull {
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: left;
      color: #979797;
    }
  
    .flight_search_details_main .flight_search_details_row:first-child > div svg {
      width: 20px;
      height: 30px;
      background-color: transparent;
    }
  
    .fltg_icon .plane_img {
      display: none;
    }
  
    .fltg_icon .flight_mobile_fly {
      display: block;
    }
  
    .flight_search_pax_select_main {
      position: fixed;
      width: 100%;
      left: -1px;
      top: -1px;
  
      height: 100vh;
      border-radius: 0;
    }
  
    .mobileAddTraveller {
      display: flex;
      background-color: var(--primary_color);
      padding: 16px 15px;
      height: 65px;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: left;
      color: #fff;
    }
  
    .mobileAddTraveller span {
      padding-right: 0px;
    }
  
    .class_wrap {
      box-shadow: none;
      display: grid;
      /* border-top: 1px solid #dedddd; */
      padding: 10px 20px;
    }
  
    .flight_search_details_container .flight_search_details_main {
      position: unset;
    }
  
    .flight_autocomplete_value {
      top: -1px;
      left: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      z-index: 999;
      position: fixed;
      border: 0;
      box-shadow: none;
    }
  
    .flight_InputVal {
      margin-top: 0px;
      padding: 20px;
      background-color: #1b5d9d;
      position: sticky;
      top: 60px;
      border: 1px solid #1b5d9d;
    }
  
    .search_Autoicon {
      position: absolute;
      top: 13px;
      left: 10px;
    }
    .flight_main_box{
      position: relative;
    }
    .suggestion_main_container {
      max-height: 70vh;
      margin: 0;
      overflow-y: auto;
  }
  .suggestion_main_container{
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}
.suggestion_main_container::-webkit-scrollbar {
  display: none; 
}
  
    .flight_search_details_main
      .flight_search_details_row:first-child
      .flight_InputVal
      input {
      padding: 12px 14px 12px 30px !important;
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: left;
      color: #979797;
    }
  
    .flight_search_details_main
      .flight_search_details_row:first-child
      .flight_InputVal
      input::placeholder {
      font-size: 15px;
      font-weight: normal;
      color: #979797 !important;
    }
  
    .pax_btn_thme button {
      width: 100%;
      text-align: center;
      height: 57px;
    }
  
    .sugges_popular {
      padding: 20px;
      margin-top: 20px;
      border-top: 1px solid #c1c1c1;
    }
  
    .sugges_popular p {
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: left;
      color: var(--secondary_color);
    }
  
    .popular_suggestion {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      margin-top: 23px;
    }
  
    .popular_suggestion span {
      padding: 10px 15px;
      font-size: 18px;
      font-family: var(--font_family);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      text-align: left;
      color: var(--primary_color);
      background-color: #fff;
      border: 1px solid var(--primary_color);
      border-radius: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  
    .modal_container {
      position: fixed;
      z-index: 1;
      left: -1px;
      top: -1px;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      /* background-color: rgb(0, 0, 0); */
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 999;
    }
    .calendar_wid_container  .modal_content{
      padding-bottom: 100px;
    }
    .modal_content {
      background-color: #fefefe;
      border: 1px solid transparent;
    }
    .cal_header{
      height: 65px;
      padding: 10px 10px;
      background-color: #266aac;
      display: flex;
      align-items: center;
      z-index: 12;
      position: relative;
    }
    .cal_header span svg {
      height: 25px;
  }
  .calendar_wid_main input {
    color: var(--secondary_color) !important;
    font-family: var(--font-family);
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}
.car_search_details_main label:first-child,
.car_search_details_new_wrap label:first-child,
.car_searchwidget_calendar_container label:first-child,
.car_searchwidget_pickup_container label:first-child:first-child,
.car_searchwidget_distance_container label:first-child {
  font-size: 15px;
}
    .cal_headerLabel{
      font-family: var(--font_family);
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: left;
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .calendar_head_main{
      height: 85px;
      padding: 12px 10px;
      background-color: #1b5d9d;
      display: flex;
      gap: 25px;
      justify-content: space-between;
    }
  
    .calendar_head_com{
      height: 61px;
      padding: 10px 10px 10px 6px;
      border-radius: 5px;
      background-color: #266aac;
      width: 50%;
      position: relative;
    }
    .calendar_head_title{
      font-family: var(--font_family);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      text-align: left;
      color: #fff;
    }
    .calendar_head_date{
      font-family: var(--font_family);
      font-size: 13px!important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .calendar_footer{
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background: #fff;
      padding: 10px;
      text-align: center;
      box-shadow: 0 0 9px 1px rgb(0 0 0 / 11%);
    }
    .calendar_footer button{
      width: 100%;
      height: 57px;
      margin: auto;
      padding: 18px 15px;
      border-radius: 10px;
      background-color: var(--buttons_color);
      font-family: var(--font_family);
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      /* margin-top: 50px;
      margin-bottom: 50px; */
    }
    .closeb_icon{
      position: absolute;
      top: -5px;
      right: -5px;
      width: 19px;
      height: 19px;
      padding: 5px;
      background-color: #fff;
      border-radius: 50px;
    }
    .cal_Active{
      box-shadow: 0 0 7px 0 #fff;
      border: solid 1px #fff;
      background-color: #266aac;
    }
    .calendar_week_heads {
      display: flex;
      padding: 0 15px;
      padding-top: 10px;
      padding-bottom: 6px;
      border-bottom: 1px solid #efeff1;
      background-color: #fff;
  }
  .calendar_week_heads .coln_center_week {
    padding: 5px 0;
    font-size: 13px;
    width: calc(100% / 7);
    text-align: center;
    font-family: var(--font_family);
    text-transform: uppercase;
    color: #8a8a8a;
  }
  .calendar_week_heads .coln_center_week:nth-last-child(-n + 2) {
    color: var(--primary_color);
    font-weight: bold;
  }
  .date_header_container{
    position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;
  }
  .calendar_head .calendar_head_center_side h3 {
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--secondary_color);
    user-select: none;
  }
  .calendar_body .calendar_day_list .day_cell_center {
    font-size: 16px;
    color: var(--secondary_color);
    height: 50px;
  }
  .calendar_body .calendar_day_list .day_cell_center.selected_calendar_date {
    background-color: transparent;
  }
  .calendar_body
  .calendar_day_list
  .day_cell_center.selected_calendar_date
  > span {
  font-weight: bold;
  background: var(--primary_color);
  width: 40px;
  height: 44px;
  border-radius: 5px;
  }
  .day_cell_center_highlight span {
    background: #b9d1e9;
    width: 40px;
    height: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .calendar_body {
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 25px;
    /* border-bottom: 2px dashed #ccc; */
  }
  
  
  
  .mobileflexcal{
    display: flex;
    gap: initial;
  }
  .flight_mobile_calendar{
    background-color: #fff;
    height: 77px;
    padding: 10px 10px;
    border-radius: 10px;
    width: 50%;
    margin-right: 18px;
  }
  .flight_mobile_calendar:last-child{
    margin-right: 0;
  }
  .calendar_wid_container .calendar_wid_main {
    display: block;
  }
  .calend_mnFlx{
    display: flex;
    /* align-items: center; */
  }
  .cal_arrow{
  margin-top: 24px;
  }
  .flight_search_details_container
  .flight_search_details_main
  .flight_search_details_row {
    position: relative;
    height: auto;
  }
  .srpwidget_nrml .flight_oneway,
  .srpwidget_nrml .flight_roundTrip,
  .srpwidget_nrml .flight_multicity {
    background-color: transparent;
    margin-top: 0px;
    left: unset;
    right: 0px;
    position: relative;
    top: 0;
  }
  
  
  .flight_swapAdv svg {
    margin: auto;
    width: 37px;
    height: 37px;
    fill: var(--primary_color);
  }
  .srpwidget_nrml {
    outline: 0;
    padding: 0px 0px 0 0;
    display: flex;
    position: absolute;
    right: 5px;
    top: 84px;
  }
  .flgtmn_flx{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .flgtmn_arrow{
    margin-top: -4px;
  }
  .flt_pasngrFlx{
    padding-left: 0;
  }
  .radio_button input:checked + span:after, .radio_button input:checked + font + span:after {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--primary_color);
  }
  .radio_button.radio_active {
    border-radius: 20px;
    background: #ecf2ff;
    padding: 6px;
}
  .error_srp {
    top: -7px;
}
.error_desk{
  position: absolute;
  left: 0;
  bottom: inherit;
  top: 54px !important;
}
.cab_main_container{
  z-index: inherit;
}
.selectPackage_btngrp.flight_way_btngrp label.radio_active:first-child{
  padding:  5px 10px;
  border: 1px solid var(--primary_color);

}



  .radio_button span {
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid var(--secondary_color);
  }
  .radio_button input:checked + span {
    background-color: transparent;
    border: 1px solid var(--primary_color);
  }
  .mobileAddTraveller p{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
  }
  .flight_cabin_bxd {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 15px;
  }
  .srpwidget_wrap{
    width: 100% !important;
    padding: 20px 0;
    background: var(--primary_color);
    border-radius: 8px;
    padding-bottom: 0;
  }
  
  .srpwidget_wrap .flight_search_details_container
  .flight_search_details_main
  .flight_search_details_row {
    background-color: transparent;
  }
   .flight_mobileContainer{
    background: #fff !important;
    padding-bottom: 10px;
    min-height: 100vh;
  }
  .srpheader{
    display: flex;
    align-items: center;
    padding: 23px 9px 22px 9px;
    background-color: #266aac;
  }
  .srpheader p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #fff;
    width: 100%;
  }
  .srpwidget_wrap .flight_way_tab{
  padding: 0 10px;
  }
  .srpwidget_Advance {
    padding: 0px 30px 0 0;
    outline: 0;
    padding: 0px 0px 0 0;
    display: flex;
    position: absolute;
    right: 0px;
    top: 86px;
  }
  
  
  .rounddatedisable {
    color: #4b4b4b !important;
  }
  .no_result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 15px;
  }
  .no_result p {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
  }
  .flight_search_details_main .flight_search_details_row .no_result svg {
    width: auto !important;
    height: auto !important;
    position: inherit!important;
  }
  .flight_search_details_main .headerAuto{
    position: sticky!important;
    top: 0px!important;
    z-index: 99!important;
    text-align: center !important;
      display: block !important;
      padding: 20px 0 !important;
  }
  .headerAuto span{
    /* position: absolute; */
    position: static;
    left: 10px;
  }
  .fltMulticity_container .search_button_section {
    padding: 0;
    padding-top: 21px;
    width: auto;
  }
  .fltMulticity_container .flight_AdvBtnMU {
    margin-top: 21px;
  }
  .fltMulticity_container .search_button_section button{
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    position: relative;
    text-align: center;
    width: 100%;
    height: 37px;
    border-radius: 10px;
    border: solid 1px #fff;
  }
  .multicity_main,
  .fltMulticity_container .search_button_section {
    width: 100%;
    clear: both;
  }
  .fltMulticity_container .trav_mainCont{
    width: auto;
    padding-left: 0px;
  }
  .fltMulticity_container .fltMulticity_Card {
    display: block !important;
  }
  .fltMulticity_container .flight_search_depart_multicity,
  .fltMulticity_container .flight_search_depart_going_date_multicity {
    /* width: 30.2% !important; */
    width: 27.2% !important;
  }
  .fltMulticity_container .flight_mobile_calendar{
    /* width: auto; */
    /* width: 88.7%; */
    width: 75.5%;
  }
  .fltMulticity_container
  .flight_search_details_main
  .fltMulticity_Card
  .flight_search_column
  /* .fltMulticity_container .fltFrom_multicity  */
  {
    border-right: 1px solid #dedede;
    margin-right: 10px !important;
    margin-bottom: 0 !important;
  }
  /* .fltMulticity_container .fltTo_multicity{
    margin-right: 10px !important;
    margin-bottom: 0 !important;
  } */
  
  .fltMulticity_container
  .flight_search_details_main
   .flight_search_details_row:first-child > .mobileflexcal {
  margin-bottom: 0 !important;
   }
   .fltMulticity_container .remove_flight_buttonMulti svg path,
  .fltMulticity_container .remove_flight_buttonMultiAdv svg path{
    fill: #fff;
  }
  .fltMulticity_container .flight_search_details_main .flight_search_details_row:first-child .remove_flight_buttonMulti, .remove_flight_buttonMultiAdv {
    border-left: 0px;
    height: auto;
    padding-left: 0px;
    margin-bottom: 0 !important;
    position: absolute;
    right: 0;
    top: 37px;
    margin-right: 6px;
  }
  .fltMulticity_container .cal_arrow{
    display: none;
  }
  .fltMulticity_container .flight_search_column.flight_cabin_class_cont.pd_10 {
    margin-top: 20px !important;
  }
  .fltMulticity_container .flight_search_details_main .flight_search_details_row:first-child > .flight_Adv_wrapMU {
    margin-bottom: 0 !important;
    clear: both;
  }
  .tlight_way_tab {
    width: auto;
    text-align: left;
    padding-bottom: 10px;
    overflow: auto;
  }
  
  .flight_way_btngrp {
    margin: 0px 0px 0px 10px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow: auto;
    justify-content: space-between;
    padding: 0 10px;
    margin: 0;
  }
  
  .radio_button {
    color: #fff;
    width: auto;
    margin: 0;
  }

  .mobile_header {
    position: sticky;
    top: 0;
    z-index: 9;
  }
  .mobile_header .mobile_visible {
    display: block;
  }
  .mobo_tab_header {
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .4);
  }
  .mobile_visible .arrow_image {
    width: 20px;
    height: 29px;
    margin-right: 20px;
  }
  .mobile_visible .search_filter_header {
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .75px;
    text-align: left;
    color: var(--primary_color);
  }
  
  
  
  }
  
  
  /* @media only screen and (max-width: 430px) and (min-width: 415px){
    .flight_search_pax_select_main{
        width: 430px;
        height: 100vh; 
        position: absolute;
        top: -505px;
        left: -22px;
        right: 0;
        overflow: hidden;
    }
  }
  @media only screen and (max-width: 414px) and (min-width: 400px){
  .flight_search_pax_select_main{
  
    width: 414px; 
  
  }
  
  
  } */
  
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    /* .no_result_mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 40px;
      gap: 25px;
    }
    .no_result_mobile p {
  
    }
    .no_result_mobile svg {
      
    } */
    .pickup_time__display {
      width: 100% !important;
  }
    .flight_search_details_main
    .flight_search_details_row
    .flight_search_column
    .flight_search_error_message {
    position: relative;
    top: -6px;
    float: left;
    width: 100%;
    top: -3px;
    left: 0;
  }
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_pax_newleft
    .flight_title {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    padding-bottom: 0;
  }
  .flight_search_details_main
    .flight_search_details_row
    .flight_search_pax_newleft
    .flight_ageLbl {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: var(--font_color);
    margin-top: 4px;
  }
  .car_search_details_mob{
    border-radius: 0 0 15px 15px !important;
  }
  .car_searchWidget_mob :global .theme4_adv_search_container__ndtoL{
    border-radius: 15px 15px 0 0;
  }
  .flight_search_pax .flight_search_wid_inputfield span {
    font-size: 20px;
  }
  .flight_search_pax .flight_search_wid_inputfield {
    width: auto;
    gap: 10px;
  }
  .flight_cabin_bxd label {
    font-family: var(--font_family);
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color) !important;
  }
  .pickup_time__selection{
    width: 100% !important;
  }
  .car_searchwidget_airport_selector_container{
    width: 100%;
    padding-right: 0;
  }
  .car_searchwidget_airport_selector_container .car_way_btngrp{
    padding: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
  }
  .pax_btn_thme button {
    font-size: 18px;
  }
  .trave_flx {
    display: block;
    padding-top: 0px;
  }
  .flight_mobileContainer {
    min-height: unset !important;
  }

  .pickup_time__options__hours, .pickup_time__options__minutes {
    width: 40%;
    padding: 10px 5px;
}

.pickup_time__options__periods {
    width: 25%;
}
  
  .flight_search_details_main .flight_search_details_row:first-child > .mobMulticity_error{
    background: #ffcbcb;
    color: red;
    border-radius: 4px;
    padding: 3px 5px !important;
    margin-bottom: 0 !important;
    margin-top: 15px !important;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
  .multicityDate{
    width: 100%;
  }
  .car_searchwidget_container {
    box-shadow: none;
    padding: 0;
}
.tab_container{
  background-color: #CADBEB;
  display: flex;
  padding: 14px 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  width: 100vw;
  max-width: 500px;
  overflow-x: auto;
}
.tab{
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  height: 31px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--primary_color);
  background-color: #FFF;
  margin-right: 13px;
  transition: background-color 0.3s, color 0.3s;
}
.tab:last-child{
  margin-right: 0;
}
.tab.active{
  background-color: var(--primary_color);
  color: #fff;
}
.pickup_time__preview_wrap .active {
  background-color: #266AAC;
  color: white;
}
.flight_mobileContainer{
  padding: 0;
}

.car_search_details_wrap {
  margin: 0;
  display: block;
  border: 0;
  height: auto;
  padding: 10px 5px;
}

.car_way_tab {
  padding-bottom: 0;
}

.car_search_details_container {
  background: #fff;
  padding: 10px;
  margin: 0 auto;
  width: 95%;
  margin-top: -1px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
}
.car_way_btngrp.flight_way_btngrp.radio_cont {
  background: #fff;
  /* box-shadow: 0 3px 8px rgba(0, 0, 0, .24); */
  box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 15px 15px 0 0;
  }
  .car_searchwidget_wrap :global .theme4_guestmaindiv__l_Fsg .theme4_adv_search_container__ndtoL {
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.2), 0px 0 8px rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    padding: 0 10px;
}
.car_searchwidget_airport_selector_container .car_way_btngrp.flight_way_btngrp.radio_cont {
    box-shadow: none;
    padding-top: 0;
}
.car_searchwidget_wrap :global .theme4_guestmaindiv__l_Fsg .theme4_adv_search_container__ndtoL .theme4_trav_flex__PAoQY {
  margin-top: 0;
  gap: 10px;
  justify-content: flex-start;
}
.car_searchwidget_wrap :global .theme4_guestmaindiv__l_Fsg .theme4_adv_search_container__ndtoL .theme4_trav_flex__PAoQY .theme4_adju_flex__ZX530{
  margin-left: auto;
}
.car_search_hourly{
  padding-top: 25px !important;
}
.srpwidget {
  overflow: auto;
  max-height: 100%;
  height: 100vh;
}
.srpwidget .car_search_details_wrap{
  background-color: transparent !important;
}
.car_search_details_wrap  .flight_autocomplete_value{
  overflow-y: auto;
}

.radio_cont {
  background: var(--primary_color);
  padding: 10px;
  margin: 0 auto;
  margin-top: 0px;
  border-radius: 10px 10px 0 0;
  width: 95%;
  padding-top: 30px;
  justify-content: flex-start;
}

.radio_button {
  margin-right: 20px;
  font-size: 14px;
  color: var(--secondary_color);
  line-height: normal;
  font-weight: 500;
}
.car_search_details_main {
  width: 100% !important;
  position: relative;
  background: #fff;
  border-radius: 10px;
  height: 67px !important;
  border: 1px solid #d4d4d4;
  padding: 10px 10px !important;
}

.car_autocomplete_container {
  padding-right: 0px;
  padding-left: 0;
  position: relative;
  display: table;
  margin-left: auto;
          bottom: 0;
}
.car_searchwidget_container .collapsible_title_add {
    position: static;
}
.cab_stops_container_main_wrap {
  padding: 0;
}
.cab_seat_add_icon {
  position: absolute;
  top: -60px;
  right: 10px;
}
.cab_stops_wrap .stops_number{
  top: inherit;
  bottom: 33px;
  right: 5px;
  z-index: 1;
}
/* .theme5_cab_stops_container__6kqXI {
  position: absolute;
  top: -62px;
  right: 10px;
} */
.cab_stops_container_main_wrap .autocomplet_wrap {
  padding: 10px;
  padding-top: 5px;
}
.mobileflexcal {
  padding-top: 0px;
}
.pickup_time_wrap {
  width: 100% !important;
}
.car_searchwidget_MobileContainer {
  border-radius: 10px;
  background: #FFF;
  height: 77px;
  padding: 13px 10px;
  margin-right: 15px;
}

.car_searchwidget_distance_container {
  width: 100%;
  padding-top: 30px;
}

.package_selector {
  width: 100% !important;
  position: relative;
  background: #fff;
  border-radius: 10px;
  height: 67px !important;
  border: 1px solid #d4d4d4;
  padding: 5px 10px !important;
  margin-left: 0 !important;
}
.car_searchwidget_container :global .collapsible_title_add {
    position: static;
}

.package_selector__display {
  border-left: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.car_searchwidget_calendar_guest {
  border-radius: 10px;
  background: #FFF;
  height: 77px;
  padding: 13px 10px;
}
.cab_main_container {
  top: -4px;
  left: 0;
  width: auto;
  position: relative;
  border-radius: 0 0 10px 10px;
}
.cab_stops_container_main {
  width: 100%;
  padding: 0;
  border: 0;
}
.cab_stops_container{
  flex: 1;
}
.locationName{
  width: 85%;
}
.locationInfo {
  align-items: center;
}
.car_button {
  width: 100%;
  height: 55px;
  font-size: 18px;
  border-radius: 40px;
  padding: 8px 18px;
}

.car_button_wrap {
  padding-top: 30px;
  padding-bottom: 15px;
}
.package_selector__display_wrap{
  width: 100% !important;
}
.car_searchwidget_pickup_container {
  padding: 0;
  width: 100%;
  padding-top: 30px;
}

.car_searchwidget_Mobile_onlyDepart{
  width: 100%;
  margin: 0;
}

.pickup_time {
  border: 0;
  padding: 0;
  border-radius: 10px;
  background: #FFF;
  height: 77px;
  padding: 13px 10px;
}

.cabStopInfo :global .autosuggest_container .autosuggest_value{
  position: fixed;
  width: auto;
  border: none;
  padding-bottom: 10px;
}

/* Time picker mobile design */
.timepicker_main_container {
  margin-top: 30px;
  width: 100% !important;
  position: relative;
  background: #fff;
  border-radius: 10px;
  height: 75px !important;
  border: 1px solid #d4d4d4;
  padding: 5px 10px !important;
}
.timepicker_main_wrap{
  padding: 0;
}
.timepicker_main_label{
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-left: 0;
  text-transform: capitalize;
  padding-bottom: 10px;
  display: block;
}
.car_search_details_main label:first-child,
.car_search_details_new_wrap label:first-child,
.car_searchwidget_calendar_container label:first-child,
.car_searchwidget_pickup_container label:first-child:first-child,
.car_searchwidget_distance_container label:first-child,
.timepicker_main_label,
.package_selector__label {
  padding-bottom: 7px;
  font-size: 14px;
}

.timepicker_value{
  color: var(--secondary_color);
font-family: var(--font-family);
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: normal; /* 129.412% */
}
.timepicker_weekday{
  color: var(--font_color);
font-family: var(--font-family);
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal; /* 169.231% */
}
.timepick_maine_div{
  padding: 0;
}
.autocomplet_wrap input,
.timepicker_value,
.package_selector__value,
.timepicker_weekday {
  font-size: 14px !important;
  color: var(--primary_color) !important;
}
.autocomplet_wrap input::placeholder {
  font-size: 14px !important;
  color: var(--primary_color) !important;
}
.calendra_value.active {
  background-color: var(--primary_color);
  color: #fff;
  border-radius: 5px;
}
.timepick_main_flex{
  display: flex;
  justify-content: space-between;
}
.timepick_main_date_box{}
.timepicker_icon{}

.timepicker_icon svg,
.arrow_icon svg {
  width: 15px;
  height: 15px;
}

.timepicker_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  overflow-y: auto;
  z-index: 100;
}

.headerAuto {
      display: flex;
    align-items: center;
    /* padding: 16px 10px; */
    padding: 16px 19px;
    background-color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .5px;
    text-align: left;
    color: #fff;
    width: 100%;
    height: 65px;
    position: sticky;
    top: 0;
    z-index: 2;
}
.headerAuto .header_title{
  /* justify-content: flex-start;
  padding-left: 20px; */
}

.pickup_drop_subtitle {
  height: 85px;
  padding: 12px 10px;
  background-color: #1b5d9d;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  margin-top: -1px;
}
.calendar_label{
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .38px;
  font-family: var(--font_family);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: #fff;
}
.calendar_head_date{
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: normal;
  font-family: var(--font_family);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: #fff;
  padding-top: 2px;
}
.calendar_head_com {
  height: 61px;
  padding: 10px 10px 10px 6px;
  border-radius: 5px;
  background-color: #266aac;
  width: 50%;
  position: relative;
}
.cal_active{
  box-shadow: 0 0 7px 0 #fff;
  /* border: 1px solid #fff; */
  background-color: #266aac;
  width: 100%;
}
.header_title {
  font-size: 18px;
  font-weight: bold;
}

.selection_main_container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  padding-bottom: 100px;
}
.hour_label{
  color: var(--secondary_color);
font-family: var(--font-family);
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal; 
}
.calendar_main_div_top{
  display: flex;
  flex-direction: column;
  padding: 0;
}

.calendar_main_wrap{
  width: 32%;
}

.calendar_main_div,
.hours_main_div,
.minutes_main_div {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 5px;
  background: linear-gradient(180deg, #FFF 8.56%, rgba(255, 255, 255, 0.20) 97.3%);
  border: 1px solid #E5E5E5;
  height: 100%;
  max-height: 400px;
  overflow: auto;
}
.calendar_main_div,
.hours_main_div{
  margin-right: 0px;
  padding: 2px;
}
.calendar_main_div,
.hours_main_div,
.minutes_main_div{
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}
.calendar_main_div::-webkit-scrollbar,
.hours_main_div::-webkit-scrollbar,
.minutes_main_div::-webkit-scrollbar {
  display: none; 
}

.active_icon_ampm{
  display: block;
  padding-right: 4px;
}

.meridiem {
  display: flex;
  gap: 8px;
  margin-top: 0px;
  border-radius: 30px;
background-color: #FFF;
box-shadow: 0px 0px 8.3px 0px rgba(0, 0, 0, 0.25);
height: 35px;
padding: 4px;
}
.startTime_label{
  color: var(--secondary_color);
font-family: var(--font-family);
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal; 
}

.meridiem button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
}
.car_searchwidget_container :global .theme4_collapsible_title_add__VxnLn{
  position: unset!important;
  top: 20px;
}

.calendar_sub_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 13px;
}

.pickup_time__apply_button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
}
.toggle_AmPm{
  display: flex;
  align-items: center;
}
.toggle_active{
  border-radius: 30px;
background: var(--primary_color);
color: #fff;
}
.calendar_main_Container{
  display: flex;
  gap: 18px;
}
.calendra_label{
  color: var(--font_color);
font-family: var(--font_family);
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal; 
margin-bottom: 5px;
text-align: center;
}
.calendra_value{
  color: var(--font_color);
  font-family: var(--font_family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  padding: 16px 10px;
  text-align: center;

}
.pickup_button_wrap{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, .11);
}
.pickup_button_wrap button{
  height: 48px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;
}
/* Time picker mobile design */
  
  
  }
  .stops_label{
    color: var(--font_color);
font-family: var(--font-family);
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: block;
text-align: center;
margin-bottom: 8px;
  }
  .stops_number{
    width: 14px;
height: 14px;
    border-radius: 2px;
background: #D9D9D9;
color: var(--primary_color);
font-family: var(--font-family);
font-size: 8px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .cab_stops_wrap{
    position: relative;
  }
  .stops_number{
    position: absolute;
    top: -6px;
    right: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .pickup_time__option.pickup_time__option__hour.active,
  .pickup_time__option.pickup_time__option__minute.active,
  .pickup_time__option.pickup_time__option__period.active,
  .package_selector__option.active {
    color: var(--primary_color);
    font-weight: bold;
}
.pickup_time__option.pickup_time__option__hour.disabled,
.pickup_time__option.pickup_time__option__minute.disabled,
.pickup_time__option.pickup_time__option__period.disabled {
  opacity: 0.5;
  pointer-events: none;
}
  
  .specialfare_disable{
    pointer-events: none;
    opacity: 0.7;
  }
  .calendar_wid_main_container{
    display: flex;
  }
  .searchAutoIcon{
    position: absolute;
    top: 14px;
    left: 7px;
  }
  .searchAutoIcon svg{
    width: 15px !important  ;
    height: 14px !important ;
  }
  .search_widget_input_highlight{
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    border: none;
    padding: 0 !important;
    padding-left: 5px;
    box-sizing: border-box;
    width: 98%;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
    padding: 10px 14px 10px 25px !important;
    border-radius: 5px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .15) !important;
    background-color: #fff;
  }
  .desktop_div{
    /* width: 77.5%; */
    /* padding: 5px 0 !important; */
    display: flex;
    position: relative;
    padding-bottom: 0 !important;
  }
  .desktop_div .car_search_details_new_wrap {
    width: 50% !important;
    cursor: default;
    padding: 0 15px !important;
    border-left: 1px solid #dedede;
    border-right: 1px solid transparent;
    border: 0;
    align-self: center;
    height: 55px;
    border-bottom: 2px solid #dedede;
    padding-right: 0 !important;
    margin-right: 15px;
}

.desktop_div .car_searchwidget_calendar_container {
    width: 50% !important;
    cursor: default;
    padding: 0 15px !important;
    border-right: 1px solid #dedede;
    border-left: 1px solid #dedede;
    position: relative;
    border: 0;
    align-self: center;
    height: 58px;
    border-bottom: 2px solid #dedede;
}

/* pickup time style */
.pickup_time {
  padding-left: 0px;
  border-left: 1px solid #dedede;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border: 0;
}
.pickup_time_wrap{
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
}

.pickup_time__display {
  cursor: pointer;
  width: 100%;
}

.pickup_time__title {
  padding: 0;
}

.pickup_time__label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-left: 0;
  text-transform: capitalize;
  padding-bottom: 10px;
  display: block;
  white-space: nowrap;
}

.pickup_time__value {
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pickup_time__selection {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 300px;
}

.pickup_time__preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  border-bottom: 1px solid #ddd;
}

.pickup_time__temp_value {
  color: var(--secondary_color);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; 
}

.pickup_time__apply_button {
  border: none;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 8px;
  background: var(--buttons_color);
  height: 25px;
  color: #FFF;
  font-family: var(--font_family);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.pickup_time__apply_button:hover {
  background-color: var(--buttons_color);
  opacity: 0.8;
}

.pickup_time__options {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
}

.pickup_time__options::-webkit-scrollbar {
  width: 5px;
}

.pickup_time__options::-webkit-scrollbar-track {
  background-color: transparent; 
}

.pickup_time__options::-webkit-scrollbar-thumb {
  background-color: #E4E4E4; 
  border-radius: 10px;
}

.pickup_time__options::-webkit-scrollbar-thumb:hover {
  background-color: #d4d4d4;
}


.pickup_time__options__hours,
.pickup_time__options__minutes,
.pickup_time__options__periods {
  display: flex;
  padding: 10px 10px;
}
.pickup_time__options__hours, .pickup_time__options__minutes {
  width: 40%;
}
.pickup_time__options__periods{
  padding: 10px 15px;
}

.pickup_time__option {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: var(--font_family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--font_color);
  margin: 2px;
  transition: background-color 0.2s;
}

.pickup_time__option:hover {
  background-color: transparent;
  color: var(--primary_color);
  font-weight: 600;
  font-size: 14px;
}

.pickup_time__option__hour,
.pickup_time__option__minute,
.pickup_time__option__period {
  text-align: center;
}
.pickup_time__preview_wrap{
  display: flex;
}

/* pickup time style */

/* Distance list style */
.package_selector {
  padding: 5px 0;
  position: relative;
  width: 100%;
    height: 55px;
  border-bottom: 2px solid #dedede;
      margin-left: 15px;
}

.package_selector__display {
  cursor: pointer;
  /* padding-left: 15px; */
  /* border-left: 1px solid #dedede; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.package_selector__title {
padding: 0;
width: 100%;
}

.package_selector__label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-left: 0;
  text-transform: capitalize;
  padding-bottom: 7px;
  display: block;
}

.package_selector__value {
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.package_selector__display_wrap{
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  justify-content: space-between;
}
.pick_error{
      margin-left: 0;
}

.package_selector__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-right: 5px;
  width: 162px;
}

.package_selector__options {
  display: flex;
  flex-direction: column;
  max-height: 325px;
  overflow-y: auto;
  padding: 10px;
}

.package_selector__options::-webkit-scrollbar {
  width: 5px;
}

.package_selector__options::-webkit-scrollbar-track {
  background-color: transparent; 
}

.package_selector__options::-webkit-scrollbar-thumb {
  background-color: #E4E4E4; 
  border-radius: 10px;
}

.package_selector__options::-webkit-scrollbar-thumb:hover {
  background-color: #d4d4d4;
}

.package_selector__option {
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: var(--font_family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  color: var(--font_color);
  transition: background-color 0.2s;
}

.package_selector__option:hover {
  /* background-color: #f0f0f0; */
  color: var(--primary_color);
  font-weight: 600;
}

/* Distance list style */
.desktop_div .car_search_details_new_wrap_onlyDepart{
  width: 100% !important;
  margin-left: 15px;
}
.cab_main_border_hide{
  border: 0;
}
.flight_way_btngrp{
  position: relative;
}
.trip_type_container {
  width: 100%;
  margin-bottom: 8px;
}
.trip_type_container span {
  margin-right: 30px;
  color: var(--font_color);
}
.trip_type_heading {
  color: #000;
}
.trip_type_container span input {
margin-right: 12px;
}


  @media only screen and (max-width: 768px) and (min-width: 320px){
    .package_selector__dropdown {
      position: static;
      box-shadow: none;
      border: 0;
    }
    .package_selector__dropdown{
      width: 100%;
    }
    
    .package_selector__options {
      max-height: auto;
      overflow: inherit;
    }
    .selectPackage_btngrp .radio_button span{
      border: 1px solid var(--primary_color) !important;
    }
    .selectPackage_btngrp .radio_button input:checked + span:after{
      background-color: var(--primary_color);
    }
    .selectPackage_btngrp .theme5_radio_button__NN044 input:checked + span{
      border: 1px solid var(--primary_color);
    }
    .selectPackage_btngrp .radio_button{
      color: var(--secondary_color);
      margin-right: 0;
    }
    .selectPackage_btngrp.flight_way_btngrp {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 100px;
  }
  .selectPackage_btngrp .radio_active {
    font-weight: 500 !important;
  }
  
  .selectPackage_btngrp .radio_button input:checked label {
    font-weight: 600;
  }
  .selectPackage_btngrp label,
  .selectPackage_btngrp label:first-child{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary_color);
    padding-left: 0;
    padding-bottom: 0px;
    display: flex;
    gap: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid #DEDDDD;
    margin-bottom: 20px;
  }
  .car_searchwidget_distance_container .selectPackage_btngrp label:first-child{
    text-transform: none !important;
  }
  .selectPackage_btngrp label:last-child{
    margin-bottom: 0;
  }
  .trip_type_container {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .trip_type_container span {
    margin-right: 5px;
    display: inline-block;
  }
  .trip_type_container span input {
    margin-right: 5px;

  }


  
  }



  