.nationlity {
  width: 100%;
}
/* .traveller_button{
    background-color: var(--primary_color);
    width: 20px;
    border-radius: 5px;
    color: #fff;
} */
.traveller_button {
  /* width: 30px;
  height: 30px; */
  padding: 0px;
  padding: 5px;
  padding-top: 2px;
  line-height: 1;
  border-radius: 50px;
  /* background-color: var(--primary_color); */
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  position: relative;
}
.guesttext {
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.45px;
  text-align: left;
  color: var(--primary_color);
  white-space: nowrap;
  padding-left: 5px;
}
/* .advbtn, .addguestbtn, .advance_search_card_container{
  display: flex;
    align-items: center;
    gap: 5px;
} */
.addguestbtn{
  flex-direction: column;
}
.dropdown_Guest_container {
  width: 300px;
  /* width: auto; */
  min-height: 275px;
  margin-top: 10px;
  padding: 15px;
  border: solid 1px #e0e0e0;;
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  right: 0;
  margin-left: auto;
  z-index: 9;
}
.inputsearch input {
  width: 100%;
  height: 38px;
  border: solid 1px #e0e0e0;
  border-radius: 50px;
  background-color: #fff;
}

.adv_search_container,
.addguestbtn {
  position: relative;
}
.adv_search_container{
  margin-left: 0px;
  display: flex;
  align-items: center;
}
.adv_search_wrap {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 17px;
}
.collapsible_title {
  /* width: 59%; */
  text-align: left;
}
.advmodal_dropcontent {
  position: absolute;
  right: 0;
  background: #e2e2e2;
  z-index: 1;
  border-radius: 5px;
  padding: 15px 35px;
  margin-top: 10px;
  /* box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.2); */
  border: 2px solid #ccc;
  width: 80%;
  padding-bottom: 25px;
}
.filter_container {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.adv_text {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.label_fname label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  margin: 15px 0;
}
.label_fname {
  margin: 15px 0;
}
.input_fname {
  position: relative;
}
.input_fname span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.mystyletext {
  padding-left: 20px;
}
.check_advance_labels {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

.nationality_wrap {
  position: relative;
  display: inline;
}
.input_nationality_wd {
  width: 250px;
  height: 40px;
  padding: 12px 20px 12px 12px;
  border-radius: 3px;
  border: solid 1px var(--primary_color);
  background-color: #e2e0e0;
  position: relative;
}
.pg_down_arrow i {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.inputsearch_result {
  width: 250px;
  height: 383px;
  padding: 16px 22px 20px 20px;
  margin-top: 0px;
  border: solid 1px #ccc;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.inputsearch_result input {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.adju_flex{
  display: flex;
  align-items: center;
}

.advbtn{
  display: flex;
  align-items: center;
}

.advance_search_card_container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.right_advsearch {
  margin-left: 30px;
}
.advs_input {
  position: relative;
  margin-top: 20px;
}
.advs_input span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.advance_search_card {
  position: absolute;
  background: #e2e2e2;
  border-radius: 5px;
  right: 0px;
  /* top:100px */
  top: 78px;
  z-index: 9;
  /* box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%); */
  border: 2px solid #ccc;
  padding: 26px;
  display: flex;
  flex-wrap: wrap;
  /* width: 900px; */
  z-index: 15;
}
.form_checkbox_adv {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.form_checkbox_adv > div {
  padding-right: 10px;
}
.input_container {
  display: inline-flex;
  gap: 10px;
  margin-bottom: 10px;
}
.dropdownfilter {
  position: relative;
}
.dropdownfilter {
  position: relative;
  width: 40%;
  margin-left: auto;
}
.dropmeallign {
  display: grid;
  margin-left: 20px;
  border: 1px solid #000000;
  padding: 10px;
  height: 170px;
  overflow-y: scroll;
  width: 258px;
  background: #fff;
}
.flight_cabin_class_ele {
  font-family: var(--font_family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
}

/* Traveller search design */

.gest_container {
  text-align: left;
}
.travel_gest p {
  font-size: 15px;
  margin-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-weight: 500;
}
.travlr_scroll {
  max-height: 140px;
  overflow: auto;
  padding-right: 5px;
}
.travlr_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.travlr_name {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--font_color);
  width: 80%;
}
.travlr_name span {
  word-break: break-word;
}
.travlr_btn {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  width: 30px;
  height: 30px;
  padding-bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary_color);
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
}

.travlr_scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.travlr_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.travlr_scroll::-webkit-scrollbar-thumb {
  background: #666;
}

.travlr_scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary_color);
}

.travlr_scroll::-webkit-scrollbar-thumb:hover {
  background: var(--primary_color);
  opacity: 0.8;
}

.main_adv_multicity {
  /* width: 1200px;
    margin: auto; */
    padding: 0;
}

.advanc_addition_tabs {
  width: 100%;
  /* display: flex; */
  gap: 3px;
  padding: 20px 0px;
  padding-top: 25px;
    padding-bottom: 10px;
}

.advance_search_card_container_multicity {
  width: max-content;
  position: absolute;
  right: 35px;
  top: 21px;
}

.adv_multicity_imp {
  width: 100%;
}

.advance_add {
  /* position: absolute;
  right: 35px; */
  margin-right: 40px;
  padding-left: 15px;
}
.collapsible_title_add {
  width: 100%;
  text-align: right;
  /* margin-right: 20px; */
  margin-left: auto;
  /* position: absolute;
  right: 210px; */
  margin-top: 0px;
}

.adv_departure_time {
  width: 185px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  justify-content: space-evenly;
  height: 60px;
  border-radius: 3px;
  position: relative;
}

.time_icon {
  width: 20px;
  font-weight: 600;
  color: var(--secondary_color);
  fill: var(--primary_color);
}

.text_arrow_box {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text_arrow_box label {
  color: #545454;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}

.text_arrow_box span {
  color: #000;
  font-weight: 600;
}
.text_arrow_box svg {
  color: var(--secondary_color);
  width: 20px;
}
.text_box {
  display: flex;
  flex-direction: column;
}

.cabin_selector {
  width: 185px;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  padding: 0 5px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}

.selector_icon {
  width: 25%;
  height: 100%;
  color: #016582;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector_text {
  cursor: pointer;
  width: 75%;
}

.secondary_text {
  text-transform: uppercase;
  color: #525252;
  font-size: 13px;
}

.primary_text {
  color: #000;
  font-weight: 600;
}

.drop_arrow {
  font-size: 30px;
  padding-top: 5px;
}

.departure_time_drop {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}
.departure_time_drop li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.departure_time_drop li {
  color: var(--primary_color);
}

.rbd_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}

.rbd_dropdown li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.rbd_input {
  padding: 5px 10px;
  width: 140px;
}

.rbd_input::placeholder {
  font-size: 14px;
}

.airline_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: max-content;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  width: 100%;
  box-shadow: 2px 2px 5px #858585bf;
}

.airline_dropdown li {
  width: max-content;
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.supplier_dropdown {
  background: white;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  padding-top: 10px;
  margin-top: 5px;
  z-index: 20;
  box-shadow: 2px 2px 5px #858585bf;
}

.supplier_dropdown li {
  list-style: none;
  padding: 10px 20px;
  width: 100%;
  transition: 0.4s;
  cursor: pointer;
}

.supplier_dropdown li:hover {
  background: var(--primary_color);
  color: #fff;
}

.drop_arrow svg {
  color: var(--secondary_color);
  width: 20px;
}

.departure_main_box {
  /* width: 175px; */
  min-width: 175px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.departure_main_box .label_fname {
  color: grey;
  margin: 0;
}
.departure_main_box .label_fname label {
  /* color: grey;
  text-transform: uppercase;
  padding-left: 5px; */
  font-family: var(--font_family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding-left: 0px;
    text-transform: capitalize;
}

.multiSelectDepartureTimeDropdown #search_input::placeholder {
  display: none;
  /* color: red !important; */
}

.multiSelectContainer #search_input {
  height: 60px !important;
}

.imp_select_dep {
  /* background: lime; */
}

.imp_select_dep select{
  background: transparent;
  border: none;
  outline: none;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: none;
  padding: 0 10px 0 0;
  padding-left: 15px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

.imp_select_dep .multiSelectContainer .searchWrapper input.searchBox {
  /* background: red !important; */
}


.adv_tabs_split{
  width: max-content;
  display: flex;
  background: transparent;
  /* border-radius: 3px; */
  height: 70px;
  border-bottom: 2px solid #dedede;
  margin-right: 15px;
  position: relative;
}

.adv_tabs_split input{
  border: none;
  /* padding-left: 30px !important;
    padding-right: 5px !important; */
    width: 100%;
    /* width: auto; */
    padding: 5px 3px;
    padding: 0;
}
.adv_tabs_split input::placeholder{
  padding: 0 !important;
}

.adv_tabs_split input{
  font-size: 14px;
  font-weight: 600;
  color: var(--primary_color);
}
.adv_tabs_split [type='text']:focus{
  box-shadow: none; 
}

.adv_tabs_split input[type="radio"]{
  border: 1px solid var(--primary_color);
}

.adv_tabs_split input::placeholder{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  border: none;
  padding: 0 10px 0 0;
  padding-left: 15px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}
.checkbox{
  border: 1px solid grey;
}

.icon_box_adv{
  /* width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; */
  position: absolute;
  top: 36px;
  z-index: 1;
}

.multiSRp{
  top: 39px;
}

.icon_box_adv svg{
  min-width: 22px;
  height: 100%;
  fill: var(--primary_color);
  /* position: absolute;
  bottom: -30px; */
}
.icon_box_adv svg path{
  fill: var(--primary_color);
}
.icon_box_advInput{
  padding-top: 10px;
  padding-left: 12px;
}
.icon_round_advInput{
  padding-top: 1px;
}
.icon_round_advRbd{
  padding-top: 5px;
  padding-left: 7px;
}
.icon_pd{
  padding-top: 5px;
  padding-left: 20px;
}
.flight_search_column {
  float: left;
  margin-right: 15px;
  margin-bottom: 3px;
  position: relative;
  background: #ffff;
  border-radius: 0;
  height: 70px;
  border-bottom: 2px solid #dedede;
}

.checkbox_label{
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.checkbox_label .bagCheckbox{
  position: relative;
  margin-left: 0 !important;
}
.checkbox_label .bagCheckbox input{
  cursor: pointer;
    height: 20px;
    opacity: 0;
    position: absolute;
    width: 20px;
    z-index: 2;
}
.checkbox_label .bagBox{
  align-items: center;
    background: #fff;
    border: 1px solid #9b9b9b;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 0 !important;
}

.checkbox_label .bagCheck{
  border-color: #fff;
  -webkit-border-image: none;
  border-image: none;
  border-style: none solid solid none;
  border-width: medium 2px 2px medium;
  box-sizing: border-box;
  height: 11px;
  opacity: 0;
  position: relative;
  top: -1px;
  -webkit-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  width: 5px;
  z-index: 1;
  margin-left: 0;
}
.checkbox_label input[type=checkbox]:checked~.bagBox {
  background: var(--secondary_color);
  border: 0;
}
.checkbox_label .bagCheckbox input[type=checkbox]:checked~.bagBox .bagCheck {
  opacity: 1;
  transform: scale(1) rotate(45deg);
}
.advTitle{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .38px;
  text-align: center;
  color: var(--primary_color);
  margin-left: 10px;
}

.flight_Adv_wrap{
  margin-left: auto;
  display: flex;
  align-items: flex-end;

}
.flight_AdvBtn{
  background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 40px;
    width: 100%;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    /* margin-top: 3px; */
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}

.search_button_section{
  color: var(--secondary_color);
  font-family: var(--font_family);
  font-weight: 600;
  font-size: 14px;
}
.multicity_main{
  /* text-align: right; */
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.flight_Adv_wrapMU{
  padding: 0;
}
.travtext{
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}
.flight_AdvBtnMU{
  background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
    border-radius: 40px;
    width: auto;
    padding: 8px 18px;
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-left: 0px;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
}
.client_name{
  padding: 0;
  text-transform: uppercase;
}

.baggage_multicity{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.flx_baggage{
  justify-content: flex-end;
  margin-top: 15px;
  padding: 15px 0;
}
.icon_Multicity_advInput{
  padding-top: 4px;
  padding-left: 10px;
}
.icon_Multicitydep_advInput{
  padding-left: 5px;
  padding-top: 4px;
}
.icon_Rbd_advInput{
  padding-left: 5px;
  padding-top: 5px;
}

.icon_supplier_advInput{
  padding-left: 10px;
  padding-top: 10px;
}
.icon_box_advSrpInp{
  padding-top: 9px;
    padding-left: 12px;
}
.icon_pdRound{
  padding-left: 20px;
  padding-top: 7px;
}
.icon_pdlft{
  padding-left: 25px;
}
.icon_box_advSrpInp input{
  background: #def1fa;
}
.icons_advInputMulti{
  padding-left: 5px;
  padding-top: 10px;
}
.icon_Multicity_advInputSRP{
  padding-left: 10px;
  padding-top: 10px;
}
.MulticitydepSRP_advInput{
  padding-left: 20px;
  padding-top: 10px;
}
.Multicity_supplier_advInput{
  padding-left: 10px;
  padding-top: 5px;
}
.adv_tabsSupplier{
  padding-top: 10px;
}
.Multicity_supplier_advInput input{
  background: #def1fa;
}

.flightmainbots{
  margin-top: 20px;
}
.flightfare{
  width: 20%;
  border-bottom: 2px solid #dedede;
}

.flightmainfare{
  display: flex;
  gap: 5px;
  padding-top: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.flightmainfare span{
  /* width: 16%; */
  padding: 0;
}
.flightmainfare span svg path{
  color: var(--primary_color);
}
.flightmainfare label{
  margin: 0px;
  width: 80%;
  font-size: 14px;
font-weight: bold !important;
color: var(--primary_color) !important;
}
.flightfare label{
  font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: var(--font_color);
}
.flightfares label{
  font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: var(--font_color);
}

.wd_5{
  width: 5% !important;
}
.wd_10{
  width: 10% !important;
}
.wd_15{
  width: 15% !important;
}
.wd_20{
  width: 20% !important;
}
.wd_25{
  width: 25% !important;
}
.wd_30{
  width: 30% !important;
}
.wd_35{
  width: 35% !important;
}
.wd_40{
  width: 40% !important;
}
.wd_100{
  width: 100% !important;
}

.trav_flex{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0 0px;
  gap: 10px;
  /* margin-bottom: 25px; */
  padding-bottom: 5px;
  padding-left: 15px;
  /* border-bottom: 1px solid #ccc; */
}

.travNameCap{
  background-color: #e7efff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 15px;
  color: var(--primary_color);
  text-align: center;
  width: auto;
  font-weight: 100;
}
.collapsible_title_add .addguestbtn{
  margin-top: 10px;
}
.travNameCap+div{
  display: flex;
  align-items: center;
}