.modal_container{
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    top: 70px;
    /* bottom: 0; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    }
      
    .modal_overflow{
      overflow: auto;
      height: 100vh;
      display: flex;
      align-items: center;
      z-index: 1;
      width: 100%;
      padding: 0px 0 40px;
      }
    .modal_background{
      position: fixed;
      background: rgb(0 0 0 / 15%);
      top: 70px;
      bottom: 0;
      left: 0;
      right: 0;
      /* z-index: 1; */
    }
    
    .modal_content{
      position: relative;
      width: 100%;
      box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 700px;
    padding: 40px 100px;
    height: max-content;
    border-radius: 10px;
    height: 100vh;
    }
    .modal_content.mobile_modal{
      padding: 0;
      padding-top: 0;
    }
    .modal_close svg{
      height: 25px;
      width: 25px;
      background-color: var(--primary_color);
      color: #fff;
      border-radius: 50px;
      padding: 4px;
      position: absolute;
      z-index: 3;
      top: 15px;
      right: 20px;
     
    }
    
    .modal_close{
      float: right;
      cursor: pointer;
    }

    /********** Mobile Modal popup **********/
    @media only screen and (max-width: 767px) and (min-width: 320px){
      .modal_container{
        position: fixed;
    background: rgba(0,0,0,.7);
    width: 100%;
    z-index: 9999;
    top: 0;
    height: 100%;
      }
      .modal_overflow{
        background: #fff;
        position: fixed;
        z-index: 9999;
        height: auto;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        max-height: 100%;
        overflow: auto;
            /* max-width: 400px; */
    padding-bottom: 5px;
    /* border-radius: 10px;*/
    height: 100vh;
      }
    }

    @media only screen and (min-width: 700px) and (max-width: 1024px) {
      .modal_overflow{
        height: auto;
      }
      .modal_content{
        width: 100%;
        max-width: 100% !important;
        height: auto;
        /* background: red; */
      }
    }