.hotel_search_accomodation .hotel_cabin_class_cont {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.hotel_search_column .hotel_room_list {
  position: absolute;
  min-width: 318px;
  top: 49px;
  left: 0;
  z-index: 9;
  max-height: 400px;
  overflow: auto;
  border-radius: 10px 0px 10px 10px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 3px 1px rgba(0, 0, 0, 0.30);
  padding: 9px 24px;
  padding-bottom: 30px;
  padding-top: 20px;
}
.hotel_search_column .hotel_room_list .hotel_room_list_main {
  width: 360px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  border-top: none;
}
.hotel_search_column .hotel_room_list .hotel_room_list_main .hotel_room_detail_ele {
  text-align: left;
  padding: 0 0;
  width: 100%;
  border-top: 1px solid #CBCBCB;
  padding-top: 19px;
  margin-top: 19px;
}
.hotel_search_column .hotel_room_list .hotel_room_list_main .hotel_room_detail_ele:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}
.hotel_adult_accomodation {
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
}
.hotel_children_accomodation {
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
}
.hotel_room_detail_ele .hotel_room_title {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--secondary_color);
  margin-right: 10px;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.hotel_room_head {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 9px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 0px;
  padding-bottom: 0;
}
.hotel_room_container{
  display: flex;
  gap: 34px;
}
.hotel_room_edit,
.hotel_room_remove {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: var(--primary_color);
  border: none;
  background: 0 0;
  cursor: pointer;
  outline: 0;
}
.hotel_room_traveller{
  color: var(--font_color);
font-family: var(--font_family);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.hotel_roomWrap{
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.hotel_accomodation_main > div img {
  position: relative;
  top: unset;
  left: unset;
}
.hotel_accomodation_conf {
  display: flex;
      width: 115px;
      height: 35px;
      align-items: center;
      gap: 8px;
      border-radius: 5px 0px 5px 5px;
      border: 1px solid #E6E6E6;
      background-color: #FFF;
}
.hotel_accomodation_conf div {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--font_color);
  width: 50px;
  text-align: center;
}
.hotel_accomodation_main span {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}
.hotel_accomodation_main p {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  margin: 0 0;
  display: block;
}
.hotel_room_detail_ele .hotel_room_title span {
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--font_color);
  margin-right: 10px;
  width: 50%;
}
.hotel_room_list_main .hotel_room_detail_ele .hotel_accomodation_main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
  position: relative;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.hotel_accomodation_conf .hotel_accomodation_icon svg {
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 0;
  position: relative;
  top: unset;
  left: unset;
}
.age_list_ele {
  cursor: pointer;
  width: 100%;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  padding: 5px 15px;
  height: 35px;
  display: flex;
  align-items: center;
}
.age_list_ele:hover {
  background: rgb(255 99 0 / 0.1);
  color: var(--primary_color);
}
.disbale_traveller {
  pointer-events: none !important;
  opacity: 0.5 !important;
  /* background: #ACACAC !important; */
  border-radius: 50px;
}
.disbale_traveller svg path{
  fill: #acacac;
}
.disbale_traveller img {
  opacity: 0.5;
}
.done_button_main {
  width: 100%;
  text-align: center;
  padding-top: 23px;
  background: #fff;
}
.done_button_main .done_button {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;
  height: 42px;
  padding: 12px 12px 12px;
  /* background-color: var(--buttons_color); */
  color: #fff !important;
  margin-bottom: 0px;
  border-radius: 5px 0px 5px 5px;
  background-image: linear-gradient(90deg, #FF6300, #FF6300);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.done_button_main .done_button::before {
  background: #fff;
  content: "";
  height: 155px;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 50px;
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
}

.done_button_main .done_button:hover,
.done_button_main .done_button:active {
  transform: translateY(-3px);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}


.hotel_guestlabel {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}
.hotel_cabin_class_name {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
  border: 0 !important;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none !important;
  outline: 0 !important;
  cursor: pointer;
}
.hotel_cabin_class_name span {
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.arrow_rotate {
  top: 3px !important;
  transform: rotate(180deg);
  transition: all 0.2s linear;
}
.hotel_add_rooms {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary_color);
  display: flex;
  align-items: center;
  padding-top: 18px;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid #CBCBCB;
  text-transform: capitalize;
  margin-top: 17px;
}
.hotel_add_rooms span {
  width: 26px;
  height: 26px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 26px;
  margin: 0 4px 0 0;
  font-weight: 400;
  padding-left: 0;
  padding-top: 2px;
}
.child_row_main label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  margin: 0;
  width: 100%;
  display: block;
}
.child_row_main input {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
  margin: 0;
  width: 100%;
  height: 36px;
  cursor: pointer;
  display: flex;
  padding: 12px 9px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px 0px 5px 5px;
  border: 1px solid #E6E6E6;
}
.child_row_main input::placeholder {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--font_color);
}
.child_input_wrap{
  position: relative;
}
.child_row_main {
  position: relative;
  padding: 0 0px;
}
.child_age_wrap{
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.child_row_main .child_age_lable{
  color: var(--font_color);
font-family: var(--font_family);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.child_age_select_arrow svg {
  position: absolute;
  right: -14px;
  top: -21px !important;
  transform: rotate(180deg);
}
.child_age_select_arrow_rot svg {
  position: absolute;
  right: 10px;
  top: 14px !important;
  left: inherit !important;
  top: unset;
}
.child_row_main .child_age_select_arrow {
  position: absolute;
  right: 25px;
  top: 35px;
}
.child_rowMain_container{
  display: flex;
  gap: 12px 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
  /* padding-bottom: 17px;
  border-bottom: 1px solid #CBCBCB; */
}
.child_rowMain_contSpace{
  border: 0;
  margin: 0;
}
.child_row_container{
  width: 48%;
}
.child_row_container .child_row_main .err_message_text {
  font-size: 11px;
  color: #e8664e;
  line-height: 13px;
  margin-top: 10px;
}
.hotel_cabin_class_name input {
  background-color: #fff;
}
.hotel_adult_accomodation_tag {
  display: flex;
}
.hotel_adult_accomodation_tag span {
  padding-left: 10px;
}
.hotel_class_arrow {
  position: relative;
}
.hotel_class_arrow span {
  position: absolute;
  left: 14px;
  top: 11px;
  width: 20px;
  height: 20px;
  object-fit: scale-down;
  color: var(--primary_color);
}
.adult_room_flex {
  display: flex;
  /* align-items: center; */
  gap: 2px;
  padding-top: 0;
  position: relative;
  z-index: 4;
}
.hotel_guestCont {
  /* width: 24%; */
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  position: relative;
}
.drop_wrap {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.hotel_dropdown {
  position: relative;
  top: -5px;
}
.hotel_dropdown svg {
  position: relative;
  left: 0;
  right: 0;
  width: 20px;
  height: 22px;
  fill: var(--primary_color);
}
.hotel_list_flex {
  display: flex;
  gap: 5px;
}
.paxPlus_icon {
  margin-right: 6px;
}
.paxPlus_icon svg {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
}
.adult_room_icon svg{
  width: 18px;
  height: 18px;
}
.adult_room_icon svg path{
  fill: var(--font_color);
}
.hotel_search_column.hotel_search_accomodation{
  height: 100%;
  position: relative;
}
.hotel_cabin_class_name .adult_room_title{
  color: var(--font_color);
font-family: var(--font_family);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal; 
}
.age_list_cont{
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  padding: 15px 10px;
  padding-bottom: 20px;
  padding-left: 0;
  border-radius: 5px;
  border: 1px solid #CBCBCB;
  background-color: #fff;
}
.age_list_main{
  background-color: #fff;
  overflow-y: auto;
  max-height: 217px;
  border-radius: 5px;
}
.child_rowMain_container .child_age_wrap input:focus{
  border: 1px solid #E6E6E6;
}
.select_full_section {
  position:absolute;
  left: 0;
  right: 0;
  height: 100%;
  cursor: pointer;
  height: 54px;
  width: 100%;
  top: -18px;
  z-index: 2;
  }

  .parent_autocomplete{
    position: absolute;
    min-width: 318px;
    z-index: 9;
    background: #fff;
    max-height: 420px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25), 0 1px 3px 1px rgba(0, 0, 0, .3);
    border-radius: 10px 0 10px 10px;
    padding-right: 0px;
    top: 49px;
    left: 0;
}

.hotel_search_column .child_autocomplete{
    margin-bottom: 5px;
    max-height: 400px;
    overflow: auto;
    padding: 20px 24px 30px;
	padding-bottom: 20px;
  position: static;
  box-shadow: none;
  border: 0;
  background: transparent;
}


@media only screen and (max-width: 992px) and (min-width: 768px) {
  .search_container {
      height: 69px !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .done_button_main {
      padding-top: 18px;
      position: fixed;
      width: 90%;
      z-index: 99;
      bottom: 20px;
      border: none;
      background: unset;
  }
  .select_full_section {
    position: static;
    height: auto;
}
.hotel_add_rooms_wrap{
  border-top: 1px solid #CBCBCB;
  margin-top: 17px;
}
  .hotel_guestCont {
    width: auto;
    border: 0;
    height: 100%;
    display: block;
    overflow: auto;
}

.hotel_search_column .hotel_room_list {
    min-width: 100%;
    position: static;
    box-shadow: none;
    padding: 0 15px;
    max-height: 75vh;
    max-height: max-content;
    overflow: visible;
}
  .paxPlus_icon {
      margin-right: 6px;
  }
  .hotel_room_list_main {
      padding-bottom: 90px;
  }
  .hotel_room_detail_ele .hotel_room_title {
      font-family: var(--font_family);
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: left;
      color: #000;
  }
  .hotel_room_list {
      padding: 0 20px;
      max-height: 90vh;
      overflow: scroll;
  }
  .hotel_room_head {
      padding: 0;
      border-bottom: 0;
  }
  .hotel_adult_accomodation,
  .hotel_children_accomodation {
      padding: 0;
      border-bottom: 0;
  }
  .hotel_addrooms_container{
    border-top: 1px solid #CBCBCB;
    margin-top: 18px;
  }
  .hotel_add_rooms {
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    width: -moz-max-content;
    width: max-content;
    border: 0;
    margin-top: 18px;
  }
  .hotel_accomodation_main p {
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.38px;
      color: var(--secondary_color);
      margin-bottom: 7px;
  }
  .done_button_main .done_button {
    height: 47px;
    width: 100%;
    border-radius: 5px 0px 5px 5px;
    background: var(--buttons_color);
    color: #FFF;
    font-family: var(--font_family);
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .hotel_accomodation_conf .hotel_accomodation_icon svg {
      width: 31px;
      height: 30px;
  }
  .child_row_main input {
     outline: 0;
  }
  .child_row_main input:focus {
      box-shadow: none;
      outline: 0;
  }
  .search_container {
      position: relative;
      padding: 12px 20px;
      background-color: #1b5d9d;
      height: 65px;
  }
  .search_icon svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 60%;
      left: 27px;
      transform: translateY(-60%);
  }
  .child_row_main {
      padding: 0;
  }
  .child_age_select_arrow_rot svg {
     outline: 0;
  }
  .child_row_main .child_age_select_arrow {
     outline: 0;
  }
  .age_list_ele {
      /* padding: 10px 2px;
      border-bottom: 1px solid #dedddd; */
      outline: 0;
  }
  .age_list_ele:last-child {
      border-bottom: 0;
  }
  .age_list_main {
      overflow: scroll;
      overflow-x: hidden;
      height: 230px;
  }
  .age_list_main::-webkit-scrollbar {
      width: 3px;
  }
  .age_list_main::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  .age_list_main::-webkit-scrollbar-thumb {
    background: var(--primary_color);
  }
  .age_list_main::-webkit-scrollbar-thumb:hover {
    background: var(--primary_color);
  }
  
  .hotel_add_rooms span {
      padding-top: 1px;
  }

  .done_button_main .done_button:hover,
.done_button_main .done_button:active {
  transform: unset;
  color: #fff;
  box-shadow: unset;
}

}
